<template>
  <div>
    <div v-show="editableSubstances && editableSubstances.length > 0" ref="labResultTable" class="modalTableContainer">
      <b-table
        class="substanceTable"
        foot-clone
        :items="editableSubstances"
        :fields="substanceFields"
        :sort-desc.sync="sortDesc"
        :sort-by.sync="sortBy"
        :class="{ overflow: tall, print: isPrintView }">
        <template #head()="{ label, field }">
          <TableSortIcons
            :label="label"
            :field="field"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            v-b-tooltip.hover
            :title="field.fullName"></TableSortIcons>
        </template>
        <template #cell(substanceName)="data">
          <div>{{ data.item.substanceName }}</div>
          <div v-if="data.item.substanceComment" class="substance-comment">{{ data.item.substanceComment }}</div>
        </template>
        <template #cell(isPositive)="data">
          <font-awesome-icon v-if="data.item.isPositive" icon="circle-check" size="lg" />
        </template>
        <template #cell(medicallyJustifiable)="data">
          <div v-if="data.item.isPositive">
            <span
              v-if="data.item.medicallyJustifiable"
              v-b-tooltip.hover
              :title="currentMedicallyJustifiableTooltip"
              @click="setMedicallyJustifiable(data.item, false)">
              <MedicallyJustifiableIcon :no-click="isPrintView"></MedicallyJustifiableIcon>
            </span>
            <span
              v-if="!data.item.medicallyJustifiable"
              v-b-tooltip.hover
              :title="currentNotMedicallyJustifiableTooltip"
              @click="setMedicallyJustifiable(data.item, true)">
              <NotMedicallyJustifiableIcon :no-click="isPrintView"></NotMedicallyJustifiableIcon>
            </span>
          </div>
        </template>
      </b-table>
    </div>
    <b-alert :show="editableSubstances && editableSubstances.length === 0" variant="info">No substances found for this test result.</b-alert>
  </div>
</template>

<script>
import MedicallyJustifiableIcon from '@/components/icons/MedicallyJustifiableIcon';
import NotMedicallyJustifiableIcon from '@/components/icons/NotMedicallyJustifiableIcon';

export default {
  components: {
    MedicallyJustifiableIcon,
    NotMedicallyJustifiableIcon
  },
  props: {
    substances: { type: Array, required: false, default: null },
    isPrintView: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      substanceFields: [
        { key: 'substanceName', label: 'Substance', sortable: true },
        { key: 'testOrderCode', label: 'Test Code', sortable: true, class: 'rightAlign' },
        { key: 'testOrderDescription', label: 'Test Desc', fullName: 'Test Order Description', sortable: true },
        { key: 'observationValue', label: 'Obs Val', fullName: 'Observation Value', sortable: true, class: 'noWrap' },
        { key: 'initialTestLevel', label: 'Initial Lv', fullName: 'Initial Test Level', sortable: true, class: 'rightAlign' },
        { key: 'confirmTestLevel', label: 'Confirm Lv', fullName: 'Confirm Test Level', sortable: true, class: 'rightAlign' },
        { key: 'substanceUnit', label: 'Units', fullName: 'Substance Units', sortable: true, class: 'noWrap' },
        { key: 'isPositive', label: 'Pos', fullName: 'Is Positive', sortable: true, class: 'centerAlign noWrap' },
        { key: 'medicallyJustifiable', label: 'MJ', fullName: 'Medically Justifiable', sortable: true, class: 'centerAlign noWrap' }
      ],
      tall: false,
      sortBy: null,
      sortDesc: true
    };
  },
  computed: {
    editableSubstances() {
      return this.$store.getters.editableSubstances;
    },
    currentMedicallyJustifiableTooltip() {
      if (!this.isPrintView) {
        return "Click to set 'Not Medically Justifiable'";
      }
      return 'Medically Justifiable';
    },
    currentNotMedicallyJustifiableTooltip() {
      if (!this.isPrintView) {
        return "Click to set 'Medically Justifiable'";
      }
      return 'Not Medically Justifiable';
    }
  },

  mounted() {
    setTimeout(() => {
      this.tall = this.$refs.labResultTable.clientHeight > window.innerHeight * 0.75 - 30;
    }, 0);
  },
  methods: {
    setMedicallyJustifiable(substance, medicallyJustifiable) {
      if (this.isPrintView) {
        return;
      }

      const param = {
        substanceKey: substance.substanceKey,
        medicallyJustifiable
      };

      this.$store.dispatch('setMedicallyJustifiable', param);
    }
  }
};
</script>
