<template>
  <div id="subjectDetails" class="detailsDPA">
    <div class="row">
      <div class="col-2 navDiv no-print" v-click-outside="closeCollapsingSidebar" :class="{ active: showCollapsingSidebar }">
        <div id="navShelf" @click="toggleCollapsingSidebar">
          <div><font-awesome-icon icon="caret-right" size="xl" /></div>
        </div>
        <ul class="nav">
          <li
            v-for="option in subjectsInProgram"
            :key="option.subjectKey"
            class="nav-item"
            :value="option.subjectKey"
            @click="changeSubject(option.subjectKey)">
            <a :class="{ 'router-link-exact-active': option.subjectKey == selectedSubjectKey }">
              {{ option.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-10">
        <b-card header-tag="header" :class="{ teamToggle: headerCollapse }">
          <template #header>
            <h6>Subjects</h6>
            <div class="search-card-header">
              <b-button variant="primary" @click="shelfToggle" v-b-tooltip.hover :title="`${headerCollapse ? 'Open' : 'Close'} subject information`">
                <font-awesome-icon icon="fa-solid fa-id-card" v-show="headerCollapse" />
                <font-awesome-icon icon="fa-regular fa-id-card" v-show="!headerCollapse" />
              </b-button>
              <b-button variant="primary" class="print-button" @click="print">
                <font-awesome-icon icon="print" size="lg" />
              </b-button>
            </div>
          </template>
          <div v-if="subject">
            <SubjectDetailsHeader :collapse="headerCollapse"></SubjectDetailsHeader>
            <LocationsTab></LocationsTab>
          </div>
          <div v-if="subjectKey && !subject" class="spinner-container">
            <font-awesome-icon icon="spinner" spin size="4x" />
          </div>
          <div v-if="!subjectKey">
            <h2>Select a Subject from the sidebar</h2>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import LocationsTab from '@/components/subjectDetails/LocationsTab';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';
import SubjectDetailsHeader from '@/components/subjectDetails/SubjectDetailsHeader';

export default {
  components: { LocationsTab, SubjectDetailsHeader },
  mixins: [SubjectDetailsMixin],
  props: {
    subjectKey: { type: Number, required: false, default: null }
  },
  data() {
    return {
      selectedSubjectKey: this.subjectKey,
      headerCollapse: localStorage.getItem('headerState') ? Boolean(localStorage.getItem('headerState') === 'true') : false
    };
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.initializePage();
      }
    }
  },
  async created() {
    await this.initializePage();
  },
  methods: {
    async initializePage() {
      if (!this.subjectKey) {
        this.clearSubjectDetails();
        return;
      }

      this.selectedSubjectKey = this.subjectKey;
      await this.initializeSubjectDetails(this.subjectKey);
    },
    changeSubject(newSubjectKey) {
      if (!newSubjectKey) {
        return;
      }
      if (this.subjectKey !== newSubjectKey) {
        this.$router.push({ name: 'SubjectDetails', params: { subjectKey: newSubjectKey } });
      }
    },
    shelfToggle() {
      this.headerCollapse = !this.headerCollapse;
      localStorage.setItem('headerState', this.headerCollapse);
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.subject-sidebar {
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 275px;
}
</style>
