<template>
  <b-card header-tag="header">
    <template #header>
      <div class="search-card-header unmatched">
        <span>Unmatched Lab Results (Last 30 Days)</span>
        <b-button variant="primary" class="print-button" @click="print('labResultsTable', 'Unmatched Lab Results')">
          <font-awesome-icon icon="print" size="lg" />
        </b-button>
      </div>
    </template>
    <LabResultsTable
      ref="labResultsRef"
      :filter-object="filterObject"
      hide-tokens
      home-unmatched
      :current-page.sync="filterObject.currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"></LabResultsTable>
  </b-card>
</template>
<script>
import { DateTime } from 'luxon';
import LabResultsTable from '@/components/sharedBusiness/LabResultsTable';
import VuexFunctions from '@/functions/VuexFunctions';
import GlobalFunctions from '@/functions/GlobalFunctions';

export default {
  components: {
    LabResultsTable
  },
  data() {
    return {
      filterObject: {
        matched: false,
        isAnnual: null,
        isNormal: null,
        scoutingCombine: null,
        preEmployment: null,
        gameOfficial: null,
        invalidResult: null,
        blindTest: false,
        specimenSubstituted: null,
        actedUpon: false,
        isPositive: null,
        cocNumbers: [''],
        collectionDateFrom: null,
        collectionDateTo: null,
        importDateFrom: DateTime.now().minus({ days: 30 }).toISODate(),
        importDateTo: null,
        currentPage: 1,
        pageSize: 50,
        sortBy: 'collectionDateTime',
        sortDesc: false,
        getAllRows: false
      }
    };
  },
  computed: {
    sortBy: VuexFunctions.mapComputed('home', 'unmatchedLabResultSortBy'),
    sortDesc: VuexFunctions.mapComputed('home', 'unmatchedLabResultSortDesc')
  },
  methods: {
    print(element, headerText) {
      GlobalFunctions.printSection(element, headerText);
    },
    refreshLabResults() {
      return this.$refs.labResultsRef.refreshCurrentTestResults();
    }
  }
};
</script>
