export default {
  getStoredRouteState() {
    const jsonString = window.localStorage.getItem('routeState');
    if (!jsonString) {
      return {
        arrivedAtSubjectDetailsFromRoute: null,
        currentSubjectsRoute: null,
        currentDPASubjectDetailsRoute: null,
        currentLabResultsRoute: null,
        currentCollectionSearchRoute: null,
        currentCollectionEntryRoute: null,
        currentCListRoute: null
      };
    }
    const routeState = JSON.parse(jsonString);
    return routeState;
  },
  storeRouteState(state) {
    const routeState = {
      arrivedAtSubjectDetailsFromRoute: this.getSimpleRoute(state.arrivedAtSubjectDetailsFromRoute),
      currentSubjectsRoute: this.getSimpleRoute(state.currentSubjectsRoute),
      currentDPASubjectDetailsRoute: this.getSimpleRoute(state.currentDPASubjectDetailsRoute),
      currentLabResultsRoute: this.getSimpleRoute(state.currentLabResultsRoute),
      currentCollectionSearchRoute: this.getSimpleRoute(state.currentCollectionSearchRoute),
      currentCollectionEntryRoute: this.getSimpleRoute(state.currentCollectionEntryRoute),
      currentCListRoute: this.getSimpleRoute(state.currentCListRoute)
    };

    const jsonString = JSON.stringify(routeState);
    window.localStorage.setItem('routeState', jsonString);
  },
  getSimpleRoute(route) {
    if (!route) {
      return null;
    }

    let simpleRoute = { name: route.name };
    if (route.params) {
      simpleRoute.params = route.params;
    }
    if (route.query) {
      simpleRoute.query = route.query;
    }
    if (route.hash) {
      simpleRoute.hash = route.hash;
    }

    return simpleRoute;
  },
  clearRouteState() {
    window.localStorage.removeItem('routeState');
  },
  getStoredTableSize() {
    return window.localStorage.getItem('tableSize');
  },
  storeTableSize(newSize) {
    window.localStorage.setItem('tableSize', newSize);
  }
};
