import axios from 'axios';

export default {
  state: {
    substances: [],
    medicallyJustifiableChanges: []
  },
  getters: {
    substances: (state) => state.substances,
    editableSubstances: (state) => {
      let copiedSubstances = _.cloneDeep(state.substances);
      for (const substance of copiedSubstances) {
        const matchingChange = state.medicallyJustifiableChanges.filter((x) => x.substanceKey === substance.substanceKey)[0];
        if (matchingChange) {
          substance.medicallyJustifiable = matchingChange.medicallyJustifiable;
        }
      }
      return copiedSubstances;
    },
    medicallyJustifiableChanges: (state) => state.medicallyJustifiableChanges
  },
  actions: {
    async getSubstances(context, testResultKey) {
      let queryURL = '/api/Subject/GetSubstances';
      let queryObject = {
        testResultKey: testResultKey
      };

      const response = await axios.get(queryURL, {
        params: queryObject
      });

      context.commit('setSubstances', response.data);
    },
    async saveSubstanceChanges(context, testResultKey) {
      const changes = context.state.medicallyJustifiableChanges;
      const request = {
        testResultKey: testResultKey,
        substances: changes
      };

      await axios.post('/api/Subject/SetSubstancesMedicallyJustifiable', request);

      let editedSubstances = _.cloneDeep(context.state.substances);
      for (const substance of editedSubstances) {
        const matchingChange = changes.filter((x) => x.substanceKey === substance.substanceKey)[0];
        if (matchingChange) {
          substance.medicallyJustifiable = matchingChange.medicallyJustifiable;
        }
      }

      context.commit('setSubstances', editedSubstances);
      context.commit('setMedicallyJustifiableChanges', []);
    },
    setMedicallyJustifiable(context, param) {
      let changes = [];
      for (const existingChange of context.state.medicallyJustifiableChanges) {
        if (existingChange.substanceKey !== param.substanceKey) {
          changes.push(existingChange);
        }
      }
      for (const substance of context.state.substances) {
        if (param.substanceKey === substance.substanceKey) {
          if (param.medicallyJustifiable !== substance.medicallyJustifiable) {
            changes.push({ substanceKey: substance.substanceKey, medicallyJustifiable: param.medicallyJustifiable });
          }
        }
      }
      context.commit('setMedicallyJustifiableChanges', changes);
    }
  },
  mutations: {
    setSubstances(state, substances) {
      state.substances = substances;
    },
    setMedicallyJustifiableChanges(state, medicallyJustifiableChanges) {
      state.medicallyJustifiableChanges = medicallyJustifiableChanges;
    }
  }
};
