import axios from 'axios';

export default {
  state: {
    collectionTypes: [],
    yearMonthOptions: [],
    years: [],
    clubs: [],
    gameOfficials: [],
    initialized: false
  },
  actions: {
    async initializeCollectionTypes(context) {
      let collectionTypesPromise = context.dispatch('getCollectionTypes');
      let lookupsPromise = context.dispatch('getLookups');
      await Promise.all([collectionTypesPromise, lookupsPromise]);
      context.commit('setInitialized', true);
    },
    async getCollectionTypes(context) {
      const response = await axios.get('/api/Collections/GetCollectionTypes');
      context.commit('setCollectionTypes', response.data || []);
    },
    async getLookups(context) {
      const response = await axios.get('/api/Collections/GetLookups');
      context.commit('setYearMonthOptions', response.data.yearMonthOptions || []);
      context.commit('setYears', response.data.years || []);
      context.commit('setClubs', response.data.clubs || []);
      context.commit('setGameOfficials', response.data.gameOfficials || []);
    }
  },
  mutations: {
    setCollectionTypes(state, collectionTypes) {
      state.collectionTypes = collectionTypes;
    },
    setYearMonthOptions(state, yearMonthOptions) {
      state.yearMonthOptions = yearMonthOptions;
    },
    setYears(state, years) {
      state.years = years;
    },
    setClubs(state, clubs) {
      state.clubs = clubs;
    },
    setGameOfficials(state, gameOfficials) {
      state.gameOfficials = gameOfficials;
    },
    setInitialized(state, initialized) {
      state.initialized = initialized;
    }
  }
};
