<template>
  <ValidationObserver v-slot="{ invalid }" ref="locationFormValidationRef">
    <DraggableModal
      id="location-modal"
      ref="locationModalRef"
      :title="modalTitle"
      confirm-hide
      :has-edits="hasEdits"
      @hidden="initializeValues"
      size="lg">
      <b-alert ref="errorMessageRef" :show="!!errorInfo" variant="danger">{{ errorInfo }}</b-alert>

      <b-form v-if="internalLocation" @submit.prevent>
        <div class="splitRow">
          <b-form-group label-for="log-date" class="full-width">
            <template #label>
              <span class="text-danger">*</span>
              Log Date
            </template>
            <ValidationProvider v-slot="{ errors }" name="Log Date" rules="required|date">
              <DateTimePicker id="log-date" v-model="internalLocation.logDate"></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox id="voicemail" v-model="internalLocation.voicemail">Voicemail</b-form-checkbox>
          </b-form-group>
          <b-form-group label="Departure Date from Previous Location" label-for="departure-date" class="full-width">
            <ValidationProvider v-slot="{ errors }" name="Departure Date" rules="date">
              <DateTimePicker id="departure-date" v-model="internalLocation.currentLocationDepartureDate" show-clear></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="addressBlock">
          <div class="splitRow noHead">
            <b-form-group label-for="address-one">
              <template #label>
                <span class="text-danger">*</span>
                Address
              </template>
              <ValidationProvider v-slot="{ errors }" name="Address 1" rules="required">
                <b-form-input id="address-one" placeholder="Address 1" v-model="internalLocation.addressOne"></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label-for="address-two">
              <b-form-input id="address-two" placeholder="Address 2" v-model="internalLocation.addressTwo"></b-form-input>
            </b-form-group>
          </div>
          <b-form-checkbox id="non-us-address" v-model="nonUSAddress" @change="nonUSAddressChanged">Non-US Address.</b-form-checkbox>
          <div class="flex-form three-col">
            <b-form-group label-for="city">
              <ValidationProvider v-slot="{ errors }" name="City" rules="required">
                <b-form-input id="city" placeholder="City" v-model="internalLocation.city"></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label-for="state">
              <ValidationProvider v-slot="{ errors }" name="State" rules="required">
                <b-form-select
                  v-show="!nonUSAddress"
                  id="stateDropdown"
                  placeholder="State"
                  v-model="internalLocation.state"
                  :options="usStates"></b-form-select>
                <b-form-input v-show="nonUSAddress" id="stateText" placeholder="State" v-model="internalLocation.state"></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label-for="zip">
              <b-form-input id="zip" placeholder="Zip Code" v-model="internalLocation.zip"></b-form-input>
            </b-form-group>
          </div>
          <b-form-group label-for="country">
            <b-form-input id="country" placeholder="Country" v-model="internalLocation.country"></b-form-input>
          </b-form-group>
        </div>
        <div class="splitRow">
          <b-form-group label-for="from-date" class="full-width">
            <template #label>
              <span class="text-danger">*</span>
              Start Date
            </template>
            <ValidationProvider v-slot="{ errors }" name="Start Date" rules="required|date">
              <DateTimePicker id="from-date" v-model="internalLocation.startDate"></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label="End Date" label-for="until-date" class="full-width">
            <ValidationProvider v-slot="{ errors }" name="End Date" rules="date">
              <DateTimePicker id="until-date" v-model="internalLocation.endDate" show-clear></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="splitRow phone noHead">
          <b-form-group label-for="primary-number">
            <template #label>
              <span class="text-danger">*</span>
              Phone Number
            </template>
            <ValidationProvider v-slot="{ errors }" name="Primary Number" rules="required">
              <b-form-input id="primary-number" placeholder="Primary" v-model="internalLocation.primaryNumber"></b-form-input>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label-for="alternate-number">
            <b-form-input id="alternate-number" placeholder="Alternate" v-model="internalLocation.alternateNumber"></b-form-input>
          </b-form-group>
        </div>

        <b-form-group label="Comments" label-for="comments" class="full-width">
          <b-form-textarea id="comments" v-model="internalLocation.comments"></b-form-textarea>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-alert :show="!subject.isInProgram" variant="warning">Locations cannot be edited for subjects not in program.</b-alert>
          <div class="d-modal-buttons">
            <b-button variant="primary" :disabled="!subject.isInProgram || saveInProgress" @click="saveLocation(invalid)">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
            <b-button variant="secondary" @click="$modal.hide('location-modal')">Close</b-button>
          </div>
        </div>
      </template>
    </DraggableModal>
  </ValidationObserver>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    location: { type: Object, required: false, default: null }
  },
  data() {
    return {
      internalLocation: null,
      saveInProgress: false,
      errorInfo: null,
      nonUSAddress: false
    };
  },
  computed: {
    editingExistingLocation() {
      return !!this.location;
    },
    modalTitle() {
      if (this.location) {
        return 'Edit Location';
      }

      return 'Add New Location';
    },
    hasEdits() {
      return !_.isEqual(this.location, this.internalLocation);
    }
  },
  watch: {
    location() {
      this.initializeValues();
    }
  },
  created() {
    this.initializeValues();
  },
  methods: {
    initializeValues() {
      if (this.editingExistingLocation) {
        this.internalLocation = _.cloneDeep(this.location);
      } else {
        this.internalLocation = {
          subjectKey: this.subject.subjectKey,
          logDate: null,
          currentLocationDepartureDate: null,
          addressOne: null,
          addressTwo: null,
          city: null,
          state: null,
          zip: null,
          primaryNumber: null,
          alternateNumber: null,
          startDate: null,
          endDate: null,
          comments: null
        };
      }

      this.errorInfo = null;
    },
    scrollTop() {
      setTimeout(() => {
        const errorMessage = this.$refs.errorMessageRef;
        if (errorMessage) {
          errorMessage.$el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    },
    nonUSAddressChanged() {
      if (!this.nonUSAddress) this.internalLocation.state = null;
    },
    async saveLocation(invalid) {
      if (invalid) {
        this.$refs.locationFormValidationRef.validate();
        return;
      }
      this.saveInProgress = true;
      if (this.editingExistingLocation) {
        await this.editLocation();
      } else {
        await this.addNewLocation();
      }

      this.saveInProgress = false;
    },
    async addNewLocation() {
      try {
        await axios.post('/api/Subject/AddLocation', this.internalLocation);
        this.makeToast('Location has been added.');
        this.$refs.locationModalRef.hide('confirmed');
        await this.getSubject();
      } catch (error) {
        this.errorInfo = error.response.data;
        if (!this.errorInfo) {
          this.errorInfo = 'Failed to add location, please contact SoA Support.';
        }
        this.scrollTop();
      }
    },
    async editLocation() {
      try {
        await axios.post('/api/Subject/EditLocation', this.internalLocation);
        this.makeToast('Location has been edited.');
        this.$refs.locationModalRef.hide('confirmed');
        await this.getSubject();
      } catch (error) {
        this.errorInfo = error.response.data;
        if (!this.errorInfo) {
          this.errorInfo = 'Edit failed, please contact SoA Support.';
        }
        this.scrollTop();
      }
    }
  }
};
</script>
<style scoped>
.result-summary-section {
  display: flex;
  flex-direction: column;
  height: 100px;
  flex-wrap: wrap;
}
.d-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
