export default {
  namespaced: true,
  state: {
    playersNotTestedDays: 30,
    playersNotTestedSortBy: null,
    playersNotTestedSortDesc: true,
    eventFilter: 'Positive Toxicology',
    eventSortBy: null,
    eventSortDesc: true,
    // unmatchedResultsSortBy: 'specimen.collection',
    // unmatchedResultsSortDesc: true,
    unmatchedLabResultSortBy: 'collectionDateTime',
    unmatchedLabResultSortDesc: true,
    activityLabResultSortBy: 'collectionDateTime',
    activityLabResultSortDesc: true
  },
  getters: {
    playersNotTestedDays: (state) => state.playersNotTestedDays,
    playersNotTestedSortBy: (state) => state.playersNotTestedSortBy,
    playersNotTestedSortDesc: (state) => state.playersNotTestedSortDesc,
    eventFilter: (state) => state.eventFilter,
    eventSortBy: (state) => state.eventSortBy,
    eventSortDesc: (state) => state.eventSortDesc,
    // unmatchedResultsSortBy: (state) => state.unmatchedResultsSortBy,
    // unmatchedResultsSortDesc: (state) => state.unmatchedResultsSortDesc,
    unmatchedLabResultSortBy: (state) => state.unmatchedLabResultSortBy,
    unmatchedLabResultSortDesc: (state) => state.unmatchedLabResultSortDesc,
    activityLabResultSortBy: (state) => state.activityLabResultSortBy,
    activityLabResultSortDesc: (state) => state.activityLabResultSortDesc
  },
  mutations: {
    setPlayersNotTestedDays(state, playersNotTestedDays) {
      state.playersNotTestedDays = playersNotTestedDays;
    },
    setPlayersNotTestedSortBy(state, playersNotTestedSortBy) {
      state.playersNotTestedSortBy = playersNotTestedSortBy;
    },
    setPlayersNotTestedSortDesc(state, playersNotTestedSortDesc) {
      state.playersNotTestedSortDesc = playersNotTestedSortDesc;
    },
    setEventFilter(state, eventFilter) {
      state.eventFilter = eventFilter;
    },
    setEventSortBy(state, eventSortBy) {
      state.eventSortBy = eventSortBy;
    },
    setEventSortDesc(state, eventSortDesc) {
      state.eventSortDesc = eventSortDesc;
    },
    // setUnmatchedResultsSortBy(state, unmatchedResultsSortBy) {
    //   state.unmatchedResultsSortBy = unmatchedResultsSortBy;
    // },
    // setUnmatchedResultsSortDesc(state, unmatchedResultsSortDesc) {
    //   state.unmatchedResultsSortDesc = unmatchedResultsSortDesc;
    // },
    setUnmatchedLabResultSortBy(state, unmatchedLabResultSortBy) {
      state.unmatchedLabResultSortBy = unmatchedLabResultSortBy;
    },
    setUnmatchedLabResultSortDesc(state, unmatchedLabResultSortDesc) {
      state.unmatchedLabResultSortDesc = unmatchedLabResultSortDesc;
    },
    setActivityLabResultSortBy(state, activityLabResultSortBy) {
      state.activityLabResultSortBy = activityLabResultSortBy;
    },
    setActivityLabResultSortDesc(state, activityLabResultSortDesc) {
      state.activityLabResultSortDesc = activityLabResultSortDesc;
    }
  }
};
