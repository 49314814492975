<template>
  <svg class="mJustifiable on" :class="{ clickable: !noClick }" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 509.2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="M441.7,82.8L253.4,2.9c-4.2-1.9-8.8-2.9-13.4-2.9s-9.2,1-13.3,2.9L38.3,82.8C16.3,92.1-.1,113.8,0,140c.5,99.2,41.3,280.7,213.6,363.2,16.7,8,36.1,8,52.8,0,172.3-82.5,213.1-264,213.6-363.2,.1-26.2-16.3-47.9-38.3-57.2Zm-77.47,187.2h-93.23v93.23c0,12.59-14.3,22.77-32,22.77s-32-10.17-32-22.77v-93.23H113.77c-12.59,0-22.77-14.3-22.77-32s10.17-32,22.77-32h93.23V112.77c0-12.59,14.3-22.77,32-22.77s32,10.17,32,22.77v93.23h93.23c12.59,0,22.77,14.3,22.77,32s-10.17,32-22.77,32Z" />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    noClick: { type: Boolean, required: false, default: false }
  }
};
</script>
