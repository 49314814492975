<template>
  <div id="collectionSearch">
    <b-card header-tag="header">
      <template #header>
        <div class="search-card-header">
          <h6 class="header-text">Collection Search</h6>
          <div class="search-options no-print">
            <b-button v-d-modal.specimen-collections-filter-modal variant="primary">
              <font-awesome-icon icon="fa-solid fa-filter" size="lg" />
              <span>Filters</span>
            </b-button>
            <b-button variant="primary" class="print-button" @click="print">
              <font-awesome-icon icon="print" size="lg" />
            </b-button>
          </div>
        </div>
      </template>

      <SpecimenCollectionsTable
        ref="specimenCollectionsTableRef"
        :filter-object="searchedFilterObject"
        :current-page.sync="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        use-nav
        @sort-changed="sortChanged"
        @page-changed="pageChanged"></SpecimenCollectionsTable>
    </b-card>
    <ValidationObserver v-slot="{ invalid }" ref="collectionSearchFilterValidationRef">
      <DraggableModal id="specimen-collections-filter-modal" ref="specimenCollectionsFilterModalRef" title="Filters" @hide="resetQueryToLastSearch">
        <b-form>
          <div v-if="isSA" class="flex-form SAentry">
            <b-form-group label="Matched?" label-for="filter-matched">
              <b-form-select id="filter-matched" v-model="filterObject.matched" :options="defaultSelectOptions"></b-form-select>
            </b-form-group>
            <b-form-group label="Positive?" label-for="filter-positive">
              <b-form-select id="filter-positive" v-model="filterObject.isPositive" :options="defaultSelectOptions"></b-form-select>
            </b-form-group>
          </div>

          <div>
            <b-form-group label="COC #:" label-for="filter-coc-number">
              <span v-for="(cocNumber, index) in filterObject.cocNumbers" :key="index" class="input-with-x">
                <b-form-input id="filter-coc-number" v-model="filterObject.cocNumbers[index]"></b-form-input>
                <b-button v-if="filterObject.cocNumbers[index] || index > 0" variant="danger" @click="removeCOCNumber(index)">
                  <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" />
                </b-button>
                <b-button v-if="index === filterObject.cocNumbers.length - 1" variant="primary" @click="addCOCNumber">
                  <font-awesome-icon icon="fa-solid fa-circle-check" />
                  <font-awesome-icon icon="fa-regular fa-circle-check" />
                </b-button>
              </span>
            </b-form-group>
          </div>

          <div class="flex-form two-col">
            <b-form-group label="Collection Date (From):" label-for="filter-collection-date-from">
              <ValidationProvider rules="date">
                <DateTimePicker id="filter-collection-date-from" v-model="filterObject.collectionDateFrom" date-only class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label="Collection Date (To):" label-for="filter-collection-date-to">
              <ValidationProvider rules="date">
                <DateTimePicker id="filter-collection-date-to" v-model="filterObject.collectionDateTo" date-only class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label="Notification Date (From):" label-for="filter-notification-date-from">
              <ValidationProvider rules="date">
                <DateTimePicker
                  id="filter-notification-date-from"
                  v-model="filterObject.notificationDateFrom"
                  date-only
                  class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label="Notification Date (To):" label-for="filter-notification-date-to">
              <ValidationProvider rules="date">
                <DateTimePicker id="filter-notification-date-to" v-model="filterObject.notificationDateTo" date-only class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label="Appointment Date (From):" label-for="filter-appointment-date-from">
              <ValidationProvider rules="date">
                <DateTimePicker id="filter-appointment-date-from" v-model="filterObject.appointmentDateFrom" date-only class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label="Appointment Date (To):" label-for="filter-appointment-date-to">
              <ValidationProvider rules="date">
                <DateTimePicker id="filter-appointment-date-to" v-model="filterObject.appointmentDateTo" date-only class="mb-2"></DateTimePicker>
              </ValidationProvider>
            </b-form-group>
          </div>
        </b-form>
        <template #modal-footer>
          <div class="w-100">
            <div class="d-modal-buttons">
              <b-button variant="secondary" @click="resetToDefaultFilters()">Default Filters</b-button>
              <b-button variant="primary" @click="getSpecimenCollectionsNewSearch(invalid)">Search</b-button>
            </div>
          </div>
        </template>
      </DraggableModal>
    </ValidationObserver>
  </div>
</template>
<script>
import GlobalFunctions from '@/functions/GlobalFunctions';
import SpecimenCollectionsTable from '@/components/sharedBusiness/SpecimenCollectionsTable';

export default {
  components: {
    SpecimenCollectionsTable
  },
  data() {
    return {
      filterObject: null,
      currentPage: null,
      sortBy: null,
      sortDesc: null,
      searchedFilterObject: null,
      defaultFilters: {
        matched: false,
        isPositive: null,
        cocNumbers: [''],
        collectionDateFrom: null,
        collectionDateTo: null,
        notificationDateFrom: null,
        notificationDateTo: null,
        appointmentDateFrom: null,
        appointmentDateTo: null
      },
      defaultPageControl: {
        currentPage: 1,
        sortBy: 'specimen.collection',
        sortDesc: true
      },
      fieldAnnotations: {
        matched: 'Boolean',
        isPositive: 'Boolean',
        cocNumber: 'Array',
        sortDesc: 'Boolean',
        currentPage: 'Number'
      }
    };
  },
  watch: {
    $route() {
      this.initializeQueryFromURL();
      this.setFilterObject();
    }
  },
  created() {
    this.initializeQueryFromURL();
    this.setFilterObject();
  },
  methods: {
    initializeQueryFromURL() {
      const combinedDefaults = { ...this.defaultFilters, ...this.defaultPageControl };
      const fullQueryObject = GlobalFunctions.convertRouteObjectToQueryObject(this.$route.query, combinedDefaults, this.fieldAnnotations);
      this.filterObject = {
        matched: fullQueryObject.matched,
        isPositive: fullQueryObject.isPositive,
        cocNumbers: fullQueryObject.cocNumbers,
        collectionDateFrom: fullQueryObject.collectionDateFrom,
        collectionDateTo: fullQueryObject.collectionDateTo,
        notificationDateFrom: fullQueryObject.notificationDateFrom,
        notificationDateTo: fullQueryObject.notificationDateTo,
        appointmentDateFrom: fullQueryObject.appointmentDateFrom,
        appointmentDateTo: fullQueryObject.appointmentDateTo
      };
      this.currentPage = fullQueryObject.currentPage;
      this.sortBy = fullQueryObject.sortBy;
      this.sortDesc = fullQueryObject.sortDesc;
    },
    resetToDefaultFilters() {
      this.filterObject = _.cloneDeep(this.defaultFilters);
      this.resetPageControl();
    },
    resetPageControl() {
      this.currentPage = this.defaultPageControl.currentPage;
      this.sortBy = this.defaultPageControl.sortBy;
      this.sortDesc = this.defaultPageControl.sortDesc;
    },
    resetQueryToLastSearch(args) {
      if (args.params !== 'newSearch') {
        const pageControl = {
          currentPage: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        };
        this.initializeQueryFromURL();
        this.currentPage = pageControl.currentPage;
        this.sortBy = pageControl.sortBy;
        this.sortDesc = pageControl.sortDesc;
      }
    },
    getSpecimenCollectionsNewSearch(invalid) {
      if (invalid) {
        this.$refs.collectionSearchFilterValidationRef.validate();
        return;
      }
      this.$refs.specimenCollectionsFilterModalRef.hide('newSearch');
      this.totalSpecimenCollectionsCount = null;
      this.$refs.specimenCollectionsTableRef.$children[2].$el.scrollTop = 0;
      this.resetPageControl();
      this.pushNewSearch(false);
    },
    pageChanged(newPage) {
      this.currentPage = newPage;
      this.pushNewSearch(true);
    },
    sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.currentPage = 1;
      this.sortBy = context.sortBy;
      this.sortDesc = context.sortDesc;
      this.pushNewSearch(true);
    },
    pushNewSearch(replace) {
      const combinedDefaults = { ...this.defaultFilters, ...this.defaultPageControl };
      const fullQueryObject = { ...this.filterObject, currentPage: this.currentPage, sortBy: this.sortBy, sortDesc: this.sortDesc };
      const routeQueryObject = GlobalFunctions.convertQueryObjectToRouteObject(fullQueryObject, combinedDefaults, this.fieldAnnotations);
      if (!_.isEqual(routeQueryObject, this.$route.query)) {
        if (replace) {
          this.$router.replace({ name: 'CollectionSearch', query: routeQueryObject });
        } else {
          this.$router.push({ name: 'CollectionSearch', query: routeQueryObject });
        }
      } else {
        this.setFilterObject();
      }
    },
    setFilterObject() {
      this.searchedFilterObject = _.cloneDeep(this.filterObject);
    },
    addCOCNumber() {
      this.filterObject.cocNumbers.push('');
    },
    removeCOCNumber(index) {
      this.filterObject.cocNumbers.splice(index, 1);
      if (this.filterObject.cocNumbers.length === 0) {
        this.filterObject.cocNumbers = [''];
      }
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
