<template>
  <div>
    <b-button variant="primary" class="print-button no-print" @click="print">
      Print
      <font-awesome-icon icon="print" size="lg" />
    </b-button>
    <div class="print-header">
      <img src="/android-chrome-192x192.png" />

      <h5 style="text-align: center">
        NFL Drug Program
        <br />
        {{ printTitle }}
        <br />
        {{ clubName }}
      </h5>
    </div>
    <div>
      <b-table :items="annualTestLog" :fields="annualTestLogFields" responsive :sort-desc.sync="sortDesc" :sort-by.sync="sortBy">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(cocNumber)="data">
          <div v-if="data.item.cocNumber.length > 0">{{ data.item.cocNumber }}</div>
          <div v-if="data.item.cocNumber.length === 0" class="fillInLine"></div>
        </template>
        <template #cell(comment)="data">
          <div v-if="data.item.comment.length > 0">{{ data.item.comment }}</div>
          <div v-if="data.item.comment.length === 0" class="fillInLine"></div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { DateTime } from 'luxon';

export default {
  props: {
    clubCode: { type: String, required: true }
  },
  data() {
    return {
      annualTestLog: [],
      annualTestLogFields: [
        { key: 'player', label: 'Player', sortable: true, tdClass: 'noBorder' },
        { key: 'cocNumber', label: 'COC Number', tdClass: 'noBorder' },
        { key: 'position', label: 'Pos', sortable: true, tdClass: 'noBorder' },
        { key: 'college', label: 'College', tdClass: 'noBorder' },
        { key: 'comment', label: 'Comments', tdClass: 'noBorder commentCell' }
      ],
      sortBy: 'position',
      sortDesc: false,
      clubName: null
    };
  },
  computed: {
    printTitle() {
      let logYear = DateTime.now().toObject().month < 4 ? DateTime.now().toObject().year - 1 : DateTime.now().toObject().year;
      return logYear + ' Testing Log';
    }
  },
  watch: {
    async clubCode(newValue) {
      if (!newValue) {
        return;
      }

      await this.getAnnualTestLog();
    }
  },
  async created() {
    await this.getAnnualTestLog();
  },
  methods: {
    async getAnnualTestLog() {
      let queryURL = '/api/Collections/GetAnnualTestLog';
      let queryObject = {
        clubCode: this.clubCode
      };

      const response = await axios.get(queryURL, { params: queryObject });

      this.annualTestLog = response.data;
      if (response.data.length > 0) {
        this.clubName = response.data[0].clubName;
      }
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.print-button {
  margin-bottom: 10px;
}

.print-header {
  display: flex;
  align-items: center;
}
</style>
