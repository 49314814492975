import { mapState, mapGetters } from 'vuex';
import { Info } from 'luxon';

let basicMixin = {
  computed: {
    ...mapGetters([
      'isAuth',
      'userInfo',
      'authIsBusy',
      'authFailed',
      'isSA',
      'isDPA',
      'arrivedAtSubjectDetailsFromRoute',
      'subjectsRoute',
      'dpaSubjectDetailsRoute',
      'labResultsRoute',
      'collectionSearchRoute',
      'collectionEntryRoute',
      'cListRoute',
      'defaultSelectOptions',
      'cocNumberSplitRegex',
      'tableSize'
    ]),
    ...mapState({
      showUserMenu: (state) => state.general.showUserMenu,
      showCollapsingSidebar: (state) => state.general.showCollapsingSidebar,
      showPinnedSidebar: (state) => state.general.showPinnedSidebar,
      eventLookups: (state) => state.general.eventLookups,
      eventLookupDictionary: (state) => state.general.eventLookupDictionary,
      toastCount: (state) => state.general.toastCount,
      selectedTestResultKey: (state) => state.general.selectedTestResultKey
    }),
    userName() {
      if (!this.$store.getters.userInfo) {
        return;
      }
      return this.$store.getters.userInfo.userName;
    },
    monthOptions() {
      return Info.months().map((x, index) => ({ value: index + 1, text: x }));
    },
    subjectsInProgram() {
      return this.$store.getters.subjectsInProgram;
    },
    eventLookupsReady() {
      return !!this.eventLookups && !!this.eventLookupDictionary;
    },
    allowFakeLogin() {
      return process.env.VUE_APP_FAKE_LOGIN_ENABLE === 'true';
    },
    environmentName() {
      return process.env.VUE_APP_SOA_ENV;
    },
    printMode() {
      return this.$route.meta.printMode;
    },
    pageIsPrintable() {
      return this.$route.meta.printable;
    },
    subjectDetailsReturnRoute() {
      if (this.arrivedAtSubjectDetailsFromRoute?.name) {
        return this.arrivedAtSubjectDetailsFromRoute;
      }

      return { name: 'Subjects' };
    }
  },
  methods: {
    async initializeBasicData() {
      await this.$store.dispatch('getUserInfo');
      if (this.isAuth) {
        this.$store.dispatch('getStoredTableSize');
        this.$store.dispatch('getStoredRoutes');
        await this.$store.dispatch('getSubjectsInProgram');
        await this.$store.dispatch('getEventLookups');
      }
    },
    toggleUserMenu() {
      this.$store.dispatch('toggleUserMenu');
    },
    closeUserMenu() {
      this.$store.dispatch('closeUserMenu');
    },
    toggleCollapsingSidebar() {
      this.$store.dispatch('toggleCollapsingSidebar');
    },
    togglePinnedSidebar() {
      this.$store.dispatch('togglePinnedSidebar');
    },
    closeCollapsingSidebar() {
      this.$store.dispatch('closeCollapsingSidebar');
    },
    makeToast(message) {
      this.$store.dispatch('incrementToastCount');
      const id = `my-toast-${this.toastCount}`;

      const vNodesMsg = this.$createElement(
        'p',
        {
          class: ['text-center', 'mb-0'],
          on: { click: () => this.$bvToast.hide(id) }
        },
        [message]
      );

      this.$bvToast.toast([vNodesMsg], {
        id: id,
        autoHideDelay: 5000,
        variant: 'success',
        toaster: 'b-toaster-top-center',
        solid: true,
        noCloseButton: true
      });
    },
    getMonthName(monthNumber) {
      return Info.months()[monthNumber - 1];
    },
    getReadableYearMonth(yearMonth) {
      return `${this.getMonthName(yearMonth.month)} ${yearMonth.year}`;
    },
    searchSubjectsInProgram(query) {
      let matchingSubjects = [];
      const lowerCaseQuery = query.toLowerCase();
      for (const subject of this.subjectsInProgram) {
        if (subject.subjectKey == query) {
          matchingSubjects.push(subject);
          continue;
        }
        if (subject.name.toLowerCase().includes(lowerCaseQuery)) {
          matchingSubjects.push(subject);
          continue;
        }
        if (subject.firstName.toLowerCase().includes(lowerCaseQuery)) {
          matchingSubjects.push(subject);
          continue;
        }
        if (subject.firstName.toLowerCase().includes(lowerCaseQuery)) {
          matchingSubjects.push(subject);
          continue;
        }

        const firstNameLastName = subject.firstName + ' ' + subject.lastName;
        if (firstNameLastName.toLowerCase().includes(lowerCaseQuery)) {
          matchingSubjects.push(subject);
        }
      }

      return matchingSubjects;
    },
    getEventLookupByID(id) {
      if (!this.eventLookupDictionary) {
        return null;
      }
      if (!id) {
        return null;
      }
      return this.eventLookupDictionary[id];
    },
    convertCaseEventToReadable(caseEvent) {
      let newCaseEvent = _.cloneDeep(caseEvent);
      newCaseEvent.eventType = this.getEventLookupByID(newCaseEvent.eventType)?.eventLookupDesc;
      newCaseEvent.event = this.getEventLookupByID(newCaseEvent.event)?.eventLookupDesc;
      newCaseEvent.eventReason = this.getEventLookupByID(newCaseEvent.eventReason)?.eventLookupDesc;
      newCaseEvent.eventSubReason = this.getEventLookupByID(newCaseEvent.eventSubReason)?.eventLookupDesc;
      return newCaseEvent;
    },
    joinValidStrings(delimiter, ...strings) {
      return strings.filter(Boolean).join(delimiter);
    },
    selectTestResult(testResultKey) {
      this.$store.commit('setSelectedTestResultKey', testResultKey);
    }
  }
};

export default basicMixin;
