<template>
  <div class="locationTable">
    <div class="location-add-button no-print">
      <b-button
        v-if="subject.isInProgram"
        v-d-modal.location-modal
        variant="primary"
        @click="selectLocation(null)"
        aria-label="Add Location"
        data-balloon-pos="down">
        <font-awesome-icon icon="circle-plus" />
        Add
      </b-button>
    </div>
    <div class="subjectsTableContainer">
      <b-alert variant="danger" :show="showDismissibleAlert" dismissible @dismissed="showDismissibleAlert = false">{{ errorInfo }}</b-alert>
      <b-table
        v-if="subject.locations.length > 0"
        striped
        hover
        foot-clone
        :items="subject.locations"
        :fields="locationFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(logDate)="data">
          <a v-d-modal.location-modal class="fake-link" @click="selectLocation(data.item)">
            <div v-if="data.item.logDate">{{ data.item.logDate | date }},</div>
            <div>{{ data.item.logDate | time }}</div>
          </a>
        </template>
        <template #cell(currentLocationDepartureDate)="data">
          <div v-if="data.item.currentLocationDepartureDate">{{ data.item.currentLocationDepartureDate | date }},</div>
          <div>{{ data.item.currentLocationDepartureDate | time }}</div>
        </template>
        <template #cell(address)="data">
          <div>
            <span>{{ data.item.addressOne }}</span>
            <span v-if="data.item.addressTwo">, {{ data.item.addressTwo }}</span>
          </div>
          <div>{{ data.item.city }}, {{ data.item.state }}, {{ data.item.zip }}</div>
        </template>
        <template #cell(phones)="data">
          <span class="primary" aria-label="Primary Phone Number" data-balloon-pos="right">
            {{ data.item.primaryNumber }}
            <font-awesome-icon icon="fa-sharp fa-solid fa-star" size="xs" />
          </span>
          <div v-if="data.item.alternateNumber">
            {{ data.item.alternateNumber }}
          </div>
        </template>
        <template #cell(startDate)="data">
          <div v-if="data.item.startDate">{{ data.item.startDate | date }},</div>
          <div>{{ data.item.startDate | time }}</div>
        </template>
        <template #cell(endDate)="data">
          <div v-if="data.item.endDate">{{ data.item.endDate | date }},</div>
          <div>{{ data.item.endDate | time }}</div>
        </template>
        <template #cell(voiceMail)="data">
          <font-awesome-icon v-if="data.item.voiceMail" icon="circle-check" size="lg" />
        </template>
        <template #cell(comments)="data">
          <div style="max-width: 100%; overflow: auto">{{ data.item.comments }}</div>
        </template>
        <template #cell(buttons)="data">
          <span class="table-column-buttons icon actionIcon">
            <font-awesome-icon v-show="sendingEmailFor == data.item.addressChangeLogKey" icon="spinner" spin />
            <font-awesome-icon
              v-show="(dateTimeNow < data.item.endDate || !data.item.endDate) && sendingEmailFor != data.item.addressChangeLogKey"
              v-b-popover.hover.focus="'Send Trip Filed Email'"
              icon="envelope"
              size="lg"
              class="fake-link"
              @click="emailTrip(data.item)" />
            <font-awesome-icon
              icon="copy"
              size="lg"
              v-b-popover.hover.focus="'Duplicate Location'"
              class="fake-link"
              @click="duplicateLocation(data.item)" />
            <font-awesome-icon
              icon="trash"
              size="lg"
              v-b-popover.hover.focus="'Delete Location'"
              class="fake-link"
              @click="deleteLocation(data.item)" />
          </span>
        </template>
      </b-table>
      <b-alert v-else variant="info" show>No locations found for this player.</b-alert>
    </div>
    <LocationModal :location="selectedLocation"></LocationModal>
  </div>
</template>
<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import LocationModal from '@/components/modals/LocationModal';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  components: {
    LocationModal
  },
  mixins: [SubjectDetailsMixin],
  data() {
    return {
      baseLocationFields: [
        { key: 'logDate', label: 'Log Date', sortable: true, class: 'dateTime' },
        { key: 'currentLocationDepartureDate', label: 'Prev Departure', sortable: true, tdClass: 'dateTime', thClass: 'pd' },
        { key: 'address', label: 'Address', tdClass: 'detailed-column' },
        { key: 'phones', label: 'Phones', tdClass: 'detailed-column phone' },
        { key: 'startDate', label: 'Start', sortable: true, class: 'dateTime' },
        { key: 'endDate', label: 'End', sortable: true, class: 'dateTime' },
        { key: 'voiceMail', label: 'VM', class: 'centerAlign', popover: 'Voicemail' },
        { key: 'comments', label: 'Comments', class: 'limitedWidth' }
      ],
      programSubjectLocationFields: [{ key: 'buttons', label: '' }],
      selectedLocation: null,
      sendingEmailFor: 0,
      errorInfo: null,
      showDismissibleAlert: false,
      sortBy: null,
      sortDesc: true
    };
  },
  computed: {
    locationFields() {
      if (!this.subject.isInProgram) {
        return this.baseLocationFields;
      }

      return this.baseLocationFields.concat(this.programSubjectLocationFields);
    },
    dateTimeNow() {
      var dateNow = DateTime.now().toSQLDate();
      var timeNow = DateTime.now().toSQLTime();
      return dateNow.toString() + timeNow.toString();
    }
  },
  methods: {
    selectLocation(clickedLocation) {
      this.selectedLocation = clickedLocation;
    },
    async duplicateLocation(clickedLocation) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to duplicate this location?', {
        title: 'Duplicate Location',
        okVariant: 'primary',
        okTitle: 'Duplicate',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        let params = {
          addressChangeLogKey: clickedLocation.addressChangeLogKey
        };

        await axios.post('/api/Subject/DuplicateLocation', params);
        this.makeToast('Location has been duplicated.');
        await this.getSubject();
      }
    },
    async deleteLocation(clickedLocation) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this location?', {
        title: 'Delete Location',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        let params = {
          addressChangeLogKey: clickedLocation.addressChangeLogKey
        };

        await axios.post('/api/Subject/DeleteLocation', params);
        this.makeToast('Location has been deleted.');
        await this.getSubject();
      }
    },
    async emailTrip(clickedLocation) {
      this.sendingEmailFor = clickedLocation.addressChangeLogKey;
      try {
        await axios.post(`/api/Email/EmailTripFiled`, { id: clickedLocation.addressChangeLogKey });
        this.makeToast('Email Sent.');
        this.sendingEmailFor = 0;
      } catch (error) {
        this.errorInfo = error.response.data;
        this.showDismissibleAlert = true;
        this.sendingEmailFor = 0;
      }
    }
  }
};
</script>
<style scoped>
.location-add-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
<style>
.detailed-column {
  font-size: 12px;
}
.detailed-column label {
  font-weight: bold;
}
</style>
