<template>
  <ValidationObserver v-slot="{ invalid }" ref="editAssignmentValidationRef">
    <DraggableModal
      id="edit-assignment-modal"
      ref="editAssignmentModalRef"
      size="md"
      :title="title"
      confirm-hide
      :has-edits="hasEdits"
      @hidden="exitEditMode">
      <template v-if="internalAssignment">
        <b-form @submit.prevent>
          <b-alert :show="!!errorInfo" variant="danger" dismissible>{{ errorInfo }}</b-alert>
          <b-form-group label="DPA" v-show="false" label-for="dpa">
            <b-form-select id="dpa" v-model="presetDPA" :options="[presetDPA]" disabled></b-form-select>
          </b-form-group>
          <div class="flex-form two-col">
            <b-form-group label-for="test-frequency">
              <template #label>
                <span class="text-danger">*</span>
                Test Frequency
              </template>
              <ValidationProvider v-slot="{ errors }" name="Test Frequency" rules="required|numeric|min_value:1">
                <span class="text-danger">{{ errors[0] }}</span>
                <b-form-input id="test-frequency" v-model="internalAssignment.testFrequency"></b-form-input>
              </ValidationProvider>
            </b-form-group>

            <b-form-group label="Protocols">
              <div class="horizontal-form-group protocol-selector">
                <b-form-checkbox v-model="internalAssignment.teamFacilityProtocol" @change="clearOtherSelections('TeamFacilityProtocol')">
                  <font-awesome-icon v-b-popover.hover.focus.top="'Team Facility Protocol'" icon="building" size="2x" />
                </b-form-checkbox>
                <b-form-checkbox v-model="internalAssignment.homeResidenceProtocol" @change="clearOtherSelections('HomeResidenceProtocol')">
                  <font-awesome-icon v-b-popover.hover.focus.top="'Home Residence Protocol'" icon="house" size="2x" />
                </b-form-checkbox>
                <b-form-checkbox v-model="internalAssignment.hotelProtocol" @change="clearOtherSelections('HotelProtocol')">
                  <font-awesome-icon v-b-popover.hover.focus.top="'Hotel Protocol'" icon="hotel" size="2x" />
                </b-form-checkbox>
                <b-form-checkbox v-model="internalAssignment.otherProtocol" @change="clearOtherSelections('OtherProtocol')">
                  <font-awesome-icon v-b-popover.hover.focus.top="'Other Protocol'" icon="question-circle" size="2x" />
                </b-form-checkbox>
              </div>
            </b-form-group>
          </div>
          <b-form-group label="Comments" label-for="comments">
            <b-form-textarea id="comments" v-model="internalAssignment.comments"></b-form-textarea>
          </b-form-group>
        </b-form>
      </template>

      <template #modal-footer>
        <div class="w-100">
          <div class="d-modal-buttons">
            <b-button variant="primary" :disabled="saveInProgress" @click="confirmEdit(invalid)">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
            <b-button variant="secondary" @click="$modal.hide('edit-assignment-modal')">Close</b-button>
          </div>
        </div>
      </template>
    </DraggableModal>
  </ValidationObserver>
</template>
<script>
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    assignment: { type: Object, required: true }
  },
  data() {
    return {
      internalAssignment: null,
      errorInfo: null,
      saveInProgress: false,
      presetDPA: 'ALL DFS'
    };
  },
  computed: {
    title() {
      return `Assignment - ${this.getMonthName(this.assignment.month)} ${this.assignment.season}`;
    },
    hasEdits() {
      return !_.isEqual(this.assignment, this.internalAssignment);
    }
  },
  watch: {
    assignment() {
      this.initializeValues();
    }
  },
  created() {
    this.initializeValues();
  },
  methods: {
    initializeValues() {
      this.internalAssignment = _.cloneDeep(this.assignment);
      this.errorInfo = null;
    },
    exitEditMode() {
      this.initializeValues();
    },
    clearOtherSelections(protocolSelected) {
      switch (protocolSelected) {
        case 'TeamFacilityProtocol':
          this.internalAssignment.homeResidenceProtocol = false;
          this.internalAssignment.hotelProtocol = false;
          this.internalAssignment.otherProtocol = false;
          break;
        case 'HomeResidenceProtocol':
          this.internalAssignment.teamFacilityProtocol = false;
          this.internalAssignment.hotelProtocol = false;
          this.internalAssignment.otherProtocol = false;
          break;
        case 'HotelProtocol':
          this.internalAssignment.teamFacilityProtocol = false;
          this.internalAssignment.homeResidenceProtocol = false;
          this.internalAssignment.otherProtocol = false;
          break;
        case 'OtherProtocol':
          this.internalAssignment.teamFacilityProtocol = false;
          this.internalAssignment.homeResidenceProtocol = false;
          this.internalAssignment.hotelProtocol = false;
          break;
      }
    },
    async confirmEdit(invalid) {
      if (invalid) {
        this.$refs.editAssignmentValidationRef.validate();
        return;
      }
      try {
        this.saveInProgress = true;
        await axios.post('/api/Subject/EditAssignment', this.internalAssignment);

        await this.getSubject();
        this.makeToast('Assignment has been edited.');
        this.saveInProgress = false;
        this.$refs.editAssignmentModalRef.hide('confirmed');
      } catch (error) {
        //this.exitEditMode();
        this.errorInfo = error.response.data;
      } finally {
        this.saveInProgress = false;
      }
    }
  }
};
</script>
