export default {
  convertURLToNullableBool(urlParam, defaultValue) {
    if (!urlParam) {
      return defaultValue;
    }

    if (urlParam === 'any') {
      return null;
    }

    if (urlParam === 'yes') {
      return true;
    }

    if (urlParam === 'no') {
      return false;
    }

    return defaultValue;
  },
  convertNullableBoolToURL(value) {
    if (value === null) {
      return 'any';
    }

    if (value) {
      return 'yes';
    }

    return 'no';
  },
  removeEmpty(obj) {
    //https://stackoverflow.com/a/38340730
    // eslint-disable-next-line no-unused-vars
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  },
  convertRouteObjectToQueryObject(routeObject, defaultValues, fieldAnnotations) {
    let queryObject = _.cloneDeep(defaultValues);
    for (const key in routeObject) {
      if (key === 'currentPage') {
        continue;
      }
      if (fieldAnnotations[key] === 'Boolean') {
        queryObject[key] = this.convertURLToNullableBool(routeObject[key], defaultValues[key]);
        continue;
      }

      if (fieldAnnotations[key] === 'Number') {
        const parsedNumber = Number.parseInt(routeObject[key], 10);
        if (!Number.isNaN(parsedNumber)) {
          queryObject[key] = parsedNumber;
        }
        continue;
      }
      if (fieldAnnotations[key] === 'Array') {
        queryObject[key + 's'] = [].concat(routeObject[key]);
        continue;
      }

      queryObject[key] = routeObject[key];
    }
    return queryObject;
  },
  convertQueryObjectToRouteObject(queryObject, implicitDefaults, fieldAnnotations) {
    let routeObject = {};
    for (const key in queryObject) {
      if (key === 'currentPage') {
        continue;
      }
      if (queryObject[key] === implicitDefaults[key]) {
        continue;
      }

      if (fieldAnnotations[key] === 'Boolean') {
        routeObject[key] = this.convertNullableBoolToURL(queryObject[key]);
        continue;
      }

      if (fieldAnnotations[key.slice(0, -1)] === 'Array') {
        if (queryObject[key] === [] || (queryObject[key].length === 1 && queryObject[key][0] === '')) {
          continue;
        }

        const arrayValues = queryObject[key].filter((x) => !!x);
        if (arrayValues.length > 1) {
          routeObject[key.slice(0, -1)] = arrayValues;
        } else if (arrayValues.length === 1) {
          routeObject[key.slice(0, -1)] = arrayValues[0];
        }

        continue;
      }

      if (!queryObject[key]) {
        continue;
      }

      routeObject[key] = queryObject[key];
    }
    return routeObject;
  },
  printSection(element) {
    const n = document.getElementsByClassName('card');
    const z = document.getElementById(element).closest('div.card');
    Array.from(n).forEach((e) => {
      e.classList.add('no-print');
    });
    z.classList.remove('no-print');
    window.print();
    () => {
      Array.from(n).forEach((e) => {
        e.classList.remove('no-print');
      });
    };
  }
};
