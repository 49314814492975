<template>
  <div>
    <div class="case-event-controls">
      <b-form-select v-model="selectedCaseKey" @change="getCaseEvents">
        <b-form-select-option v-for="caseOption in subject.cases" :key="caseOption.caseKey" :value="caseOption.caseKey">
          {{ caseOption.case }}
        </b-form-select-option>
        <b-form-select-option :value="null">Events not in a case</b-form-select-option>
      </b-form-select>
      <b-button
        v-d-modal.case-event-modal
        variant="primary"
        class="add-button no-print"
        @click="clearSelectedCaseEvent"
        aria-label="Add Event"
        data-balloon-pos="up">
        <font-awesome-icon icon="circle-plus" />
        Add
      </b-button>
    </div>
    <div class="subjectsTableContainer">
      <b-table
        v-if="caseEventRows && caseEventRows.length > 0"
        ref="caseEventsTableRef"
        striped
        hover
        small
        foot-clone
        :items="caseEventRows"
        :fields="caseEventFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc">
        <template #head()="{ label, field, popover }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc" :popover="popover"></TableSortIcons>
        </template>
        <template #cell(caseEventDate)="data">
          <a v-d-modal.case-event-modal class="fake-link" @click="selectCaseEvent(data.item)">{{ data.item.caseEventDate | date }}</a>
        </template>
        <template #cell(cocNumber)="data">
          <a v-if="data.item.testResultKey" v-d-modal.lab-result-modal class="fake-link" @click="selectTestResult(data.item.testResultKey)">
            {{ data.item.cocNumber }}
          </a>
          <span v-if="!data.item.testResultKey">{{ data.item.cocNumber }}</span>
        </template>
        <template #cell(bbottleTested)="data">
          <font-awesome-icon v-if="data.item.bbottleTested" icon="circle-check" size="lg" />
        </template>
        <template #cell(diluteSpecimen)="data">
          <font-awesome-icon v-if="data.item.diluteSpecimen" icon="circle-check" size="lg" />
        </template>
        <template #cell(isPositive)="data">
          <font-awesome-icon v-if="data.item.isPositive" icon="circle-check" size="lg" />
          <span v-if="data.item.medicallyJustifiable" class="MJ" v-b-tooltip.hover :title="'Medically Justifiable'">
            <MedicallyJustifiableIcon :no-click="true"></MedicallyJustifiableIcon>
          </span>
        </template>
        <template #cell(show_details)="data">
          <!-- <span v-show="data.item.caseEventDocuments.length > 0" class="table-column-buttons icon actionIcon" @click="toggleDetails(data.item)">
            <font-awesome-icon icon="fa-caret-down" size="lg" class="fake-link" :class="{ active: data.detailsShowing }" />
          </span> -->
          <div v-for="doc in data.item.caseEventDocuments" :key="doc.caseEventDocumentKey">
            <a class="fake-link" :href="`/api/Document/CaseEventDocument/${doc.caseEventDocumentKey}`" target="_blank">
              {{ doc.documentName }}
            </a>
          </div>
        </template>
        <!-- <template #row-details="data">
          <b-card>
            <b-row v-for="doc in data.item.caseEventDocuments" :key="doc.caseEventDocumentKey">
              <b-col>
                <a :href="`/api/Document/CaseEventDocument/${doc.caseEventDocumentKey}`" target="_blank">{{ doc.documentName }}</a>
              </b-col>
            </b-row>
          </b-card>
        </template> -->
        <template #cell(emailBtn)="data">
          <span class="table-column-buttons icon actionIcon">
            <font-awesome-icon
              v-if="data.item.caseEventDocuments.length > 0"
              v-d-modal.case-event-email-modal
              class="fake-link"
              :icon="data.item.emailsSent.length > 0 ? 'envelope-circle-check' : 'envelope'"
              size="lg"
              @click="selectCaseEvent(data.item)" />
          </span>
        </template>
        <template #cell(deleteBtn)="data">
          <span class="table-column-buttons icon actionIcon">
            <!-- <font-awesome-icon
              v-if="!data.item.actedUpon"
              v-b-popover.hover.focus="'Mark Acted Upon'"
              icon="fa-regular fa-square"
              size="lg"
              class="fa-regular fake-link"
              @click="toggleActedUpon(data.item)" />
            <font-awesome-icon
              v-if="data.item.actedUpon"
              v-b-popover.hover.focus="'Mark Not Acted Upon'"
              icon="square-check"
              size="lg"
              class="fa-regular fake-link"
              @click="toggleActedUpon(data.item)" /> -->
            <font-awesome-icon
              v-if="!data.item.disableEventDeleting"
              v-b-popover.hover.focus="'Delete Case Event'"
              icon="trash"
              size="lg"
              class="fake-link"
              @click="showDeleteEventConfirmation(data.item)" />
          </span>
        </template>
      </b-table>
      <b-alert v-if="!isBusy && caseEventRows.length === 0" variant="info" show>No case events found.</b-alert>
    </div>
    <CaseEventModal
      :case-key="selectedCaseKey"
      :case-event="editableSelectedCaseEvent"
      @caseEventSaved="refreshData"
      @newCaseCreated="refreshData"
      @caseEventDocumentUploaded="getCaseEvents"
      @caseEventDocumentDeleted="getCaseEvents"></CaseEventModal>
    <CaseEventEmailModal :case-event="selectedCaseEvent" @caseEventEmailSent="getCaseEvents"></CaseEventEmailModal>
  </div>
</template>

<script>
import axios from 'axios';
import CaseEventModal from '@/components/modals/CaseEventModal';
import CaseEventEmailModal from '@/components/modals/CaseEventEmailModal';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';
import MedicallyJustifiableIcon from '@/components/icons/MedicallyJustifiableIcon';

export default {
  components: {
    CaseEventModal,
    CaseEventEmailModal,
    MedicallyJustifiableIcon
  },
  mixins: [SubjectDetailsMixin],
  data() {
    return {
      selectedCaseKey: null,
      caseEventRows: [],
      caseEventFields: [
        { key: 'caseEventDate', label: 'Date', sortable: true },
        { key: 'eventType', label: 'Type', sortable: true },
        { key: 'event', label: 'Event', sortable: true },
        { key: 'eventReason', label: 'Reason', sortable: true },
        { key: 'eventSubReason', label: 'Sub Reason', sortable: true },
        { key: 'cocNumber', label: 'COC #', sortable: true },
        { key: 'bbottleTested', label: 'B btl', sortable: true, popover: 'B Bottle Tested', class: 'centerAlign' },
        { key: 'diluteSpecimen', label: 'Dil', sortable: true, popover: 'Dilute Specimen', class: 'centerAlign' },
        { key: 'isPositive', label: 'Pos', sortable: true, popover: 'Positive Test', class: 'centerAlign' },
        { key: 'description', label: 'Description', sortable: true, class: 'limitedWidth' },
        { key: 'show_details', label: 'Documents', class: 'documents' },
        { key: 'emailBtn', label: '', class: 'centerAlign' },
        { key: 'deleteBtn', label: '', class: 'centerAlign' }
      ],
      selectedCaseEvent: null,
      sortBy: null,
      sortDesc: true,
      isBusy: true
    };
  },
  computed: {
    editableSelectedCaseEvent() {
      return this.selectedCaseEvent?.editableCaseEvent;
    }
  },
  async created() {
    if (this.subject.cases.length > 0) {
      this.selectedCaseKey = this.subject.cases[0].caseKey;
    }
    await this.getCaseEvents();
  },
  methods: {
    async refreshData() {
      await this.getSubject();
      if (this.subject.cases.length > 0) {
        this.selectedCaseKey = this.subject.cases[0].caseKey;
        await this.getCaseEvents();
      }
    },
    async getCaseEvents() {
      this.isBusy = true;

      let queryURL = '/api/Subject/GetCaseEvents';
      let queryObject = { subjectKey: this.subject.subjectKey, caseKey: this.selectedCaseKey };

      const response = await axios.get(queryURL, { params: queryObject });
      this.caseEventRows = response.data.map((item) => ({
        ...(item.disableEventEditing = item.eventReason && (item.eventReason == 40 || item.eventReason == 140)),
        ...(item.disableEventDeleting = item.eventType == 6 && (item.event == 16 || item.event == 136)),
        ...this.convertCaseEventToReadable(item),
        _showDetails: item.caseEventDocuments.length > 0,
        editableCaseEvent: item
      }));

      this.isBusy = false;
    },
    selectCaseEvent(clickedCaseEvent) {
      this.selectedCaseEvent = clickedCaseEvent;
    },
    clearSelectedCaseEvent() {
      this.selectedCaseEvent = null;
    },
    async showDeleteEventConfirmation(caseEventItem) {
      const confirmation = await this.$bvModal.msgBoxConfirm(
        'All information including documents for this case event will be deleted.  Are you sure you wish to delete this event?',
        {
          title: 'Delete Case Event',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        }
      );

      if (confirmation) {
        await axios.post('/api/Subject/DeleteCaseEvent/' + caseEventItem.caseEventKey);
        this.makeToast('Case event has been deleted.');
        await this.getCaseEvents();
      }
    },
    toggleDetails(caseEvent) {
      caseEvent._showDetails = !caseEvent._showDetails;
      this.$refs.caseEventsTableRef.refresh();
    },
    async toggleActedUpon(caseEvent) {
      await axios.post('/api/Subject/ToggleCaseEventActedUpon/', { id: caseEvent.caseEventKey });
      await this.getCaseEvents();
    }
  }
};
</script>
