<template>
  <div id="specimenCollectionsTable">
    <div class="tableFiltersHead" ref="tableFiltersHead">
      <b-form-tags v-model="filterTokens" v-if="!hideCounts" id="searchTokens" placeholder="No active search filters">
        <template v-slot="{ tags }">
          <b-form-tag v-for="tag in tags" :key="tag" :title="tag" no-remove>{{ tag }}</b-form-tag>
        </template>
      </b-form-tags>
      <ServerSidePagination v-model="internalCurrentPage" :total-rows="totalSpecimenCollectionsCount" :per-page="pageSize"></ServerSidePagination>
    </div>
    <div class="lazyload-trigger" :style="triggerHeight">
      <b-table
        striped
        hover
        small
        foot-clone
        primary-key="specimen.specimenCollectionKey"
        :items="specimenCollections"
        :fields="specimenCollectionFields"
        :sort-by.sync="internalSortBy"
        :sort-desc.sync="internalSortDesc"
        no-local-sorting
        responsive
        show-empty
        :emptyText="'No collections meet these filter criteria.'"
        class="temp-table-height"
        @sort-changed="sortChanged"
        @scroll.native="onScroll">
        <template #head()="{ label, field, popover }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc" :popover="popover"></TableSortIcons>
        </template>
        <template #cell(specimen.collection)="data">
          <div v-if="data.item.specimen.collection">{{ data.item.specimen.collection | date }},</div>
          <div>{{ data.item.specimen.collection | time }}</div>
        </template>
        <template #cell(subject.currentClub)="data">
          <div
            class="clubIcon no-print"
            :class="[{ fa: tooltipPosition(data.item.subject.currentClub, data.item.subjectTypeName) }, 'team' + data.item.subject.currentClub]"
            data-balloon-pos="up"
            :aria-label="teamTooltip(data.item.subject.currentClub, data.item.subject.subjectTypeName)"></div>
          <div class="print-only">{{ data.item.subject.currentClub }}</div>
        </template>
        <template #cell(subject.name)="data">
          <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: data.item.subject.subjectKey } }">
            {{ data.item.subject.name }}
          </router-link>
        </template>
        <template #cell(specimen.appointment)="data">
          <div v-if="data.item.specimen.appointment">{{ data.item.specimen.appointment | date }},</div>
          <div>{{ data.item.specimen.appointment | time }}</div>
        </template>
        <template #cell(specimen.notification)="data">
          <div v-if="data.item.specimen.notification">{{ data.item.specimen.notification | date }},</div>
          <div>{{ data.item.specimen.notification | time }}</div>
        </template>
        <template #cell(specimen.testType)="data">
          <div>{{ data.item.specimen.testType }}</div>
        </template>
        <template #cell(sepecimen.comment)>
          <div v-if="!hideCounts">
            {{ data.item.specimen.comment }}
          </div>
        </template>
        <template #cell(specimen.bbottleTested)="data">
          <font-awesome-icon v-if="data.item.specimen.bbottleTested" icon="circle-check" size="lg" />
        </template>
        <template #cell(specimen.isPositive)="data">
          <font-awesome-icon v-if="data.item.specimen.isPositive" icon="circle-check" size="lg" />
        </template>
        <template #cell(matchButton)="data">
          <span
            v-if="data.item.specimen.status === 'UnMatched'"
            v-d-modal.match-test-result-modal
            aria-label="Find Potential Match"
            data-balloon-pos="left"
            class="icon actionIcon"
            @click="selectSpecimenCollection(data.item)">
            <font-awesome-icon icon="fa-clipboard-question" size="lg" />
          </span>
        </template>
      </b-table>
      <b-overlay :show="isBusy" no-wrap opacity="0.5"></b-overlay>
    </div>
    <MatchTestResultModal :unmatched-result="selectedSpecimenCollection" @specimenMatched="getSpecimenCollections"></MatchTestResultModal>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import axios from 'axios';
import qs from 'qs';
import MatchTestResultModal from '@/components/modals/MatchTestResultModal';

export default {
  components: {
    MatchTestResultModal
  },
  props: {
    filterObject: { type: Object, required: true },
    hideCounts: { type: Boolean, required: false, default: false },
    currentPage: { type: Number, required: true },
    sortBy: { type: String, required: true },
    sortDesc: { type: Boolean, required: true },
    useNav: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      pageSize: 50,
      internalCurrentPage: null,
      internalSortBy: null,
      internalSortDesc: null,
      isBusy: true,
      specimenCollections: [],
      totalSpecimenCollectionsCount: null,
      triggerHeight: {},
      baseSpecimenCollectionFields: [
        { key: 'subject.currentClub', label: 'Club', sortable: true },
        { key: 'subject.name', label: 'Subject', sortable: false },
        { key: 'specimen.cocNumber', label: 'COC #', sortable: true },
        { key: 'specimen.collection', label: 'Collection', sortable: true, class: 'dateTime' },
        { key: 'specimen.appointment', label: 'Appointment', sortable: true, class: 'dateTime' },
        { key: 'specimen.notification', label: 'Notification', sortable: true, class: 'dateTime' },
        { key: 'specimen.testType', label: 'Test Type', sortable: true }
      ],
      detailPageOnlyFields: [{ key: 'specimen.comment', label: 'Comment', sortable: false }],
      saOnlySpecimenCollectionFields: [
        { key: 'specimen.bbottleTested', label: 'B Btl', class: 'centerAlign', popover: 'B Bottle Tested' },
        { key: 'specimen.isPositive', label: '+', class: 'centerAlign', popover: 'Positive Test' },
        { key: 'matchButton', label: '' }
      ],
      selectedSpecimenCollection: null,
      filterTokens: []
    };
  },
  computed: {
    specimenCollectionFields() {
      if (this.isDPA) {
        if (this.hideCounts) return this.baseSpecimenCollectionFields;
        else return this.baseSpecimenCollectionFields.concat(this.detailPageOnlyFields);
      }

      if (this.hideCounts) return this.baseSpecimenCollectionFields.concat(this.saOnlySpecimenCollectionFields);
      else return this.baseSpecimenCollectionFields.concat(this.detailPageOnlyFields.concat(this.saOnlySpecimenCollectionFields));
    }
  },
  watch: {
    currentPage() {
      this.setPageControlValues();
    },
    sortBy() {
      this.setPageControlValues();
    },
    sortDesc() {
      this.setPageControlValues();
    },
    internalCurrentPage(newValue) {
      this.$emit('update:currentPage', newValue);
    },
    internalSortBy(newValue) {
      this.$emit('update:sortBy', newValue);
    },
    internalSortDesc(newValue) {
      this.$emit('update:sortDesc', newValue);
    },
    filterTokens() {
      setTimeout(() => {
        this.matchHeight();
      });
    },
    async filterObject() {
      this.isBusy = true;
      this.totalSpecimenCollectionsCount = null;
      await this.getSpecimenCollections();
    }
  },
  async created() {
    this.setPageControlValues();
    await this.getSpecimenCollections();
  },
  mounted() {
    this.matchHeight();
    window.addEventListener('resize', this.matchHeight);
  },
  methods: {
    setPageControlValues() {
      this.internalCurrentPage = this.currentPage;
      this.internalSortBy = this.sortBy;
      this.internalSortDesc = this.sortDesc;
    },
    async getSpecimenCollectionsNewPage(newPage) {
      this.isBusy = true;
      this.internalCurrentPage = newPage;
      this.$emit('page-changed', newPage);
      if (!this.useNav) {
        await this.getSpecimenCollections();
      }
    },
    async onScroll(event) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 5) {
        if (this.isBusy) {
          return;
        }
        if (this.totalSpecimenCollectionsCount <= this.specimenCollections.length) {
          return;
        }

        this.isBusy = true;
        this.internalCurrentPage++;
        this.getSpecimenCollections(true);
      }
    },
    async sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.internalCurrentPage = 1;
      this.internalSortBy = context.sortBy;
      this.internalSortDesc = context.sortDesc;
      this.$emit('sort-changed', context);
      if (!this.useNav) {
        await this.getSpecimenCollections();
      }
    },
    async getSpecimenCollections(append) {
      let queryURL = '/api/Specimen/SearchSpecimens';
      if (!this.isSA) {
        queryURL = '/api/Specimen/SearchSpecimensDPA';
      }

      const pageControlObject = {
        pageSize: this.pageSize,
        currentPage: this.internalCurrentPage,
        sortBy: this.internalSortBy,
        sortDesc: this.internalSortDesc
      };
      const queryObject = { ...this.filterObject, ...pageControlObject };
      const response = await axios.get(queryURL, {
        params: queryObject,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        }
      });

      this.totalSpecimenCollectionsCount = response.data.totalResults;

      if (append) {
        this.specimenCollections = this.specimenCollections.concat(response.data.paginatedResults);
      } else {
        this.specimenCollections = response.data.paginatedResults;
      }

      if (!append) this.getSearchTokens();

      this.isBusy = false;
    },
    selectSpecimenCollection(specimenCollection) {
      this.selectedSpecimenCollection = specimenCollection;
    },
    getSearchTokens() {
      this.filterTokens = [];
      const format = 'MM/dd/yyyy';

      if (this.filterObject.matched == true) this.filterTokens.push('Matched');
      else if (this.filterObject.matched == false) this.filterTokens.push('Unmatched');
      if (this.filterObject.isPositive == true) this.filterTokens.push('Positive');
      else if (this.filterObject.isPositive == false) this.filterTokens.push('Negative');
      if (this.filterObject.cocNumbers != null && this.filterObject.cocNumbers.length > 0) {
        var cNums = '';
        this.filterObject.cocNumbers.forEach((o) => {
          cNums += (cNums.length > 0 ? ', ' : '') + o;
        });
        if (cNums.length > 0) this.filterTokens.push(cNums);
      }
      //Collection Date
      if (this.filterObject.collectionDateFrom != null && this.filterObject.collectionDateTo != null)
        this.filterTokens.push(
          'Collection: ' +
            DateTime.fromISO(this.filterObject.collectionDateFrom).toFormat(format) +
            ' - ' +
            DateTime.fromISO(this.filterObject.collectionDateTo).toFormat(format)
        );
      else if (this.filterObject.collectionDateFrom != null && this.filterObject.collectionDateTo == null)
        this.filterTokens.push('Collection From: ' + DateTime.fromISO(this.filterObject.collectionDateFrom).toFormat(format));
      else if (this.filterObject.collectionDateFrom == null && this.filterObject.collectionDateTo != null)
        this.filterTokens.push('Collection To: ' + DateTime.fromISO(this.filterObject.collectionDateTo).toFormat(format));
      //Appointment Date
      if (this.filterObject.appointmentDateFrom != null && this.filterObject.appointmentDateTo != null)
        this.filterTokens.push(
          'Appointment: ' +
            DateTime.fromISO(this.filterObject.appointmentDateFrom).toFormat(format) +
            ' - ' +
            DateTime.fromISO(this.filterObject.appointmentDateTo).toFormat(format)
        );
      else if (this.filterObject.appointmentDateFrom != null && this.filterObject.appointmentDateTo == null)
        this.filterTokens.push('Appointment From: ' + DateTime.fromISO(this.filterObject.appointmentDateFrom).toFormat(format));
      else if (this.filterObject.appointmentDateFrom == null && this.filterObject.appointmentDateTo != null)
        this.filterTokens.push('Appointment To: ' + DateTime.fromISO(this.filterObject.appointmentDateTo).toFormat(format));
      //Notification Date
      if (this.filterObject.notificationDateFrom != null && this.filterObject.notificationDateTo != null)
        this.filterTokens.push(
          'Notification: ' +
            DateTime.fromISO(this.filterObject.notificationDateFrom).toFormat(format) +
            ' - ' +
            DateTime.fromISO(this.filterObject.notificationDateTo).toFormat(format)
        );
      else if (this.filterObject.notificationDateFrom != null && this.filterObject.notificationDateTo == null)
        this.filterTokens.push('Notification From: ' + DateTime.fromISO(this.filterObject.notificationDateFrom).toFormat(format));
      else if (this.filterObject.notificationDateFrom == null && this.filterObject.notificationDateTo != null)
        this.filterTokens.push('Notification To: ' + DateTime.fromISO(this.filterObject.notificationDateTo).toFormat(format));
    },
    teamTooltip(clubCode, role) {
      let n = '';
      switch (clubCode) {
        case 'FA':
        case null:
          n = 'Free Agent';
          break;
        case '':
          n = role;
          break;
        default:
          n = clubCode;
          break;
      }
      return n;
    },
    tooltipPosition(club, role) {
      let n = false;
      if (!club || club == null || club == 'FA' || role == 'NFL Official' || role == 'NFL Employee') n = true;
      return n;
    },
    matchHeight() {
      var heightString = `calc(100% - ${this.$refs.tableFiltersHead.clientHeight + 2}px)`;
      this.triggerHeight = { height: heightString };
    }
  }
};
</script>
