<template>
  <div class="pagiParent">
    <span v-if="totalRows">Displaying results 1 to {{ pageTo }} of {{ totalRows }}.</span>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Number, required: true },
    totalRows: { type: Number, required: false, default: 0 },
    perPage: { type: Number, required: true }
  },
  data() {
    return {
      currentPage: null
    };
  },
  computed: {
    pageTo() {
      return this.currentPage * this.perPage > this.totalRows ? this.totalRows : this.currentPage * this.perPage;
    },
    maxPageNumber() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },
  watch: {
    value() {
      this.setValues();
    }
  },
  created() {
    this.setValues();
  },
  methods: {
    setValues() {
      this.currentPage = this.value;
    }
  }
};
</script>
