<template>
  <div id="subjectDetails">
    <div v-if="subject">
      <b-card header-tag="header" :class="{ teamToggle: headerCollapse }">
        <template #header>
          <div class="search-card-header no-print">
            <div class="search-options">
              <h6>Subject Details</h6>
              <b-form-select
                v-model="selectedSubjectKey"
                :options="subjectsInProgram"
                value-field="subjectKey"
                text-field="name"
                class="search-select"
                @change="changeSubject"></b-form-select>
            </div>
            <div class="sDetailsHeadPrint">
              <b-button variant="primary" @click="shelfToggle" v-b-tooltip.hover :title="`${headerCollapse ? 'Open' : 'Close'} subject information`">
                <font-awesome-icon icon="fa-solid fa-id-card" v-show="headerCollapse" />
                <font-awesome-icon icon="fa-regular fa-id-card" v-show="!headerCollapse" />
              </b-button>
              <b-button variant="primary" class="print-button" @click="print">
                <font-awesome-icon icon="print" size="lg" />
              </b-button>
              <router-link :to="subjectDetailsReturnRoute" title="Previous Page" v-b-tooltip.hover>
                <font-awesome-icon icon="fa-regular fa-circle-xmark" />
              </router-link>
            </div>
          </div>
        </template>
        <SubjectDetailsHeader :collapse="headerCollapse"></SubjectDetailsHeader>
      </b-card>

      <div class="print-only">
        <h2>{{ activeTabName }}</h2>
        <hr />
      </div>
      <b-tabs v-model="activeTab" nav-wrapper-class="tabList" :class="{ active: tlOpen }">
        <b-tab title="Locations" @click="openList">
          <LocationsTab></LocationsTab>
        </b-tab>
        <b-tab title="Events" @click="openList">
          <CaseEventsTab ref="caseEventsTabRef"></CaseEventsTab>
        </b-tab>
        <b-tab title="Specimens" @click="openList">
          <SpecimensTab ref="specimensTabRef"></SpecimensTab>
        </b-tab>
        <b-tab title="Assignments" @click="openList">
          <AssignmentsTab></AssignmentsTab>
        </b-tab>
      </b-tabs>
      <LabResultModal :test-result-key="selectedTestResultKey" @substancesEdited="refreshDataAfterSubstanceEdit"></LabResultModal>
    </div>
    <div v-if="!subject" class="spinner-container">
      <font-awesome-icon icon="spinner" spin size="4x" />
    </div>
  </div>
</template>

<script>
import SpecimensTab from '@/components/subjectDetails/SpecimensTab';
import LocationsTab from '@/components/subjectDetails/LocationsTab';
import CaseEventsTab from '@/components/subjectDetails/CaseEventsTab';
import AssignmentsTab from '@/components/subjectDetails/AssignmentsTab';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';
import LabResultModal from '@/components/modals/LabResultModal';
import SubjectDetailsHeader from '@/components/subjectDetails/SubjectDetailsHeader';

export default {
  components: { SpecimensTab, LocationsTab, CaseEventsTab, LabResultModal, AssignmentsTab, SubjectDetailsHeader },
  mixins: [SubjectDetailsMixin],
  props: {
    subjectKey: { type: Number, required: true }
  },
  data() {
    return {
      selectedSubjectKey: this.subjectKey,
      activeTab: 0,
      tlOpen: false,
      headerCollapse: localStorage.getItem('headerState') ? Boolean(localStorage.getItem('headerState') === 'true') : false,
      tabs: ['#locations', '#events', '#specimens', '#assignments']
    };
  },
  computed: {
    activeTabName() {
      let rawName = this.tabs[this.activeTab];
      let noHash = rawName.slice(1);
      return noHash.charAt(0).toUpperCase() + noHash.slice(1);
    }
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.initializeSubjectDetails(this.subjectKey);
      }

      this.syncTabsFromRoute();
    },
    activeTab() {
      this.syncRouteFromTabs();
    }
  },
  async created() {
    await this.initializeSubjectDetails(this.subjectKey);
  },
  mounted() {
    this.syncTabsFromRoute();
  },
  methods: {
    syncTabsFromRoute() {
      if (!this.$route.hash) {
        this.activeTab = 0;
        return;
      }
      this.activeTab = this.tabs.indexOf(this.$route.hash);
    },
    syncRouteFromTabs() {
      if (!this.$route.hash && this.activeTab === 0) {
        return;
      }
      if (this.$route.hash !== this.tabs[this.activeTab]) {
        this.$router.replace({ hash: this.tabs[this.activeTab] });
      }
    },
    changeSubject(newSubjectKey) {
      if (this.subjectKey !== newSubjectKey) {
        this.$router.push({ name: 'SubjectDetails', params: { subjectKey: newSubjectKey }, hash: this.tabs[this.activeTab] });
      }
    },
    openList() {
      this.tlOpen = !this.tlOpen;
    },
    shelfToggle() {
      this.headerCollapse = !this.headerCollapse;
      localStorage.setItem('headerState', this.headerCollapse);
    },
    print() {
      window.print();
    },
    async refreshDataAfterSubstanceEdit() {
      let tasks = [this.$refs.caseEventsTabRef.getCaseEvents(), this.$refs.specimensTabRef.getSpecimenCollections()];
      await Promise.all(tasks);
    }
  }
};
</script>
<style scoped>
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-select {
  width: 230px;
}

@media only screen and (min-width: 768px) {
  .name-address {
    text-align: left;
  }

  .team-info {
    text-align: center;
  }

  .contact-info {
    text-align: right;
  }

  .team-info hr,
  .contact-info hr {
    display: none;
  }
}
</style>
