<template>
  <div>
    <b-alert :show="!!errorInfo" variant="danger" dismissible>{{ errorInfo }}</b-alert>
    <div class="assignment-controls">
      <b-form-select
        v-show="subject.seasons.length > 0"
        v-model="editableSelectedSeason"
        :options="subject.seasons"
        @change="getAssignments"></b-form-select>
      <b-button
        v-if="subject.isInProgram"
        v-d-modal.new-assignment-modal
        variant="primary"
        class="add-button no-print"
        aria-label="Add Assignment"
        data-balloon-pos="up">
        <font-awesome-icon icon="circle-plus" />
        Add
      </b-button>
    </div>
    <div class="subjectsTableContainer">
      <b-table
        v-if="assignments && assignments.length > 0"
        striped
        hover
        small
        foot-clone
        :items="assignments"
        :fields="assignmentFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(month)="data">
          <a v-d-modal.edit-assignment-modal class="fake-link" @click="selectAssignment(data.item)">
            {{ data.item.season }} - {{ getMonthName(data.item.month) }}
          </a>
        </template>
        <template #cell(protocols)="data">
          <font-awesome-icon v-if="data.item.teamFacilityProtocol" v-b-popover.hover.focus="'Team Facility Protocol'" icon="building" />
          <font-awesome-icon v-if="data.item.homeResidenceProtocol" v-b-popover.hover.focus="'Home Residence Protocol'" icon="house" />
          <font-awesome-icon v-if="data.item.hotelProtocol" v-b-popover.hover.focus="'Hotel Protocol'" icon="hotel" />
          <font-awesome-icon v-if="data.item.otherProtocol" v-b-popover.hover.focus="'Other Protocol'" icon="question-circle" />
        </template>
        <template #cell(buttons)="data">
          <span class="table-column-buttons icon actionIcon">
            <font-awesome-icon icon="trash" size="lg" class="fake-link" @click="deleteAssignment(data.item)" />
          </span>
        </template>
      </b-table>
      <b-alert v-if="assignments && assignments.length === 0" variant="info" show>No assignments found for that subject and year.</b-alert>
    </div>
    <EditAssignmentModal v-if="selectedAssignment" :assignment="selectedAssignment"></EditAssignmentModal>
    <NewAssignmentModal></NewAssignmentModal>
  </div>
</template>
<script>
import axios from 'axios';

import EditAssignmentModal from '@/components/modals/EditAssignmentModal';
import NewAssignmentModal from '@/components/modals/NewAssignmentModal';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  components: {
    EditAssignmentModal,
    NewAssignmentModal
  },
  mixins: [SubjectDetailsMixin],
  data() {
    return {
      selectedAssignment: null,
      assignmentFields: [
        { key: 'month', label: 'Year - Month', sortable: true },
        { key: 'testFrequency', label: 'Test Frequency', sortable: true, class: 'centerAlign' },
        { key: 'protocols', label: 'Protocol' },
        { key: 'comments', label: 'Comments' },
        { key: 'buttons', label: '' }
      ],
      errorInfo: null,
      sortBy: null,
      sortDesc: true
    };
  },
  computed: {
    editableSelectedSeason: {
      get() {
        return this.selectedSeason;
      },
      set(value) {
        this.$store.commit('setSelectedSeason', value);
      }
    }
  },
  methods: {
    selectAssignment(clickedAssignment) {
      this.selectedAssignment = clickedAssignment;
    },
    async deleteAssignment(clickedAssignment) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this assignment?', {
        title: 'Delete Assignment',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        let params = {
          subjectDPAKey: clickedAssignment.subjectDPAKey
        };

        try {
          await axios.post('/api/Subject/DeleteAssignment', params);
          this.makeToast('Assignment has been deleted.');
          await this.getSubject();
        } catch (error) {
          this.errorInfo = error.response.data;
        }
      }
    }
  }
};
</script>
