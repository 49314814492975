<template>
  <div>
    <b-button variant="primary" class="print-button no-print" @click="print">
      Print
      <font-awesome-icon icon="print" size="lg" />
    </b-button>
    <div class="print-header">
      <img src="/android-chrome-192x192.png" />
      <h1>Lab Result</h1>
    </div>
    <div class="d-modal-content">
      <LabResultDetails v-if="testResult" :test-result="testResult" :is-print-view="true"></LabResultDetails>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import LabResultDetails from '@/components/sharedBusiness/LabResultDetails';

export default {
  components: {
    LabResultDetails
  },
  props: {
    testResultKey: { type: Number, required: true }
  },
  data() {
    return {
      testResult: null
    };
  },
  watch: {
    async testResultKey(newValue) {
      if (!newValue) {
        return;
      }

      await this.getLabResults();
    }
  },
  async created() {
    await this.getLabResults();
  },
  methods: {
    async getLabResults() {
      let queryURL = '/api/TestResult/GetLabResult';
      let queryObject = { testResultKey: this.testResultKey };

      const response = await axios.get(queryURL, { params: queryObject });

      this.testResult = response.data;
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.print-button {
  margin-bottom: 10px;
}

.d-modal-content {
  border: none;
}

.print-header {
  display: flex;
  align-items: center;
}
</style>
