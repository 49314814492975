<template>
  <div>
    <b-nav v-click-outside="closeCollapsingSidebar" vertical>
      <li class="navOpener">
        <div @click="toggleCollapsingSidebar">
          <font-awesome-icon icon="caret-right" size="xl" />
        </div>
        <div @click="togglePinnedSidebar" v-b-tooltip.hover :title="`${showPinnedSidebar ? 'Unp' : 'P'}in Sidebar`">
          <font-awesome-icon icon="fa-thumb-tack" size="lg" />
        </div>
      </li>
      <b-nav-item
        :to="{ name: 'Home' }"
        :link-classes="getLinkClasses('Home')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Home'">
        <font-awesome-icon icon="home" size="lg" class="sidebar-icon" />
        <div>
          <span>Home</span>
        </div>
      </b-nav-item>
      <b-nav-item
        :to="subjectsRoute"
        :link-classes="getLinkClasses('Subjects')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Subjects'">
        <font-awesome-icon icon="users" size="lg" class="sidebar-icon" />
        <div>
          <span>Subjects</span>
        </div>
      </b-nav-item>
      <b-nav-item
        :to="labResultsRoute"
        :link-classes="getLinkClasses('LabResults')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Lab Results'">
        <font-awesome-icon icon="vials" size="lg" class="sidebar-icon" />
        <div>
          <span>Lab Results</span>
        </div>
      </b-nav-item>
      <b-nav-item
        :to="collectionSearchRoute"
        :link-classes="getLinkClasses('CollectionSearch')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Collection Search'">
        <font-awesome-icon icon="magnifying-glass" class="sidebar-icon" />
        <div>
          <span>Collection Search</span>
        </div>
      </b-nav-item>
      <b-nav-item
        :to="collectionEntryRoute"
        :link-classes="getLinkClasses('CollectionEntry')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Collection Entry'">
        <font-awesome-icon icon="bottle-droplet" size="lg" class="sidebar-icon" />
        <div>
          <span>Collection Entry</span>
        </div>
      </b-nav-item>
      <b-nav-item
        :to="cListRoute"
        :link-classes="getLinkClasses('CList')"
        @click="closeCollapsingSidebar"
        v-b-tooltip.hover
        :title="showPinnedSidebar || showCollapsingSidebar ? '' : 'Assigned Collections'">
        <font-awesome-icon icon="list" size="lg" class="sidebar-icon" />
        <div>
          <span>Assigned Collections</span>
        </div>
      </b-nav-item>
    </b-nav>
    <div id="navShelf">
      <div @click="toggleCollapsingSidebar"><font-awesome-icon icon="caret-right" size="xl" /></div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    getLinkClasses(linkRouteName) {
      const currentRouteMatches = linkRouteName === this.$route.name;
      const subjectDetailsMatches = this.$route.name == 'SubjectDetails' && linkRouteName === this.subjectDetailsReturnRoute.name;
      if (currentRouteMatches || subjectDetailsMatches) {
        return ['router-link-exact-active', 'router-link-active'];
      }
      return '';
    }
  }
};
</script>
<style scoped>
.sidebar-icon {
  width: 30px;
}
</style>
