import NotFound from '@/views/NotFound';
import AccessDenied from '@/views/AccessDenied';
import Home from '@/views/Home';
import Login from '@/views/Login';
import LabResults from '@/views/LabResults';
import CollectionSearch from '@/views/CollectionSearch';
import Subjects from '@/views/Subjects';
import CollectionEntry from '@/views/CollectionEntry';
import CList from '@/views/CList';
import SubjectDetails from '@/views/SubjectDetails';
import LabResultPrintable from '@/views/print/LabResultPrintable';
import AnnualLog from '@/views/print/AnnualLog';

import store from '../store';

await store.dispatch('getUserInfo');

export const routes = [
  {
    path: '/404',
    component: NotFound,
    name: 'NotFound',
    meta: {
      title: 'Not Found',
      isPublic: true
    }
  },
  {
    path: '/accessDenied',
    component: AccessDenied,
    name: 'AccessDenied',
    meta: {
      title: 'Access Denied',
      isPublic: true
    }
  },
  {
    path: '/',
    component: Home,
    name: 'Home',
    meta: {
      title: 'Home',
      allowedRoles: ['SA']
    }
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      title: 'Login',
      isPublic: true,
      publicOnly: true
    }
  },
  {
    path: '/labResults',
    component: LabResults,
    name: 'LabResults',
    meta: {
      title: 'Lab Results',
      allowedRoles: ['SA'],
      printable: true
    }
  },
  {
    path: '/collectionSearch',
    component: CollectionSearch,
    name: 'CollectionSearch',
    meta: {
      title: 'Collection Search',
      allowedRoles: ['SA', 'DPA'],
      printable: true
    }
  },
  {
    path: '/subjects',
    component: Subjects,
    name: 'Subjects',
    meta: {
      title: 'Subjects',
      allowedRoles: ['SA'],
      printable: true
    }
  },
  {
    path: '/collectionEntry',
    component: CollectionEntry,
    name: 'CollectionEntry',
    meta: {
      title: 'Collection Entry',
      allowedRoles: ['SA', 'DPA'],
      printable: true
    }
  },
  {
    path: '/cList',
    component: CList,
    name: 'CList',
    meta: {
      title: 'Assigned Collections',
      allowedRoles: ['SA', 'DPA'],
      printable: true
    }
  },
  {
    path: '/subject/:subjectKey?',
    component: SubjectDetails,
    name: 'SubjectDetails',
    meta: {
      title: 'Subject Details',
      printable: true
    },
    props: (route) => {
      let isSA = store.getters.isSA;
      if (!route.params.subjectKey && isSA) {
        return;
      }

      const parsedSubjectKey = Number.parseInt(route.params.subjectKey, 10);
      if (Number.isNaN(parsedSubjectKey)) {
        return;
      }
      return {
        subjectKey: parsedSubjectKey
      };
    }
  },
  {
    path: '/print/testResult/:testResultKey',
    component: LabResultPrintable,
    name: 'LabResultPrintable',
    meta: {
      title: 'Lab Result',
      printMode: true,
      printable: true
    },
    props: (route) => {
      if (!route.params.testResultKey) {
        return;
      }

      const parsedTestResultKey = Number.parseInt(route.params.testResultKey, 10);
      if (Number.isNaN(parsedTestResultKey)) {
        return;
      }
      return {
        testResultKey: parsedTestResultKey
      };
    }
  },
  {
    path: '/annualLog/:clubCode',
    component: AnnualLog,
    name: 'AnnualLog',
    meta: {
      title: 'Annual Log',
      allowedRoles: ['SA', 'DPA'],
      printMode: true,
      printable: true
    },
    props: (route) => {
      if (!route.params.clubCode) {
        return;
      }
      return {
        clubCode: route.params.clubCode
      };
    }
  }
];
