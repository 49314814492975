<template>
  <b-card id="eventsTable" header-tag="header">
    <template #header>
      <div class="search-card-header">
        <span class="no-print">Activity (Last 30 Days)</span>
        <span class="print-only">{{ selectedActivityFilter }} (Last 30 Days)</span>
        <div class="search-options">
          <b-form-select v-model="selectedActivityFilter" :options="activityFilters" class="search-select no-print"></b-form-select>
          <b-button variant="primary" class="print-button" @click="print('divActivityBody', selectedActivityFilter)">
            <font-awesome-icon icon="print" size="lg" />
          </b-button>
        </div>
      </div>
    </template>
    <div id="divActivityBody">
      <b-table
        v-if="!isBusy && showEvents"
        borderless
        hover
        show-empty
        :emptyText="'No events in the past 30 days meet these criteria.'"
        :items="filteredEvents"
        :fields="caseEventFields"
        :sort-desc.sync="eventSortDesc"
        :sort-by.sync="eventSortBy">
        <template #head()="{ label, field, popover }">
          <TableSortIcons :label="label" :field="field" :sort-by="eventSortBy" :sort-desc="eventSortDesc" :popover="popover"></TableSortIcons>
        </template>
        <template #cell(subject.name)="data">
          <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: data.item.subject.subjectKey }, hash: '#events' }">
            {{ data.item.subject.name }}
          </router-link>
        </template>
        <template #cell(caseEvent.caseEventDate)="data">
          {{ data.item.caseEvent.caseEventDate | date }}
        </template>
        <template #cell(caseEvent.bbottleTested)="data">
          <font-awesome-icon v-if="data.item.caseEvent.bBottle" icon="circle-check" size="lg" />
        </template>
        <template #cell(caseEvent.diluteSpecimen)="data">
          <font-awesome-icon v-if="data.item.caseEvent.diluteSpecimen" icon="circle-check" size="lg" />
        </template>
        <template #cell(caseEvent.isPositive)="data">
          <font-awesome-icon v-if="data.item.caseEvent.isPositive" icon="circle-check" size="lg" />
        </template>
      </b-table>
      <LabResultsTable
        v-if="showLabResults"
        ref="labResultsRef"
        :filter-object="recentPositiveLabResultfilterObject"
        hide-tokens
        home-activity
        :current-page.sync="recentPositiveLabResultfilterObject.currentPage"
        :sort-by.sync="activityLabResultSortBy"
        :sort-desc.sync="activityLabResultSortDesc"></LabResultsTable>
      <div v-if="isBusy" class="spinner-container">
        <font-awesome-icon icon="spinner" spin size="4x" />
      </div>
      <!-- <b-alert :show="!isBusy && filteredEvents.length === 0" variant="info">No events in the past 30 days for that criteria.</b-alert> -->
    </div>
    <span v-html="style"></span>
  </b-card>
</template>
<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import VuexFunctions from '@/functions/VuexFunctions';
import LabResultsTable from '@/components/sharedBusiness/LabResultsTable';
import GlobalFunctions from '@/functions/GlobalFunctions';

export default {
  components: {
    LabResultsTable
  },
  data() {
    return {
      isBusy: true,
      activityFilters: ['Positive Toxicology', 'Missed Test'],
      caseEvents: [],
      caseEventFields: [
        { key: 'subject.name', label: 'Subject', sortable: true },
        { key: 'caseEvent.caseEventDate', label: 'Date', sortable: true },
        { key: 'caseEvent.eventType', label: 'Type', sortable: true },
        { key: 'caseEvent.event', label: 'Event', sortable: true },
        { key: 'caseEvent.eventReason', label: 'Reason', sortable: true },
        { key: 'caseEvent.subReason', label: 'Sub Reason', sortable: true },
        { key: 'caseEvent.cocNumber', label: 'COC #', sortable: true },
        { key: 'caseEvent.bbottleTested', label: 'B Btl', class: 'centerAlign', popover: 'B Bottle Tested' },
        { key: 'caseEvent.diluteSpecimen', label: 'Dil', class: 'centerAlign', popover: 'Dilute Specimen' },
        { key: 'caseEvent.isPositive', label: 'Pos', class: 'centerAlign', popover: 'Positive Test' }
      ],
      recentPositiveLabResultfilterObject: {
        matched: null,
        isAnnual: null,
        isNormal: null,
        scoutingCombine: null,
        preEmployment: null,
        gameOfficial: null,
        invalidResult: null,
        blindTest: false,
        specimenSubstituted: null,
        actedUpon: false,
        isPositive: true,
        cocNumbers: [''],
        collectionDateFrom: null,
        collectionDateTo: null,
        importDateFrom: DateTime.now().minus({ days: 30 }).toISODate(),
        importDateTo: null,
        currentPage: 1,
        pageSize: 50,
        sortBy: 'collectionDateTime',
        sortDesc: false,
        getAllRows: false
      },
      style: null
    };
  },
  computed: {
    selectedActivityFilter: VuexFunctions.mapComputed('home', 'eventFilter'),
    eventSortBy: VuexFunctions.mapComputed('home', 'eventSortBy'),
    eventSortDesc: VuexFunctions.mapComputed('home', 'eventSortDesc'),
    activityLabResultSortBy: VuexFunctions.mapComputed('home', 'activityLabResultSortBy'),
    activityLabResultSortDesc: VuexFunctions.mapComputed('home', 'activityLabResultSortDesc'),
    showEvents() {
      return this.selectedActivityFilter == 'Missed Test';
    },
    showLabResults() {
      return this.selectedActivityFilter == 'Positive Toxicology';
    },
    filteredEvents() {
      const readableEvents = this.caseEvents.map((item) => ({ caseEvent: this.convertCaseEventToReadable(item.caseEvent), subject: item.subject }));
      return _.filter(
        readableEvents,
        (x) =>
          (x.caseEvent.eventType && x.caseEvent.eventType.toLowerCase().includes(this.selectedActivityFilter.toLowerCase())) ||
          (x.caseEvent.event && x.caseEvent.event.toLowerCase().includes(this.selectedActivityFilter.toLowerCase())) ||
          (x.caseEvent.eventReason && x.caseEvent.eventReason.toLowerCase().includes(this.selectedActivityFilter.toLowerCase()))
      );
    }
  },
  async created() {
    await this.getRecentCaseEvents();
  },
  methods: {
    async getRecentCaseEvents() {
      this.isBusy = true;
      this.caseEvents = [];
      let queryURL = '/api/Subject/GetRecentCaseEvents';
      const response = await axios.get(queryURL);
      this.caseEvents = response.data;
      this.isBusy = false;
    },
    print(element, headerText) {
      this.style = '<style> @page { size: landscape !important; } </style>';
      console.log(element);
      setTimeout(() => {
        GlobalFunctions.printSection(element, headerText);
        this.style = '<style> @page { size: portrait; } </style>';
      });
    },
    refreshLabResults() {
      return this.$refs.labResultsRef.refreshCurrentTestResults();
    }
  }
};
</script>
<style scoped>
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-select {
  width: 230px;
}
</style>
