var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{ref:"draggableModalRef",attrs:{"draggable":".d-modal-header","adaptive":"","click-to-close":false,"name":_vm.id,"width":_vm.width,"shift-y":0.1,"classes":"d-modal-dialog"},on:{"before-open":(...args) => _vm.$emit('show', ...args),"opened":(...args) => {
      _vm.currentlyOpen = true;
      _vm.$emit('shown', ...args);
    },"before-close":_vm.confirmHideModal,"closed":(...args) => {
      _vm.currentlyOpen = false;
      _vm.$emit('hidden', ...args);
    }}},[_c('div',{staticClass:"d-modal-content"},[_c('header',{staticClass:"d-modal-header"},[_c('h5',[_vm._v(_vm._s(_vm.title))]),_c('font-awesome-icon',{staticClass:"close-button",attrs:{"icon":"xmark","size":"lg"},on:{"click":_vm.hide}})],1),_c('div',{staticClass:"d-modal-body"},[_vm._t("default")],2),_c('div',[_c('hr'),_c('div',{staticClass:"d-modal-footer"},[_vm._t("modal-footer")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }