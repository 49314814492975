<template>
  <modal
    ref="draggableModalRef"
    draggable=".d-modal-header"
    adaptive
    :click-to-close="false"
    :name="id"
    :width="width"
    :shift-y="0.1"
    classes="d-modal-dialog"
    @before-open="(...args) => $emit('show', ...args)"
    @opened="
      (...args) => {
        currentlyOpen = true;
        $emit('shown', ...args);
      }
    "
    @before-close="confirmHideModal"
    @closed="
      (...args) => {
        currentlyOpen = false;
        $emit('hidden', ...args);
      }
    ">
    <div class="d-modal-content">
      <header class="d-modal-header">
        <h5>{{ title }}</h5>
        <font-awesome-icon class="close-button" icon="xmark" size="lg" @click="hide" />
      </header>
      <div class="d-modal-body">
        <slot></slot>
      </div>
      <div>
        <hr />
        <div class="d-modal-footer">
          <slot name="modal-footer"></slot>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    size: { type: String, default: 'md' },
    confirmHide: { type: Boolean, default: false },
    hasEdits: { type: Boolean, default: false },
    respondToEscape: { type: Boolean, default: true }
  },
  data() {
    return {
      confirmingClose: false,
      waitingForEscKeyUp: false,
      currentlyOpen: false
    };
  },
  computed: {
    width() {
      switch (this.size) {
        case 'sm':
          return 400;
        case 'md':
          return 500;
        case 'lg':
          return 800;
        case 'xl':
          return 1440;
        default:
          return 500;
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (!this.respondToEscape) {
        return;
      }
      if (!this.currentlyOpen) {
        return;
      }
      if (this.confirmingClose) {
        return;
      }
      if (this.waitingForEscKeyUp) {
        return;
      }
      if (e.key === 'Escape') {
        this.waitingForEscKeyUp = true;
        this.hide();
      }
    });
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.waitingForEscKeyUp = false;
      }
    });
  },
  methods: {
    hide(args) {
      this.$modal.hide(this.id, args);
    },
    async confirmHideModal(modalEvent) {
      if (!this.confirmHide) {
        this.$emit('hide', modalEvent);
        return;
      }
      if (modalEvent.params === 'confirmed') {
        this.$emit('hide', modalEvent);
        return;
      }
      if (!this.hasEdits) {
        this.$emit('hide', modalEvent);
        return;
      }

      modalEvent.cancel();

      this.confirmingClose = true;
      try {
        const confirmation = await this.$bvModal.msgBoxConfirm('Closing this window will clear your changes.', {
          title: 'Clear Changes?',
          okVariant: 'warning',
          okTitle: 'OK',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        });

        if (confirmation) {
          this.hide('confirmed');
        }
      } finally {
        setTimeout(() => {
          this.confirmingClose = false;
        }, 100);
      }
    }
  }
};
</script>
<style scoped>
.d-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.d-modal-header h5 {
  margin: 0;
}

.d-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close-button {
  cursor: pointer;
}

.d-modal-footer {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
<style>
.vm--modal {
  height: auto !important;
}
</style>
