export default {
  bind: (el) => {
    el.style.cursor = 'pointer';
  },
  inserted: (el, binding, vnode) => {
    el.newDirClickHandler = () => {
      const modalName = Object.keys(binding.modifiers)[0];
      vnode.context.$modal.show(modalName);
    };

    el.addEventListener('click', el.newDirClickHandler);
  },
  unbind: (el) => {
    el.removeEventListener('click', el.newDirClickHandler);
  }
};
