<template>
  <div>
    <h1>Access Denied</h1>
    <b-button variant="primary" @click="logout">Return to Login</b-button>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  methods: {
    async logout() {
      await axios.post('/api/Account/Logout');
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>
