import axios from 'axios';
import usStates from '@/staticData/usStates';

export default {
  state: {
    subjectKey: null,
    subject: null,
    subjectError: null,
    selectedSeason: false,
    assignments: null,
    usStates: usStates
  },
  actions: {
    clearSubjectDetails(context) {
      context.commit('initializeSubjectDetails', null);
    },
    async initializeSubjectDetails(context, subjectKey) {
      context.commit('initializeSubjectDetails', subjectKey);
      await context.dispatch('getSubject');
    },
    async getSubject(context) {
      try {
        const response = await axios.get(`/api/Subject/GetSubject?id=${context.state.subjectKey}`);
        context.commit('setSubject', response.data);
        if (context.getters.isSA) {
          await context.dispatch('getAssignments');
        }
      } catch (error) {
        context.commit('setError', error);
      }
    },
    async getAssignments(context) {
      let queryURL = '/api/Subject/GetAssignments';
      let queryObject = {
        subjectKey: context.state.subjectKey,
        season: context.state.selectedSeason
      };
      const response = await axios.get(queryURL, {
        params: queryObject
      });
      context.commit('setAssignments', response.data);
    }
  },
  mutations: {
    initializeSubjectDetails(state, subjectKey) {
      state.subjectKey = subjectKey;
      state.subject = null;
    },
    setSubject(state, subject) {
      state.subject = subject;
      if (subject.seasons.length > 0) {
        state.selectedSeason = subject.seasons[0];
      }
    },
    setAssignments(state, assignments) {
      state.assignments = assignments;
    },
    setError(state, error) {
      state.subjectError = error;
    },
    setSelectedSeason(state, selectedSeason) {
      state.selectedSeason = selectedSeason;
    }
  }
};
