<template>
  <div id="app">
    <TopNavBar v-show="!printMode" class="no-print"></TopNavBar>
    <div class="body-content" :class="tableSize + 'Table'">
      <div class="row">
        <div v-if="showSideBar" class="navDiv no-print" :class="{ active: showCollapsingSidebar, pinned: showPinnedSidebar }">
          <SideBar></SideBar>
        </div>
        <main class="main-content" :class="{ 'col-10': showSideBar, 'col-12': !showSideBar }">
          <router-view></router-view>
        </main>
      </div>
    </div>
    <div id="menuParent" :class="{ open: showUserMenu }">
      <UserMenu></UserMenu>
    </div>
    <span v-html="style"></span>
  </div>
</template>

<script>
import TopNavBar from '@/components/layout/TopNavBar';
import SideBar from '@/components/layout/SideBar';
import UserMenu from '@/components/layout/UserMenu';

export default {
  name: 'App',
  components: {
    TopNavBar,
    SideBar,
    UserMenu
  },
  computed: {
    showSideBar() {
      return this.isAuth && this.isSA && !this.printMode;
    },
    style() {
      let v = 'portrait';
      switch (this.$route.name) {
        case 'LabResults':
        case 'CollectionSearch':
        case 'CollectionEntry':
          v = 'landscape';
          break;
        default:
          break;
      }
      return `<style> @page { size: ${v} } </style>`;
    }
  },
  async created() {
    await this.initializeBasicData();
  }
};
</script>

<style lang="scss">
@import './scss/brandingColors.scss';
@import './scss/fonts.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './scss/customBootstrap.scss';
@import './scss/styles.scss';
@import './scss/ContentPanes.scss';
@import './scss/print.scss';
@import './css/balloon.min.css';
</style>
