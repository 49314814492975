import axios from 'axios';

export default {
  state: {
    userInfo: null,
    isBusy: false,
    authFailed: false
  },
  getters: {
    isAuth: (state) => !!state.userInfo,
    isSA: (state) => state.userInfo && state.userInfo.role === 'SA',
    isDPA: (state) => state.userInfo && state.userInfo.role === 'DPA',
    userInfo: (state) => state.userInfo,
    authIsBusy: (state) => state.isBusy,
    authFailed: (state) => state.authFailed
  },
  actions: {
    async getUserInfo(context) {
      context.commit('setBusy', true);
      try {
        const response = await axios.get('/api/account/CurrentUser');
        if (!response.data.userKey) {
          context.commit('clearUserInfo');
        } else {
          context.commit('setUserInfo', response.data);
        }
      } catch (error) {
        context.commit('clearUserInfo');
        context.commit('setAuthFailed');
      } finally {
        context.commit('setBusy', false);
      }
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      state.authFailed = false;
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
    setBusy(state, isBusyFlag) {
      state.isBusy = isBusyFlag;
    },
    setAuthFailed(state) {
      state.authFailed = true;
    }
  }
};
