<template>
  <ValidationObserver v-slot="{ invalid }" ref="collectionEntryValidationRef">
    <DraggableModal
      id="collection-entry-modal"
      class="collection-entry-modal"
      ref="collectionEntryModalRef"
      size="xl"
      :title="modalTitle"
      confirm-hide
      :has-edits="hasEdits"
      @hidden="initializeValues">
      <b-alert :show="!!errorInfo" variant="danger">{{ errorInfo }}</b-alert>
      <b-form v-if="internalCollection" class="collection-entry-container">
        <div>
          <b-form-group label-for="cocNumber">
            <template #label>
              <span class="text-danger">*</span>
              COC #:
            </template>
            <ValidationProvider v-slot="{ errors }" name="COC Number" rules="required">
              <b-form-input id="cocNumber" v-model="internalCollection.cocNumber"></b-form-input>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label-for="notification-date">
            <template #label>
              <span class="text-danger">*</span>
              Notification:
            </template>
            <ValidationProvider v-slot="{ errors }" name="Notification Date" rules="required|date">
              <DateTimePicker id="notification-date" v-model="internalCollection.notification"></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label-for="appointment-date">
            <template #label>
              <span v-if="collectionType != 2" class="text-danger">*</span>
              Appointment:
            </template>
            <ValidationProvider v-slot="{ errors }" name="Appointment Date" rules="required|date|greaterOrEqual:@Notification Date,Notification Date">
              <DateTimePicker id="appointment-date" v-model="internalCollection.appointment" :disabled="isGameOfficial"></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label-for="collection-date">
            <template #label>
              <span class="text-danger">*</span>
              Collection:
            </template>
            <ValidationProvider v-slot="{ errors }" name="Collection Date" rules="required|date|greater:@Notification Date,Notification Date">
              <DateTimePicker id="collection-date" v-model="internalCollection.collection" @input="collectionDateChanged"></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-form-select
              v-model="internalCollection.testTypeKey"
              :options="testTypes"
              value-field="testTypeKey"
              text-field="testTypeName"
              class="search-select"></b-form-select>
          </b-form-group>
          <b-form-group label="Comments" label-for="comment">
            <b-form-textarea id="comment" v-model="internalCollection.comment"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-if="!isGameOfficial" id="isOnSiteDilute" v-model="internalCollection.isOnSiteDilute">On-site Dilute</b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="!isGameOfficial" label="Re-Test Appointment" label-for="reTestAppointment">
            <ValidationProvider v-slot="{ errors }" name="Re-Test Appointment Date" rules="date">
              <DateTimePicker id="reTestAppointment-date" v-model="internalCollection.reTestAppointment" show-clear></DateTimePicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="flex-row nm-bottom">
            <b-form-checkbox v-if="!isGameOfficial" id="diluteReTest" v-model="internalCollection.isDiluteReTest">Dilute Re-Test</b-form-checkbox>
            <b-form-checkbox v-if="!isGameOfficial" id="missedReTest" v-model="internalCollection.isMissedReTest">Missed Re-Test</b-form-checkbox>
          </b-form-group>
        </div>

        <div>
          <b-form-group v-if="!isGameOfficial" label="Dilute COC #:" label-for="diluteCOCNumber">
            <b-form-input id="diluteCOCNumber" v-model="internalCollection.diluteCOCNumber"></b-form-input>
          </b-form-group>
          <b-form-group label="Documents (COC form only in PDF)">
            <div class="upload-document-section">
              <!-- <input type="button" value="Select File"  /> -->
              <input v-show="false" ref="fileRef" type="file" accept=".pdf" @change="fileSelected" />
            </div>
            <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <div class="dropzone-table-container">
                <table
                  class="table"
                  v-if="
                    (internalCollection.specimenCollectionDocuments && internalCollection.specimenCollectionDocuments.length > 0) ||
                    queuedFileUpload.length > 0
                  ">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                </table>
                <b-table
                  v-if="internalCollection.specimenCollectionDocuments && internalCollection.specimenCollectionDocuments.length > 0"
                  striped
                  hover
                  small
                  foot-clone
                  :items="existingUploadedDocuments"
                  :fields="documentFields"
                  :sort-desc.sync="sortDesc"
                  :sort-by.sync="sortBy"
                  thead-class="d-none">
                  <template #head()="{ label, field }">
                    <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
                  </template>
                  <template #cell(documentType)="data">
                    <span v-b-tooltip.hover :title="data.item.documentType">
                      <font-awesome-icon :icon="fileMatch(data.item.documentType)" size="xl" />
                    </span>
                  </template>
                  <template #cell(documentName)="data">
                    <a :href="`/api/Document/GetSpecimenCollectionDocument/${data.item.specimenCollectionDocumentKey}`" target="_blank">
                      {{ data.item.documentName }}
                    </a>
                  </template>
                  <template #cell(deleteButton)="data">
                    <span class="table-column-buttons icon actionIcon">
                      <font-awesome-icon
                        icon="trash"
                        size="lg"
                        class="fake-link"
                        @click="deleteSpecimenCollectionDocument(data.item.specimenCollectionDocumentKey)" />
                    </span>
                  </template>
                </b-table>
                <b-table
                  v-if="queuedFileUpload.length > 0"
                  striped
                  hover
                  small
                  foot-clone
                  :items="queuedFileUpload"
                  :fields="fileUploadDocumentFields"
                  :sort-desc.sync="sortDesc"
                  :sort-by.sync="sortBy"
                  thead-class="d-none">
                  <template #cell(documentType)="data">
                    <span v-b-tooltip.hover :title="data.item.type">
                      <font-awesome-icon :icon="fileMatch(data.item.type)" size="xl" />
                    </span>
                  </template>
                  <template #cell(documentName)="data">{{ data.item.name }}</template>
                  <template #cell(deleteButton)="data">
                    <span v-if="!(data.item.event && data.item.event.cannotDeleteEvent)" class="table-column-buttons icon actionIcon">
                      <font-awesome-icon icon="trash" size="lg" class="fake-link" @click="removeDocumentToUpload(data.item)" />
                    </span>
                  </template>
                </b-table>
              </div>
              <label for="fileInput" class="file-label" :class="{ disabled: selectFileDisabled }" @click="$refs.fileRef.click()">
                <div v-if="isDragging">Release to add file</div>
                <div v-else>
                  <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
                  Click or Drop files here
                </div>
              </label>
            </div>
          </b-form-group>
        </div>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <div class="d-modal-buttons">
            <b-button variant="primary" :disabled="saveInProgress" @click="saveCollection(invalid)">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
            <b-button variant="secondary" @click="$modal.hide('collection-entry-modal')">Close</b-button>
          </div>
        </div>
      </template>
    </DraggableModal>
  </ValidationObserver>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    collection: { type: Object, required: false, default: null },
    collectionType: { type: Number, required: true, default: -1 }
  },
  data() {
    return {
      internalCollection: null,
      testTypes: [],
      documentFields: [
        { key: 'documentType', label: '', class: 'actionIcon' },
        { key: 'documentName', label: 'File Name' },
        { key: 'deleteButton', label: 'Delete', class: 'rightAlign' }
      ],
      fileUploadDocumentFields: [
        { key: 'documentType', label: '', class: 'actionIcon' },
        { key: 'documentName', label: 'File Name' },
        { key: 'deleteButton', label: 'Delete', class: 'rightAlign' }
      ],
      isDragging: false,
      queuedFileUpload: [],
      queuedFileDeletion: [],
      errorInfo: null,
      sortBy: null,
      sortDesc: true,
      saveInProgress: false
    };
  },
  computed: {
    editExistingCollection() {
      return !!(this.collection && this.collection.specimenCollectionKey);
    },
    modalTitle() {
      if (this.editExistingCollection) return 'Edit Collection';
      else return 'Add New Collection';
    },
    isGameOfficial() {
      return this.collectionType == 2;
    },
    hasEdits() {
      return !_.isEqual(this.collection, this.internalCollection) || this.queuedFileUpload.length > 0 || this.queuedFileDeletion.length > 0;
    },
    existingUploadedDocuments() {
      if (this.internalCollection.specimenCollectionDocuments == null) return [];
      return this.internalCollection.specimenCollectionDocuments.filter(
        (doc) => this.queuedFileDeletion.indexOf(doc.specimenCollectionDocumentKey) == -1
      );
    },
    selectFileDisabled() {
      return this.existingUploadedDocuments.length > 0 || this.queuedFileUpload.length > 0;
    }
  },
  watch: {
    collection() {
      this.initializeValues();
    }
  },
  async created() {
    await this.getTestTypes();
  },
  methods: {
    initializeValues() {
      this.errorInfo = null;
      this.queuedFileUpload = [];
      this.queuedFileDeletion = [];
      if (this.editExistingCollection) {
        this.internalCollection = _.cloneDeep(this.collection);
      } else {
        this.internalCollection = {
          specimenCollectionKey: null,
          subjectKey: this.collection.subjectKey,
          cocNumber: null,
          notification: null,
          appointment: null,
          collection: null,
          statusTypeID: null,
          testTypeKey: this.collectionType == 1 ? 27 : 9,
          comment: null,
          isOnSiteDilute: false,
          reTestAppointment: null,
          isMissedReTest: false,
          isDiluteReTest: false,
          diluteCOCNumber: null,
          isLowGravity: false,
          testTypeName: '',
          enteredBy: null,
          status: '',
          enteredByDPA: '',
          specimenCollectionDocuments: []
        };
      }
    },
    async getTestTypes() {
      const response = await axios.get('api/Collections/GetTestTypes');
      this.testTypes = response.data;
    },
    async saveCollection(invalid) {
      if (invalid) {
        this.$refs.collectionEntryValidationRef.validate();
        return;
      }
      switch (this.collectionType) {
        case 0:
          await this.saveProgramPlayerCollection();
          break;
        case 1:
          await this.saveAnnualTestCollection();
          break;
        case 2:
          await this.saveGameOfficialCollection();
          break;
        default:
          break;
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.fileRef.files = e.dataTransfer.files;
      this.fileSelected();
      this.isDragging = false;
    },
    fileSelected() {
      if (this.selectFileDisabled) {
        this.errorInfo = 'Only single file can be uploaded for collection';
        this.$refs.fileRef.value = null;
        return;
      }

      var uploadedFiles = this.$refs.fileRef.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        if (!uploadedFiles[i].name.endsWith('.pdf')) {
          this.$refs.fileRef.value = null;
          this.errorInfo = 'Only PDF document upload allowed';
          return;
        }
      }

      for (var j = 0; j < uploadedFiles.length; j++) {
        this.queuedFileUpload.push(uploadedFiles[j]);
      }

      this.errorInfo = null;
    },
    async uploadFile() {
      for (var i = 0; i < this.queuedFileUpload.length; i++) {
        const formData = new FormData();
        formData.append('file', this.queuedFileUpload[i]);
        const headers = { 'Content-Type': 'multipart/form-data' };
        const url = `/api/Document/UploadSpecimenCollectionDocument/${this.internalCollection.specimenCollectionKey}`;
        await axios.post(url, formData, { headers });
      }
    },
    async deleteSpecimenCollectionDocument(specimenCollectionDocumentKey) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this document?', {
        title: 'Delete Document',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        this.queuedFileDeletion.push(specimenCollectionDocumentKey);
        this.errorInfo = null;
      }
    },
    async removeDocumentToUpload(item) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this document?', {
        title: 'Delete Document',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });
      if (confirmation) {
        let idx = this.queuedFileUpload.indexOf(item);
        this.queuedFileUpload.splice(idx, 1);
        this.errorInfo = null;
      }
    },
    async deleteCaseEventDocumentInternal() {
      await axios.post(`/api/Document/DeleteSpecimenCollectionDocument/${this.queuedFileDeletion}`);
    },
    async UploadDeleteDocuments() {
      if (this.queuedFileUpload && this.queuedFileUpload.length > 0) await this.uploadFile();
      if (this.queuedFileDeletion && this.queuedFileDeletion.length > 0) await this.deleteCaseEventDocumentInternal();
    },
    async saveProgramPlayerCollection() {
      try {
        this.saveInProgress = true;
        var response = await axios.post('/api/Collections/AddEditProgramPlayerCollection', this.internalCollection);
        if (response.data > 0) {
          if (this.internalCollection.specimenCollectionKey == null) this.internalCollection.specimenCollectionKey = response.data;
          await this.UploadDeleteDocuments();
          this.makeToast('Program Player Collection Saved.');
          this.$emit('collectionEntrySaved');

          this.$refs.collectionEntryModalRef.hide('confirmed');
        }
      } catch (error) {
        this.errorInfo = error.response.data;
      } finally {
        this.saveInProgress = false;
      }
    },
    async saveAnnualTestCollection() {
      try {
        this.saveInProgress = true;
        var response = await axios.post('/api/Collections/AddEditAnnualTestCollection', this.internalCollection);
        if (response.data > 0) {
          if (this.internalCollection.specimenCollectionKey == null) this.internalCollection.specimenCollectionKey = response.data;
          await this.UploadDeleteDocuments();
          this.makeToast('Annual Collection Saved.');
          this.$emit('collectionEntrySaved', this.internalCollection.specimenCollectionKey == null);
          this.$refs.collectionEntryModalRef.hide('confirmed');
        }
      } catch (error) {
        this.errorInfo = error.response.data;
      } finally {
        this.saveInProgress = false;
      }
    },
    async saveGameOfficialCollection() {
      try {
        this.saveInProgress = true;
        var response = await axios.post('/api/Collections/AddEditGameOfficialCollection', this.internalCollection);
        if (response.data > 0) {
          if (this.internalCollection.specimenCollectionKey == null) this.internalCollection.specimenCollectionKey = response.data;
          await this.UploadDeleteDocuments();
          this.makeToast('Game Official Collection Saved.');
          this.$emit('collectionEntrySaved');
          this.$refs.collectionEntryModalRef.hide('confirmed');
        }
      } catch (error) {
        this.errorInfo = error.response.data;
      } finally {
        this.saveInProgress = false;
      }
    },
    collectionDateChanged() {
      if (this.collectionType !== 2) return;
      this.internalCollection.appointment = this.internalCollection.collection;
    },
    fileMatch(file) {
      let fileImage = '';
      if (file.indexOf('pdf') > -1) {
        fileImage = '-pdf';
      } else if (file.indexOf('image') > -1) {
        fileImage = '-image';
      } else if (file.indexOf('text') > -1) {
        fileImage = '-lines';
      } else {
        fileImage = '';
      }
      return 'fa-regular fa-file' + fileImage;
    }
  }
};
</script>
<style scoped>
.required > label label::after {
  content: '*';
}
.required > label {
  font-size: 15px;
}
</style>
