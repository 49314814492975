<template>
  <div id="pageHeader" :class="environmentName">
    <b-nav-text v-if="environmentName !== 'Production'" class="environment-name-text">
      <div>
        <span class="environment-name" v-for="n in 250" v-bind:key="n">{{ environmentName + ' ' }}</span>
      </div>
    </b-nav-text>
    <b-navbar toggleable="lg">
      <b-navbar-brand :to="{ name: 'Home' }">
        <img src="@/assets/Images/nfl-logo.png" />
        <span class="fullTitle">Substance of Abuse</span>
        <span class="abbrevTitle">SoA</span>
      </b-navbar-brand>

      <div id="nav-collapse" class="navHolder" is-nav>
        <b-navbar-nav id="version-environment-info">
          <b-nav-text class="version-name">v2.2</b-nav-text>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="isAuth" class="ml-auto">
          <TopNavSearchBar></TopNavSearchBar>
          <template v-if="isDPA">
            <b-nav-item :to="cListRoute" class="DPANav">
              <span>Assigned Collections</span>
              <span aria-label="C-List" data-balloon-pos="down"><font-awesome-icon icon="list" size="lg" class="sidebar-icon" /></span>
            </b-nav-item>
            <b-nav-item :to="collectionEntryRoute" class="DPANav">
              <span>Collections</span>
              <span aria-label="Collections" data-balloon-pos="down"><font-awesome-icon icon="bottle-droplet" class="sidebar-icon" /></span>
            </b-nav-item>
            <b-nav-item :to="dpaSubjectDetailsRoute" class="DPANav">
              <span>Subjects</span>
              <span aria-label="Subjects" data-balloon-pos="down"><font-awesome-icon icon="users" size="lg" class="sidebar-icon" /></span>
            </b-nav-item>
          </template>
          <div class="user-buttons" :class="{ open: showUserMenu }" @click="toggleUserMenu">
            <div>{{ userInfo.initials }}</div>
            <font-awesome-icon icon="caret-down" />
          </div>
        </b-navbar-nav>
      </div>
    </b-navbar>
  </div>
</template>
<script>
import TopNavSearchBar from '@/components/layout/TopNavBarSearch';

export default {
  components: {
    TopNavSearchBar
  }
};
</script>
<style scoped>
.user-buttons {
  margin-left: 10px;
}

#version-environment-info .version-name {
  color: white;
}

#version-environment-info .environment-name-text {
  margin-left: 20px;
  color: white;
  background-color: green;
  padding: 10px;
}

#version-environment-info .environment-name-text .environment-name {
  background-color: red;
}
</style>
