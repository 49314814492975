<template>
  <div class="date-time-picker">
    <b-input-group class="date-picker-group">
      <b-input-group-append aria-label="Open Datepicker" data-balloon-pos="up">
        <b-form-datepicker v-model="dateValue" button-only class="date-picker" :disabled="disabled" @input="changeDate"></b-form-datepicker>
      </b-input-group-append>
      <b-form-input
        v-model="dateValueMonthFirst"
        type="text"
        :disabled="disabled"
        :state="dateIsValid"
        placeholder="MM/DD/YYYY"
        autocomplete="off"
        class="date-picker"
        @change="dateEntered"></b-form-input>
    </b-input-group>
    <b-input-group v-if="!dateOnly" class="time-picker-group">
      <b-form-input
        id="time-input"
        v-model="timeValueNoSeconds"
        type="text"
        :disabled="disabled"
        :state="timeIsValid"
        placeholder="HH:mm"
        @change="timeEntered"></b-form-input>
      <b-form-timepicker
        v-model="timeValue"
        class="time-picker"
        :disabled="disabled"
        :state="timeIsValid"
        button-only
        right
        :hour12="false"
        aria-label="Open Timepicker"
        data-balloon-pos="up"
        @input="changeTimePicker"></b-form-timepicker>
      <span aria-label="Clear Fields" data-balloon-pos="up">
        <font-awesome-icon v-if="showClear && value" icon="fa-delete-left" size="xl" @click="clearValues" />
      </span>
    </b-input-group>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  props: {
    value: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    showClear: { type: Boolean, required: false, default: false },
    dateOnly: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      dateValue: null,
      dateValueMonthFirst: null,
      timeValue: null,
      timeValueNoSeconds: null
    };
  },
  computed: {
    dateIsValid() {
      if (this.disabled || (!this.dateValue && !this.dateValueMonthFirst)) {
        return null;
      }

      if (this.dateValue) {
        return DateTime.fromISO(this.dateValue).isValid;
      }

      if (this.dateValueMonthFirst) {
        return false;
      }

      return null;
    },
    timeIsValid() {
      if (this.disabled || (!this.timeValue && !this.timeValueNoSeconds)) {
        return null;
      }

      if (this.timeValue) {
        return DateTime.fromISO(this.timeValue).isValid;
      }

      if (this.timeValueNoSeconds) {
        return false;
      }

      return null;
    }
  },
  watch: {
    value() {
      this.setValues();
    }
  },
  created() {
    this.setValues();
  },
  mounted() {
    var datePickers = document.querySelectorAll('.b-form-datepicker button');
    datePickers.forEach((match) => {
      match.tabIndex = -1;
    });

    var timePickers = document.querySelectorAll('.b-form-timepicker button');
    timePickers.forEach((match) => {
      match.tabIndex = -1;
    });
  },
  methods: {
    setValues() {
      if (!this.value) {
        this.dateValue = null;
        this.timeValue = null;
        this.dateValueMonthFirst = null;
        return;
      }
      const dateTime = DateTime.fromISO(this.value);
      if (!dateTime.isValid) {
        return;
      }
      this.dateValue = dateTime.toFormat('yyyy-MM-dd');
      this.timeValue = dateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      this.dateValueMonthFirst = dateTime.toFormat('MM/dd/yyyy');
      this.timeValueNoSeconds = this.timeValue.slice(0, -3);
    },
    dateEntered() {
      // User manually typed in date
      if (!this.dateValueMonthFirst) {
        this.clearValues();
        return;
      }

      this.dateValue = null;
      this.dateValueMonthFirst = this.dateValueMonthFirst.split('.').join('/');
      this.dateValueMonthFirst = this.dateValueMonthFirst.split('-').join('/');
      const dateTime = DateTime.fromFormat(this.dateValueMonthFirst, 'M/d/yyyy');
      if (dateTime.isValid) {
        this.dateValue = dateTime.toFormat('yyyy-MM-dd');
        this.changeDate();
        if (this.dateOnly) {
          this.$emit('input', this.dateValue);
        }
      } else {
        this.$emit('input', 'Invalid Date');
      }
    },
    changeDate() {
      if (this.dateValue && this.timeValue) {
        this.$emit('input', `${this.dateValue}T${this.timeValue}`);
        return;
      }

      if (this.dateValue) {
        const dateTime = DateTime.fromISO(this.dateValue);
        if (!dateTime.isValid) {
          this.$emit('input', 'Invalid Date');
          return;
        }
        this.dateValueMonthFirst = dateTime.toFormat('MM/dd/yyyy');
        if (this.dateOnly) {
          this.$emit('input', this.dateValue);
        } else {
          this.$emit('input', 'Invalid Time');
        }
      }
    },
    timeEntered() {
      this.timeValue = null;
      var splitTime = this.timeValueNoSeconds.split(':');
      if (splitTime.length == 2) {
        if (splitTime[0].length == 1) splitTime[0] = '0' + splitTime[0];
        if (splitTime[1].length == 1) splitTime[1] = '0' + splitTime[1];
      } else {
        this.$emit('input', 'Invalid Time');
        return;
      }

      let tempTimeValue = splitTime[0] + ':' + splitTime[1] + ':00';
      if (!DateTime.fromISO(tempTimeValue).isValid) {
        this.$emit('input', 'Invalid Time');
        return;
      }

      this.timeValue = tempTimeValue;
      this.changeTimePicker();
    },
    changeTimePicker() {
      if (!this.timeValue) {
        this.$emit('input', null);
        return;
      }

      this.timeValueNoSeconds = this.timeValue.slice(0, -3);
      if (!this.dateValue) {
        this.$emit('input', null);
        return;
      }

      this.$emit('input', `${this.dateValue}T${this.timeValue}`);
    },

    clearValues() {
      this.dateValue = null;
      this.dateValueMonthFirst = null;
      this.timeValue = null;
      this.timeValueNoSeconds = null;
      this.$emit('input', null);
    }
  }
};
</script>
<style scoped>
.date-time-picker {
  display: flex;
  gap: 5px;
}
.date-picker-group {
  display: flex;
}
.time-picker-group {
  display: flex;
}
</style>
