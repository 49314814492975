<template>
  <b-badge :variant="variant" class="exterior-badge">
    <b-badge>{{ text }}</b-badge>
    {{ count }}
  </b-badge>
</template>
<script>
export default {
  props: {
    variant: { type: String, required: true },
    text: { type: String, required: true },
    count: { type: Number, required: true }
  }
};
</script>
<style scoped>
.exterior-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-size: 15px;
}
</style>
