import axios from 'axios';
import LocalStorageFunctions from '@/functions/LocalStorageFunctions';

export default {
  state: {
    showUserMenu: false,
    showCollapsingSidebar: false,
    showPinnedSidebar: localStorage.getItem('pinnedState') ? Boolean(localStorage.getItem('pinnedState') == 'true') : false,
    readyToCloseOnBlur: false,
    subjectsInProgram: null,
    eventLookups: null,
    eventLookupDictionary: null,
    arrivedAtSubjectDetailsFromRoute: null,
    currentSubjectsRoute: null,
    currentDPASubjectDetailsRoute: null,
    currentLabResultsRoute: null,
    currentCollectionSearchRoute: null,
    currentCollectionEntryRoute: null,
    currentCListRoute: null,
    routesInitialized: false,
    defaultSelectOptions: [
      { text: 'Any', value: null },
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    cocNumberSplitRegex: /[\s,]+/,
    tableSize: 'md',
    toastCount: 0,
    selectedTestResultKey: null
  },
  getters: {
    subjectsInProgram: (state) => state.subjectsInProgram,
    arrivedAtSubjectDetailsFromRoute: (state) => state.arrivedAtSubjectDetailsFromRoute,
    subjectsRoute: (state) => state.currentSubjectsRoute ?? { name: 'Subjects' },
    dpaSubjectDetailsRoute: (state) => state.currentDPASubjectDetailsRoute ?? { name: 'SubjectDetails' },
    labResultsRoute: (state) => state.currentLabResultsRoute ?? { name: 'LabResults' },
    collectionSearchRoute: (state) => state.currentCollectionSearchRoute ?? { name: 'CollectionSearch' },
    collectionEntryRoute: (state) => state.currentCollectionEntryRoute ?? { name: 'CollectionEntry' },
    cListRoute: (state) => state.currentCListRoute ?? { name: 'CList' },
    defaultSelectOptions: (state) => state.defaultSelectOptions,
    cocNumberSplitRegex: (state) => state.cocNumberSplitRegex,
    tableSize: (state) => state.tableSize
  },
  actions: {
    toggleUserMenu(context) {
      context.commit('setShowUserMenu', !context.state.showUserMenu);
      context.commit('setReadyToCloseOnBlur', false);
      setTimeout(() => {
        context.commit('setReadyToCloseOnBlur', true);
      }, 10);
    },
    closeUserMenu(context) {
      if (context.state.readyToCloseOnBlur) {
        context.commit('setShowUserMenu', false);
      }
    },
    toggleCollapsingSidebar(context) {
      context.commit('setShowCollapsingSidebar', !context.state.showCollapsingSidebar);
      context.commit('setReadyToCloseOnBlur', false);
      setTimeout(() => {
        context.commit('setReadyToCloseOnBlur', true);
      }, 10);
    },
    togglePinnedSidebar(context) {
      context.commit('setPinnedSidebar', !context.state.showPinnedSidebar);
    },
    closeCollapsingSidebar(context) {
      if (context.state.readyToCloseOnBlur) {
        context.commit('setShowCollapsingSidebar', false);
      }
    },
    async getSubjectsInProgram(context) {
      let queryURL = '/api/Subject/GetSubjects';
      let queryObject = { isInProgram: true };
      const response = await axios.get(queryURL, {
        params: queryObject
      });
      context.commit('setSubjectsInProgram', response.data);
    },
    async getEventLookups(context) {
      const response = await axios.get('/api/Subject/GetEventLookups');
      context.commit('setEventLookups', response.data);
    },
    getStoredRoutes(context) {
      const storedRoutes = LocalStorageFunctions.getStoredRouteState();
      context.commit('setAllRoutes', storedRoutes);
    },
    storeRouteState(context) {
      if (!context.state.routesInitialized) {
        return;
      }
      context.commit('storeRouteState');
    },
    clearRouteState(context) {
      context.commit('clearRouteState');
    },
    getStoredTableSize(context) {
      const tableSize = LocalStorageFunctions.getStoredTableSize();
      if (tableSize) {
        context.commit('setTableSize', tableSize);
      }
    },
    setTableSize(context, newTableSize) {
      context.commit('setTableSize', newTableSize);
    },
    incrementToastCount(context) {
      context.commit('incrementToastCount');
    }
  },
  mutations: {
    setShowUserMenu(state, showUserMenu) {
      state.showUserMenu = showUserMenu;
    },
    setShowCollapsingSidebar(state, showCollapsingSidebar) {
      state.showCollapsingSidebar = showCollapsingSidebar;
    },
    setPinnedSidebar(state, showPinnedSidebar) {
      state.showPinnedSidebar = showPinnedSidebar;
      localStorage.setItem('pinnedState', showPinnedSidebar);
    },
    setReadyToCloseOnBlur(state, readyToCloseOnBlur) {
      state.readyToCloseOnBlur = readyToCloseOnBlur;
    },
    setSubjectsInProgram(state, subjectsInProgram) {
      state.subjectsInProgram = subjectsInProgram;
    },
    setEventLookups(state, eventLookups) {
      state.eventLookupDictionary = Object.assign({}, ...eventLookups.map((x) => ({ [x.eventLookupID]: x })));
      state.eventLookups = eventLookups;
    },
    setAllRoutes(state, routes) {
      state.arrivedAtSubjectDetailsFromRoute = routes.arrivedAtSubjectDetailsFromRoute;
      state.currentSubjectsRoute = routes.currentSubjectsRoute;
      state.currentDPASubjectDetailsRoute = routes.currentDPASubjectDetailsRoute;
      state.currentLabResultsRoute = routes.currentLabResultsRoute;
      state.currentCollectionSearchRoute = routes.currentCollectionSearchRoute;
      state.currentCollectionEntryRoute = routes.currentCollectionEntryRoute;
      state.currentCListRoute = routes.currentCListRoute;
      state.routesInitialized = true;
    },
    storeRouteState(state) {
      LocalStorageFunctions.storeRouteState(state);
    },
    setArrivedAtSubjectDetailsFromRoute(state, arrivedAtSubjectDetailsFromRoute) {
      state.arrivedAtSubjectDetailsFromRoute = arrivedAtSubjectDetailsFromRoute;
    },
    setCurrentRoute(state, currentRoute) {
      switch (currentRoute.name) {
        case 'Subjects':
          state.currentSubjectsRoute = currentRoute;
          break;
        case 'SubjectDetails':
          state.currentDPASubjectDetailsRoute = currentRoute;
          break;
        case 'LabResults':
          state.currentLabResultsRoute = currentRoute;
          break;
        case 'CollectionSearch':
          state.currentCollectionSearchRoute = currentRoute;
          break;
        case 'CollectionEntry':
          state.currentCollectionEntryRoute = currentRoute;
          break;
        case 'CList':
          state.currentCListRoute = currentRoute;
          break;
      }
    },
    clearRouteState(state) {
      state.arrivedAtSubjectDetailsFromRoute = null;
      state.currentSubjectsRoute = null;
      state.currentDPASubjectDetailsRoute = null;
      state.currentLabResultsRoute = null;
      state.currentCollectionSearchRoute = null;
      state.currentCollectionEntryRoute = null;
      state.currentCListRoute = null;
      state.routesInitialized = true;
      LocalStorageFunctions.clearRouteState();
    },
    setTableSize(state, newTableSize) {
      state.tableSize = newTableSize;
      LocalStorageFunctions.storeTableSize(state.tableSize);
    },
    incrementToastCount(state) {
      state.toastCount++;
    },
    setSelectedTestResultKey(state, selectedTestResultKey) {
      state.selectedTestResultKey = selectedTestResultKey;
    }
  }
};
