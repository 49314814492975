<template>
  <div>
    <div v-if="!allowFakeLogin">
      <h1>Log In</h1>
      <b-button variant="primary" href="/Login/Login">Log in</b-button>
    </div>
    <div v-if="allowFakeLogin">
      <h1>Log In</h1>
      <h2>Okta Login</h2>
      <b-button variant="primary" href="/Login/Login">Log in with Okta</b-button>

      <hr />

      <div>
        <h2>Fake Login</h2>
        <b-form-group label="User" label-for="fake-login-user-select">
          <b-form-select
            id="fake-login-user-select"
            v-model="selectedEmailAddress"
            :options="testUsers"
            value-field="email"
            text-field="userName"></b-form-select>
          <input :value="selectedEmailAddress" style="width: 0px !important; padding: 0px; border: none" />
        </b-form-group>
        <b-form-group label="Password" label-for="fake-login-password">
          <b-form-input id="fake-login-password" v-model="fakeLoginPassword" type="password"></b-form-input>
        </b-form-group>
        <b-alert :show="!!fakeLoginError" variant="danger" dismissible>{{ fakeLoginError }}</b-alert>
        <b-button variant="primary" @click="login">Fake Log In</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    location: { type: Object, required: false, default: null }
  },
  data() {
    return {
      testUsers: [],
      selectedEmailAddress: null,
      fakeLoginPassword: null,
      fakeLoginError: null
    };
  },
  async created() {
    await this.getTestUsers();
  },
  methods: {
    async getTestUsers() {
      const response = await axios.get('/api/Account/GetTestUsers');
      this.testUsers = response.data;
    },
    async login() {
      let params = {
        selectedEmailAddress: this.selectedEmailAddress,
        password: this.fakeLoginPassword
      };

      try {
        await axios.post('/api/Account/FakeLogin', params);
      } catch (error) {
        this.fakeLoginError = error.response.data;
        return;
      }

      await this.initializeBasicData();

      if (this.isSA) {
        this.$router.push({ name: 'Home' });
        return;
      }

      if (this.isDPA) {
        this.$router.push({ name: 'CList' });
        return;
      }

      this.$router.push({ name: 'NotFound' });
    }
  }
};
</script>
