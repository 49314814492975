import Vue from 'vue';
import { DateTime } from 'luxon';

Vue.filter('yesNo', function (value) {
  if (value) {
    return 'Yes';
  }
  return 'No';
});

Vue.filter('date', function (value) {
  if (value) {
    const date = DateTime.fromISO(value);
    const humanReadable = date.toLocaleString(DateTime.DATE);
    return humanReadable;
  }
  return '';
});

Vue.filter('time', function (value) {
  if (value) {
    const date = DateTime.fromISO(value);
    const humanReadable = date.toLocaleString(DateTime.TIME_24_SIMPLE);
    return humanReadable;
  }
  return '';
});

Vue.filter('dateTime', function (value) {
  if (value) {
    const date = DateTime.fromISO(value);
    const humanReadableDate = date.toLocaleString(DateTime.DATE);
    const humanReadableTime = date.toLocaleString(DateTime.TIME_24_SIMPLE);
    return `${humanReadableDate}, ${humanReadableTime}`;
  }
  return '';
});
