<template>
  <div>
    <div class="specimens-controls">
      <b-form-select v-model="selectedCaseKey" @change="getSpecimenCollections">
        <b-form-select-option v-for="caseOption in subject.cases" :key="caseOption.caseKey" :value="caseOption.caseKey">
          {{ caseOption.case }}
        </b-form-select-option>
        <b-form-select-option :value="null">Specimens not in a case</b-form-select-option>
      </b-form-select>
    </div>
    <div class="subjectsTableContainer">
      <b-table
        v-if="specimenCollections.length > 0"
        striped
        hover
        small
        foot-clone
        :items="specimenCollections"
        :fields="specimenFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(specimen.cocNumber)="data">
          <a v-d-modal.lab-result-modal class="fake-link" @click="selectTestResult(data.item.testResult.testResultKey)">
            {{ data.item.specimen.cocNumber }}
          </a>
        </template>
        <template #cell(specimen.collection)="data">
          <div v-if="data.item.specimen.collection">{{ data.item.specimen.collection | date }},</div>
          <div>{{ data.item.specimen.collection | time }}</div>
        </template>
        <template #cell(specimen.appointment)="data">
          <div v-if="data.item.specimen.appointment">{{ data.item.specimen.appointment | date }},</div>
          <div>{{ data.item.specimen.appointment | time }}</div>
        </template>
        <template #cell(specimen.notification)="data">
          <div v-if="data.item.specimen.notification">{{ data.item.specimen.notification | date }},</div>
          <div>{{ data.item.specimen.notification | time }}</div>
        </template>
        <template #cell(testResult.bbottleTested)="data">
          <font-awesome-icon v-if="data.item.testResult.bbottleTested" icon="circle-check" size="lg" />
        </template>
        <template #cell(testResult.diluteSpecimen)="data">
          <font-awesome-icon v-if="data.item.testResult.diluteSpecimen" icon="circle-check" size="lg" />
        </template>
        <template #cell(testResult.isPositive)="data">
          <font-awesome-icon v-if="data.item.testResult.isPositive" icon="circle-check" size="lg" />
          <span v-if="data.item.testResult.medicallyJustifiable" class="MJ" v-b-tooltip.hover :title="'Medically Justifiable'">
            <MedicallyJustifiableIcon no-click></MedicallyJustifiableIcon>
          </span>
        </template>
        <template #cell(addToCase)="data">
          <span
            v-d-modal.add-specimen-to-case-modal
            aria-label="Add to Case"
            data-balloon-pos="left"
            class="icon actionIcon"
            @click="selectSpecimen(data.item.specimen)">
            <font-awesome-icon icon="fa-clipboard-question" size="lg" />
          </span>
        </template>
      </b-table>
      <b-alert v-if="!isBusy && specimenCollections.length === 0" variant="info" show>No specimen collections found.</b-alert>
    </div>
    <AddSpecimenToCaseModal :specimen="selectedSpecimen" @specimenAddedToCase="getSpecimenCollections"></AddSpecimenToCaseModal>
  </div>
</template>

<script>
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';
import AddSpecimenToCaseModal from '@/components/modals/AddSpecimenToCaseModal';
import MedicallyJustifiableIcon from '@/components/icons/MedicallyJustifiableIcon';

export default {
  components: { AddSpecimenToCaseModal, MedicallyJustifiableIcon },
  mixins: [SubjectDetailsMixin],
  data() {
    return {
      selectedCaseKey: null,
      selectedSpecimen: null,
      specimenCollections: [],
      baseSpecimenFields: [
        { key: 'specimen.cocNumber', label: 'COC #', sortable: true },
        { key: 'specimen.collection', label: 'Collection', sortable: true, class: 'dateTime' },
        { key: 'specimen.appointment', label: 'Appointment', sortable: true, class: 'dateTime' },
        { key: 'specimen.notification', label: 'Notification', sortable: true, class: 'dateTime' },
        { key: 'testResult.bbottleTested', label: 'B Bottle', sortable: true, class: 'centerAlign' },
        { key: 'testResult.isPositive', label: 'Positive', sortable: true, class: 'centerAlign' },
        { key: 'testResult.diluteSpecimen', label: 'Dilute', sortable: true, class: 'centerAlign' },
        { key: 'specimen.status', label: 'Status', sortable: true }
      ],
      nonCaseSpecimenFields: [{ key: 'addToCase', label: '' }],
      sortBy: null,
      sortDesc: true,
      isBusy: true
    };
  },
  computed: {
    specimenFields() {
      if (this.selectedCaseKey) {
        return this.baseSpecimenFields;
      }

      return this.baseSpecimenFields.concat(this.nonCaseSpecimenFields);
    }
  },
  async created() {
    if (this.subject.cases.length > 0) {
      this.selectedCaseKey = this.subject.cases[0].caseKey;
    }

    await this.getSpecimenCollections();
  },
  methods: {
    async getSpecimenCollections() {
      this.isBusy = true;
      let queryURL = '/api/Subject/GetSpecimenCollections';
      let queryObject = {
        subjectKey: this.subject.subjectKey,
        caseKey: this.selectedCaseKey
      };

      const response = await axios.get(queryURL, {
        params: queryObject
      });

      this.specimenCollections = response.data;
      this.isBusy = false;
    },
    selectSpecimen(selectedSpecimen) {
      this.selectedSpecimen = selectedSpecimen;
    }
  }
};
</script>
