<template>
  <div class="headerRow" :class="[{ collapse: collapse }, 'team' + subject.currentClub]">
    <div class="subContainer">
      <h2>{{ subject.name }}</h2>
      <h3 v-if="subject.subjectContactInfo">
        <span>{{ subject.subjectContactInfo.streetAddress1 }}</span>
        <span v-if="subject.subjectContactInfo.streetAddress2">, {{ subject.subjectContactInfo.streetAddress2 }}</span>
      </h3>
      <h3 v-if="subject.subjectContactInfo" class="addrRow2">
        {{ subject.subjectContactInfo.city }},
        {{ subject.subjectContactInfo.state }}
        {{ subject.subjectContactInfo.zip }}
      </h3>
      <h3 v-if="subject.subjectContactInfo">
        <div v-if="subject.subjectContactInfo.userName" class="click lombardo" aria-label="Lombardo Username" data-balloon-pos="up">
          <span class="title label-icon">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -10 179.22 189.22"
              class="svg-inline--fa fa-lg"
              style="fill: #212529">
              <path
                d="M145.34,0H23.88A23.88,23.88,0,0,0,0,23.88V145.34a23.88,23.88,0,0,0,23.88,23.87H145.34a23.88,23.88,0,0,0,23.88-23.87V23.88A23.88,23.88,0,0,0,145.34,0ZM84.61,36A27.34,27.34,0,1,1,57.27,63.3,27.34,27.34,0,0,1,84.61,36Zm53.68,109H29.89a36.26,36.26,0,0,1,36.27-36.26H102A36.26,36.26,0,0,1,138.29,145Z" />
            </svg>
          </span>
          <span>{{ subject.subjectContactInfo.userName }}</span>
        </div>
        <div v-if="subject.subjectContactInfo.number" class="click" @click="send(`tel:${subject.subjectContactInfo.number}`)">
          <span class="title label-icon">
            <font-awesome-icon icon="square-phone-flip" size="lg" />
          </span>
          <span>{{ subject.subjectContactInfo.number }}</span>
        </div>
        <div v-if="subject.subjectContactInfo.email" class="click" @click="send(`mailTo:${subject.subjectContactInfo.email}`)">
          <span class="title label-icon">
            <font-awesome-icon icon="square-envelope" size="lg" />
          </span>
          {{ subject.subjectContactInfo.email }}
        </div>
      </h3>
    </div>
    <div class="profileFields">
      <table>
        <tr>
          <td>Club :</td>
          <td>{{ subject.currentClub }} - {{ subject.position }}</td>
          <td>Stage :</td>
          <td>{{ subject.stageName }}</td>
        </tr>
        <tr>
          <td>Status :</td>
          <td>{{ subject.playerCurrentStatus }}</td>
          <td>
            <span class="hideMobile">Program&nbsp;</span>
            <span class="hideDesktop">Prog&nbsp;</span>
            Status :
          </td>
          <td>{{ subject.statusInProgramName }}</td>
        </tr>
        <tr>
          <td>DOB :</td>
          <td>{{ subject.dateOfBirth | date }}</td>
          <td>Subject Key :</td>
          <td>{{ subject.subjectKey }}</td>
        </tr>
        <tr>
          <td>College :</td>
          <td>{{ subject.college }}</td>
          <td>Foreign ID :</td>
          <td>{{ subject.foreignID }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    collapse: { type: Boolean, required: true }
  },
  methods: {
    send(value) {
      window.open(value);
      return;
    }
  }
};
</script>
