import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faSpinner,
  faVials,
  faPersonCircleQuestion,
  faUsers,
  faList,
  faBottleDroplet,
  faCopy,
  faTrash,
  faCirclePlus,
  faBuilding,
  faHouse,
  faHotel,
  faQuestionCircle,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faBackwardFast,
  faForwardFast,
  faSearch,
  faSquareCheck,
  faSquarePhoneFlip,
  faSquareEnvelope,
  faEnvelope,
  faCircleCheck,
  faMagnifyingGlass,
  faEllipsis,
  faBinoculars,
  faLock,
  faLockOpen,
  faClipboardCheck,
  faClipboardQuestion,
  faFilter,
  faSquareMinus,
  faPrescriptionBottle,
  faCircleExclamation,
  faEnvelopeCircleCheck,
  faCircleXmark,
  faSort,
  faSortDown,
  faSortUp,
  faRotateLeft,
  faSquare,
  faUser,
  faXmark,
  faSquareXmark,
  faPrint,
  faDeleteLeft,
  faStar,
  faIdCard,
  faThumbTack,
  faCloudArrowUp,
  faShield,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

import {
  faSquare as regSquare,
  faCircleXmark as faRegCircleXmark,
  faCircleCheck as faRegCircleCheck,
  faIdCard as faRegIdCard,
  faFilePdf as faRegFilePdf,
  faFileImage as faRegFileImage,
  faFileLines as faRegFileLines,
  faFile as faRegFile,
  faCircle as faRegCircle
  // faFileCircleQuestion as faRegFileCircleQuestion
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faHome,
  faSpinner,
  faVials,
  faPersonCircleQuestion,
  faUsers,
  faList,
  faBottleDroplet,
  faCopy,
  faTrash,
  faCirclePlus,
  faBuilding,
  faHouse,
  faHotel,
  faQuestionCircle,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faBackwardFast,
  faForwardFast,
  faSearch,
  faSquareCheck,
  regSquare,
  faSquarePhoneFlip,
  faSquareEnvelope,
  faEnvelope,
  faCircleCheck,
  faMagnifyingGlass,
  faEllipsis,
  faBinoculars,
  faLock,
  faLockOpen,
  faClipboardCheck,
  faClipboardQuestion,
  faFilter,
  faSquareMinus,
  faPrescriptionBottle,
  faCircleExclamation,
  faEnvelopeCircleCheck,
  faCircleXmark,
  faRegCircleXmark,
  faRegCircleCheck,
  faRegIdCard,
  faSort,
  faSortDown,
  faSortUp,
  faRotateLeft,
  faSquare,
  faSquareXmark,
  faUser,
  faXmark,
  faPrint,
  faDeleteLeft,
  faStar,
  faIdCard,
  faThumbTack,
  faRegFilePdf,
  faRegFileImage,
  faCloudArrowUp,
  faShield,
  faRegFileLines,
  faRegFile,
  faRegCircle,
  faPlus,
  faMinus
  // faRegFileCircleQuestion
);

export { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText };
