<template>
  <div>
    <div v-if="collectionSubject" class="collectionsHeader">
      <div>
        <template v-if="filterObject.collectionType != 2">
          <div>
            <span>{{ collectionSubject.name }}</span>
            <span v-if="filterObject.collectionType == 0">{{ filterObject.monthName }} {{ filterObject.year }}</span>
          </div>
          <div>
            <label>Subject Key:</label>
            <span>{{ collectionSubject.subjectKey }}</span>
          </div>
          <div>
            <label>Current Status:</label>
            {{ collectionSubject.playerCurrentStatus }}
          </div>
          <div v-show="collectionSubject.dischargeDate">
            <label>Discharge Date:</label>
            {{ collectionSubject.dischargeDate | dateTime }}
          </div>
        </template>
        <template v-else>
          <span style="padding-right: 10px">{{ collectionSubject.name }}</span>
          <div>
            <label>Subject Key:</label>
            <span>{{ collectionSubject.subjectKey }}</span>
          </div>
        </template>
      </div>

      <div v-show="collectionSubject" class="collection-controls">
        <b-button v-d-modal.collection-entry-modal variant="primary" class="add-button no-print" @click="addEditCollection(null)">
          <font-awesome-icon icon="circle-plus" />
          <span>Add</span>
        </b-button>
      </div>
    </div>

    <div v-show="isBusy" class="spinner-container">
      <font-awesome-icon icon="spinner" spin size="4x" />
    </div>
    <b-alert :show="!!errorInfo" variant="danger" dismissible>{{ errorInfo }}</b-alert>
    <b-table
      v-if="!isBusy && collectionsEntries.length > 0"
      foot-clone
      :items="collectionsEntries"
      :fields="collectionEntryFields"
      :sort-desc.sync="internalSortDesc"
      :sort-by.sync="internalSortBy"
      @sort-changed="sortChanged">
      <template #head()="{ label, field }">
        <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
      </template>
      <template #cell(status)="data">
        <a v-if="isSA && data.item.statusTypeID == 4" class="fake-link" @click="unlockCollection(data.item)">
          <font-awesome-icon v-if="data.item.statusTypeID == 4" v-b-popover.hover="'Click to unlock'" icon="lock" />
        </a>
        <a v-if="isDPA && data.item.statusTypeID == 4">
          <font-awesome-icon v-if="data.item.statusTypeID == 4" v-b-popover.hover="'Matched'" icon="lock" />
        </a>
      </template>
      <template #cell(cocNumber)="data">
        <span v-if="data.item.statusTypeID === 4">{{ data.item.cocNumber }}</span>
        <a
          v-if="data.item.statusTypeID !== 4"
          v-d-modal.collection-entry-modal
          v-b-popover.hover="'Edit collection'"
          class="fake-link"
          @click="addEditCollection(data.item)">
          {{ data.item.cocNumber }}
        </a>
      </template>
      <template #cell(notification)="data">
        <div v-if="data.item.notification">{{ data.item.notification | date }},</div>
        <div>{{ data.item.notification | time }}</div>
      </template>
      <template #cell(appointment)="data">
        <div v-if="data.item.appointment">{{ data.item.appointment | date }},</div>
        <div>{{ data.item.appointment | time }}</div>
      </template>
      <template #cell(collection)="data">
        <div v-if="data.item.collection">{{ data.item.collection | date }},</div>
        <div>{{ data.item.collection | time }}</div>
      </template>
      <template #cell(isOnSiteDilute)="data">
        <font-awesome-icon v-if="data.item.isOnSiteDilute" icon="circle-check" size="lg" />
      </template>
      <template #cell(reTestAppointment)="data">
        <div v-if="data.item.reTestAppointment && !data.item.reTestAppointment.startsWith('1900')">
          <div>{{ data.item.reTestAppointment | date }},</div>
          <div>{{ data.item.reTestAppointment | time }}</div>
        </div>
      </template>
      <template #cell(isMissedReTest)="data">
        <font-awesome-icon v-if="data.item.isMissedReTest" icon="circle-check" size="lg" />
      </template>
      <template #cell(isDiluteReTest)="data">
        <font-awesome-icon v-if="data.item.isDiluteReTest" icon="circle-check" size="lg" />
      </template>
      <template #cell(diluteCOCNumber)="data">
        {{ data.item.diluteCOCNumber }}
      </template>
      <template #cell(cocForm)="data">
        <div>
          <a
            v-if="data.item.specimenCollectionDocuments && data.item.specimenCollectionDocuments.length > 0"
            :href="`/api/Document/GetSpecimenCollectionDocument/${data.item.specimenCollectionDocuments[0].specimenCollectionDocumentKey}`"
            target="_blank">
            {{ data.item.specimenCollectionDocuments[0].documentName }}
          </a>
          <span
            v-if="data.item.specimenCollectionDocuments && data.item.specimenCollectionDocuments.length > 0"
            class="table-column-buttons icon actionIcon">
            <font-awesome-icon
              icon="xmark"
              size="sm"
              class="fake-link"
              @click="deleteSpecimenCollectionDocument(data.item.specimenCollectionDocuments[0])" />
          </span>
          <span
            v-if="!data.item.specimenCollectionDocuments || data.item.specimenCollectionDocuments.length == 0"
            aria-label="Upload COC Form"
            data-balloon-pos="left"
            class="icon actionIcon"
            @click="showFileSelection(data.item.specimenCollectionKey)">
            <font-awesome-icon icon="fa-circle-plus" size="xl" />
          </span>
        </div>
      </template>
      <template #cell(buttons)="data">
        <span v-if="data.item.statusTypeID !== 4" class="table-column-buttons icon actionIcon">
          <font-awesome-icon icon="trash" size="lg" class="fake-link" @click="showDeleteCollectionConfirmation(data.item)" />
        </span>
      </template>
    </b-table>
    <input v-show="false" ref="fileRef" type="file" accept=".pdf" @change="uploadCOCForm" />
    <CollectionEntryModal
      :collection="selectedCollection"
      :collection-type="filterObject.collectionType"
      @collectionEntrySaved="collectionEntrySaved"
      @collectionDocumentUploaded="getCollectionsByType"
      @collectionDocumentDeleted="getCollectionsByType"></CollectionEntryModal>
  </div>
</template>

<script>
import axios from 'axios';
import CollectionEntryModal from '@/components/modals/CollectionEntryModal';

export default {
  components: {
    CollectionEntryModal
  },
  props: {
    filterObject: { type: Object, required: true },
    sortBy: { type: String, required: true },
    sortDesc: { type: Boolean, required: true }
  },
  data() {
    return {
      collectionsEntries: [],
      collectionSubject: null,
      isBusy: false,
      selectedCollection: null,
      baseCollectionEntryFields: [
        { key: 'status', label: '' },
        { key: 'cocNumber', label: 'COC #', sortable: true },
        { key: 'notification', label: 'Notification', sortable: true, class: 'dateTime' },
        { key: 'appointment', label: 'Appointment', sortable: true, class: 'dateTime' },
        { key: 'collection', label: 'Collection', sortable: true, class: 'dateTime' },
        { key: 'testTypeName', label: 'Type', sortable: true, class: 'dateTime' },
        { key: 'enteredByDPA', label: 'Entered By', sortable: true },
        { key: 'comment', label: 'Comment' }
      ],
      nonGameOfficialCollectionEntryFields: [
        { key: 'isOnSiteDilute', label: 'On-site Dilute', sortable: true, class: 'centerAlign' },
        { key: 'reTestAppointment', label: 'Re-Test Appointment', sortable: true },
        { key: 'isMissedReTest', label: 'Missed Re-Test', sortable: true, class: 'centerAlign' },
        { key: 'isDiluteReTest', label: 'Dilute Re-Test', sortable: true, class: 'centerAlign' },
        { key: 'diluteCOCNumber', label: 'Dilute COC #', sortable: true }
      ],
      collectionActions: [
        { key: 'cocForm', label: 'COC Form' },
        { key: 'buttons', label: '' }
      ],
      errorInfo: null,
      internalSortBy: null,
      internalSortDesc: null,
      selectedUploadSpecimenCollectionKey: null
    };
  },
  computed: {
    collectionEntryFields() {
      if (this.filterObject.collectionType == 2) {
        return this.baseCollectionEntryFields.concat(this.collectionActions);
      } else {
        return this.baseCollectionEntryFields.concat(this.nonGameOfficialCollectionEntryFields, this.collectionActions);
      }
    }
  },
  watch: {
    async filterObject() {
      await this.getCollections();
    },
    sortBy() {
      this.setPageControlValues();
    },
    sortDesc() {
      this.setPageControlValues();
    },
    internalSortBy(newValue) {
      this.$emit('update:sortBy', newValue);
    },
    internalSortDesc(newValue) {
      this.$emit('update:sortDesc', newValue);
    }
  },
  async created() {
    this.setPageControlValues();
    await this.getCollections();
  },
  methods: {
    async getCollections() {
      await this.getSubjectInfo();
      await this.getCollectionsByType();
    },
    async getCollectionsByType() {
      this.isBusy = true;
      switch (this.filterObject.collectionType) {
        case 0:
          await this.getProgramPlayerCollections();
          break;
        case 1:
          await this.getAnnualTestCollections();
          break;
        case 2:
          await this.getGameOfficialCollections();
          break;
        default:
          break;
      }
      this.isBusy = false;
    },
    setPageControlValues() {
      this.internalSortBy = this.sortBy;
      this.internalSortDesc = this.sortDesc;
    },
    sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.internalSortBy = context.sortBy;
      this.internalSortDesc = context.sortDesc;
      this.$emit('sort-changed', context);
    },
    async getProgramPlayerCollections() {
      let queryURL = '/api/Collections/GetProgramPlayerCollections';
      let qo = { subjectKey: this.filterObject.subjectKey, year: this.filterObject.year, month: this.filterObject.month };

      const response = await axios.get(queryURL, { params: qo });

      this.collectionsEntries = response.data;
    },
    async getAnnualTestCollections() {
      let queryURL = '/api/Collections/GetAnnualTestCollections';
      let qo = { subjectKey: this.filterObject.subjectKey };

      const response = await axios.get(queryURL, { params: qo });
      this.collectionsEntries = response.data;
    },
    async getGameOfficialCollections() {
      let queryURL = '/api/Collections/GetGameOfficialCollections';
      let qo = { subjectKey: this.filterObject.subjectKey };

      const response = await axios.get(queryURL, { params: qo });
      this.collectionsEntries = response.data;
    },
    async getSubjectInfo() {
      const response = await axios.get(`/api/Subject/GetSubjectBasic?id=${this.filterObject.subjectKey}`);
      this.collectionSubject = response.data;
    },
    addEditCollection(clickedCollection) {
      if (clickedCollection === null && this.collectionSubject) {
        clickedCollection = {};
        clickedCollection.subjectKey = this.collectionSubject.subjectKey;
      }
      this.selectedCollection = clickedCollection;
    },
    collectionEntrySaved(newAnnualTestAdded) {
      this.getCollectionsByType();
      if (newAnnualTestAdded === true) this.$emit('annualTestAddedDeleted');
    },
    async unlockCollection(collectionItem) {
      try {
        await axios.post(`/api/Collections/UnlockCollection?id=${collectionItem.specimenCollectionKey}`);
        this.makeToast('Collection Unlocked.');
        this.getCollectionsByType();
      } catch (error) {
        this.errorInfo = error.response.data;
      }
    },
    async showDeleteCollectionConfirmation(collectionItem) {
      if (collectionItem.statusTypeID == 4) return;
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure you wish to delete this collection?', {
        title: 'Delete Collection',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        await axios.post('/api/Collections/DeleteCollection/' + collectionItem.specimenCollectionKey);
        this.makeToast('Collection has been deleted.');
        await this.getCollectionsByType();
        if (this.filterObject.collectionType == 1) this.$emit('annualTestAddedDeleted');
      }
    },
    showFileSelection(specimenCollectionKey) {
      this.selectedUploadSpecimenCollectionKey = specimenCollectionKey;
      this.$refs.fileRef.click();
    },
    async uploadCOCForm() {
      if (!this.$refs.fileRef.files || this.$refs.fileRef.files.length == 0) return;

      if (!this.$refs.fileRef.files[0].name.endsWith('.pdf')) {
        this.$refs.fileRef.value = null;
        this.errorInfo = 'Only PDF document upload allowed';
        return;
      }
      this.errorInfo = null;

      const formData = new FormData();
      formData.append('file', this.$refs.fileRef.files[0]);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const url = `/api/Document/UploadSpecimenCollectionDocument/${this.selectedUploadSpecimenCollectionKey}`;
      await axios.post(url, formData, { headers });

      this.getCollectionsByType();
    },
    async deleteSpecimenCollectionDocument(specimenCollectionDocument) {
      const confirmation = await this.$bvModal.msgBoxConfirm(
        'Are you sure that you want to delete ' + specimenCollectionDocument.documentName + '?',
        {
          title: 'Delete Document',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        }
      );

      if (confirmation) {
        await axios.post(`/api/Document/DeleteSpecimenCollectionDocument/${specimenCollectionDocument.specimenCollectionDocumentKey}`);
        this.$refs.fileRef.value = null;
        this.getCollectionsByType();
      }
    }
  }
};
</script>
