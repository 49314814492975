<template>
  <component :is="view" :subject-key="subjectKey"></component>
</template>

<script>
export default {
  props: {
    subjectKey: { type: Number, required: true }
  },
  computed: {
    view() {
      return this.isSA ? 'SASubjectDetails' : 'DPASubjectDetails';
    }
  }
};
</script>
