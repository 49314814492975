<template>
  <DraggableModal id="match-test-result-modal" title="Match Test Result" size="xl">
    <div v-if="unmatchedResult">
      <h3>Specimen</h3>
      <section>
        <div>
          <div class="key-value">
            <label>COC:</label>
            {{ specimen.cocNumber }}
          </div>
          <div class="key-value">
            <label>Subject:</label>
            {{ subject.name }}
          </div>

          <div class="key-value">
            <label>B Bottle Tested:</label>
            {{ specimen.bbottleTested | yesNo }}
          </div>

          <div class="key-value">
            <label>Postive Test:</label>
            {{ specimen.isPositive | yesNo }}
          </div>
        </div>

        <div>
          <div class="key-value">
            <label>Notification Date:</label>
            {{ specimen.notification | dateTime }}
          </div>
          <div class="key-value">
            <label>Appointment Date:</label>
            {{ specimen.appointment | dateTime }}
          </div>
          <div class="key-value">
            <label>Collection Date:</label>
            {{ specimen.collection | dateTime }}
          </div>
          <div class="key-value">
            <label>Test Type:</label>
            {{ specimen.testType }}
          </div>
        </div>
      </section>
      <h3>Potential Matches</h3>
      <section>
        <div v-if="!isBusy && potentialMatches.length > 0" class="modalTableContainer">
          <b-table
            striped
            hover
            small
            foot-clone
            :items="potentialMatches"
            :fields="potentialMatchesFields"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy">
            <template #head()="{ label, field }">
              <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
            </template>
            <template #cell(testResult.cocNumber)="row">
              <a v-d-modal.lab-result-modal class="fake-link" @click="row.toggleDetails">{{ row.item.testResult.cocNumber }}</a>
            </template>
            <template #cell(testResult.collectionDateTime)="data">
              {{ data.item.testResult.collectionDateTime | dateTime }}
            </template>
            <template #cell(testResult.importDate)="data">
              {{ data.item.testResult.importDate | dateTime }}
            </template>
            <template #cell(matchButton)="data">
              <span
                aria-label="Match specimen collection"
                data-balloon-pos="left"
                class="icon actionIcon"
                @click="matchTestResult(data.item.testResult)">
                <font-awesome-icon icon="fa-clipboard-check" size="lg" />
              </span>
            </template>
            <template #row-details="row">
              <LabResultDetails :test-result="row.item.testResult"></LabResultDetails>
            </template>
          </b-table>
        </div>
        <div v-show="isBusy" class="spinner-container">
          <font-awesome-icon icon="spinner" spin size="4x" />
        </div>
        <b-alert :show="!isBusy && potentialMatches && potentialMatches.length === 0" variant="info">No potential matches found.</b-alert>
      </section>
    </div>
  </DraggableModal>
</template>
<script>
import axios from 'axios';
import LabResultDetails from '@/components/sharedBusiness/LabResultDetails';

export default {
  components: {
    LabResultDetails
  },
  props: {
    unmatchedResult: { type: Object, required: false, default: null }
  },
  data() {
    return {
      isBusy: true,
      potentialMatches: null,
      potentialMatchesFields: [
        { key: 'testResult.cocNumber', label: 'COC #', sortable: true, class: 'rightAlign' },
        { key: 'testResult.collectionDateTime', label: 'Collection', sortable: true, class: 'rightAlign' },
        { key: 'testResult.importDate', label: 'Import Date', class: 'rightAlign' },
        { key: 'testResult.scientist', label: 'Scientist' },
        { key: 'matchType', label: 'Matches On' },
        { key: 'matchButton', label: 'Match' }
      ],
      sortBy: null,
      sortDesc: true
    };
  },
  computed: {
    specimen() {
      return this.unmatchedResult.specimen;
    },
    subject() {
      return this.unmatchedResult.subject;
    }
  },
  watch: {
    async unmatchedResult() {
      await this.getPotentialMatches();
    }
  },
  methods: {
    async getPotentialMatches() {
      this.isBusy = true;
      let queryURL = '/api/TestResult/GetPotentialMatchingTestResults';
      let queryObject = {
        specimenCollectionKey: this.specimen.specimenCollectionKey
      };

      const response = await axios.get(queryURL, { params: queryObject });
      this.potentialMatches = response.data;
      this.isBusy = false;
    },
    async matchTestResult(testResult) {
      const confirmMessage = 'Are you sure that you want to set match this specimen collection with this test result?';
      const confirmation = await this.$bvModal.msgBoxConfirm(confirmMessage, {
        title: 'Are You Sure?',
        okVariant: 'warning',
        okTitle: 'Yes',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        const requestObject = { specimenCollectionKey: this.specimen.specimenCollectionKey, testResultKey: testResult.testResultKey };
        await axios.post('/api/Specimen/MatchSpecimenToTestResult', requestObject);
        this.makeToast('Results have been matched.');
        this.$modal.hide('match-test-result-modal');
        this.$emit('specimenMatched');
      }
    }
  }
};
</script>
