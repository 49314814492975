<template>
  <DraggableModal id="add-specimen-to-case-modal" title="Add Specimen to Case" size="lg">
    <div v-if="specimen">
      <h3>Specimen</h3>
      <section class="specimen-summary-section">
        <div>
          <div class="key-value">
            <label>COC:</label>
            {{ specimen.cocNumber }}
          </div>
          <div class="key-value">
            <label>Subject:</label>
            {{ subject.name }}
          </div>

          <div class="key-value">
            <label>B Bottle Tested:</label>
            {{ specimen.bbottleTested | yesNo }}
          </div>

          <div class="key-value">
            <label>Postive Test:</label>
            {{ specimen.isPositive | yesNo }}
          </div>
        </div>

        <div>
          <div class="key-value">
            <label>Notification Date:</label>
            {{ specimen.notification | dateTime }}
          </div>
          <div class="key-value">
            <label>Appointment Date:</label>
            {{ specimen.appointment | dateTime }}
          </div>
          <div class="key-value">
            <label>Collection Date:</label>
            {{ specimen.collection | dateTime }}
          </div>
        </div>
      </section>
      <h3>Cases</h3>
      <section>
        <b-table striped hover small :items="subject.cases" :fields="caseFields" :sort-desc.sync="sortDesc" :sort-by.sync="sortBy" foot-clone>
          <template #head()="{ label, field }">
            <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
          </template>
          <template #cell(addButton)="data">
            <span aria-label="Add to Case" data-balloon-pos="left" class="icon actionIcon" @click="addToCase(data.item)">
              <font-awesome-icon icon="fa-clipboard-question" size="lg" />
            </span>
          </template>
        </b-table>
      </section>
    </div>
  </DraggableModal>
</template>
<script>
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    specimen: { type: Object, required: false, default: null }
  },
  data() {
    return {
      caseFields: [
        { key: 'case', label: 'Case', sortable: true },
        { key: 'addButton', label: '' }
      ],
      sortBy: null,
      sortDesc: true
    };
  },
  methods: {
    async addToCase(caseObject) {
      const confirmMessage = 'Are you sure that you want to add this specimen collection to this case?';
      const confirmation = await this.$bvModal.msgBoxConfirm(confirmMessage, {
        title: 'Are You Sure?',
        okVariant: 'warning',
        okTitle: 'Yes',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        const requestObject = { specimenCollectionKey: this.specimen.specimenCollectionKey, caseKey: caseObject.caseKey };
        await axios.post('/api/Subject/AddSpecimenToCase', requestObject);
        this.makeToast('Specimen has been added to case.');
        this.$modal.hide('add-specimen-to-case-modal');
        this.$emit('specimenAddedToCase');
      }
    }
  }
};
</script>
