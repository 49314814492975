<template>
  <div id="userMenuList" v-click-outside="closeUserMenu">
    <div class="sizeSelector">
      <span>Text Size</span>
      <ul>
        <li :class="{ active: tableSize == 'sm' }" @click="setTableSize('sm')">SM</li>
        <li :class="{ active: tableSize == 'md' }" @click="setTableSize('md')">MD</li>
        <li :class="{ active: tableSize == 'lg' }" @click="setTableSize('lg')">LG</li>
        <li :class="{ active: tableSize == 'xl' }" @click="setTableSize('xl')">XL</li>
      </ul>
    </div>
    <a v-if="isAuth" @click="logout"><span>Log Out</span></a>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  methods: {
    async logout() {
      this.$store.dispatch('clearRouteState');
      await axios.post('/api/Account/Logout');
      this.closeUserMenu();
      this.$router.push({ name: 'Login' });
    },
    setTableSize(newTableSize) {
      this.$store.dispatch('setTableSize', newTableSize);
    }
  }
};
</script>
