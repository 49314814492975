<template>
  <div id="labResultsPane">
    <div>
      <b-alert :show="!!warningMessage" variant="warning">{{ warningMessage }}</b-alert>
      <b-card header-tag="header">
        <template #header>
          <div class="search-card-header">
            <h6 class="header-text">Lab Results</h6>
            <div class="search-options no-print">
              <b-button v-d-modal.lab-result-filter-modal variant="primary">
                <font-awesome-icon icon="filter" size="lg" />
                <span>Filters</span>
              </b-button>
              <b-button variant="warning" @click="setIsBlind()">Set Blind</b-button>
              <b-button variant="warning" @click="setActedUpon()">Set Acted Upon</b-button>
              <b-button variant="warning" @click="exportCSV()">Export</b-button>
              <b-button variant="primary" class="print-button" @click="print">
                <font-awesome-icon icon="print" size="lg" />
              </b-button>
            </div>
          </div>
        </template>
        <LabResultsTable
          ref="labResultTableRef"
          :filter-object="searchedFilterObject"
          :current-page.sync="queryObject.currentPage"
          :sort-by.sync="queryObject.sortBy"
          :sort-desc.sync="queryObject.sortDesc"
          use-nav
          @sort-changed="sortChanged"
          @page-changed="pageChanged"></LabResultsTable>
      </b-card>
      <ValidationObserver v-slot="{ invalid }" ref="labResultFilterValidationRef">
        <DraggableModal id="lab-result-filter-modal" ref="labResultFilterModalRef" title="Filters" @hide="resetQueryToLastSearch" size="lg">
          <b-form>
            <div class="flex-form">
              <b-form-group label="Test Type" class="entryRow">
                <div class="horizontal-form-group">
                  <b-form-checkbox v-model="queryObject.isAnnual">Annual</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.isNormal">Normal</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.scoutingCombine">Scouting Combine</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.preEmployment">Pre-Employment</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.gameOfficial">Game Official</b-form-checkbox>
                </div>
              </b-form-group>
              <b-form-group label="Test Condition" class="entryRow">
                <div class="horizontal-form-group">
                  <b-form-checkbox v-model="queryObject.invalidResult">Invalid Result Only</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.blindTest">Blind Test</b-form-checkbox>
                  <b-form-checkbox v-model="queryObject.specimenSubstituted">Specimen Substituted Only</b-form-checkbox>
                </div>
              </b-form-group>
              <b-form-group label="Acted Upon" class="entryRow radio">
                <div class="horizontal-form-group">
                  <button class="ui button" :class="{ active: queryObject.actedUpon == true }" @click.prevent="actedUponClicked(true)">Yes</button>
                  <button class="ui button" :class="{ active: queryObject.actedUpon == false }" @click.prevent="actedUponClicked(false)">No</button>
                </div>
              </b-form-group>
              <b-form-group label="Test Result" class="entryRow radio">
                <div class="horizontal-form-group">
                  <button class="ui button" :class="{ active: queryObject.isPositive == true }" @click.prevent="testResultClicked(true)">
                    Positive
                  </button>
                  <button class="ui button" :class="{ active: queryObject.isPositive == false }" @click.prevent="testResultClicked(false)">
                    Negative
                  </button>
                </div>
              </b-form-group>
              <b-form-group label="Medically Justifiable" class="entryRow radio">
                <div class="horizontal-form-group">
                  <button
                    class="ui button"
                    :class="{ active: queryObject.medicallyJustifiable === 'None' }"
                    @click.prevent="medicallyJustifiableClicked('None')">
                    None
                  </button>
                  <button
                    v-b-tooltip.hover
                    title="Has 'Justifiable'"
                    class="ui button"
                    :class="{ active: queryObject.medicallyJustifiable === 'AtLeastOneJustifiable' }"
                    @click.prevent="medicallyJustifiableClicked('AtLeastOneJustifiable')">
                    <MedicallyJustifiableIcon></MedicallyJustifiableIcon>
                  </button>
                  <button
                    v-b-tooltip.hover
                    title="Has 'Not Justifiable'"
                    class="ui button"
                    :class="{ active: queryObject.medicallyJustifiable === 'AtLeastOneNotJustifiable' }"
                    @click.prevent="medicallyJustifiableClicked('AtLeastOneNotJustifiable')">
                    <NotMedicallyJustifiableIcon></NotMedicallyJustifiableIcon>
                  </button>
                  <button
                    class="ui button"
                    :class="{ active: queryObject.medicallyJustifiable === 'All' }"
                    @click.prevent="medicallyJustifiableClicked('All')">
                    All
                  </button>
                </div>
              </b-form-group>
              <b-form-group label="Matched" class="entryRow radio">
                <div class="horizontal-form-group">
                  <button class="ui button" :class="{ active: queryObject.matched == true }" @click.prevent="matchedClicked(true)">Matched</button>
                  <button class="ui button" :class="{ active: queryObject.matched == false }" @click.prevent="matchedClicked(false)">
                    Unmatched
                  </button>
                </div>
              </b-form-group>
            </div>
            <div class="calGroup">
              <b-form-group label="Collection Date" label-for="filter-collection-date-from">
                <ValidationProvider rules="date">
                  <DateTimePicker id="filter-collection-date-from" v-model="queryObject.collectionDateFrom" date-only size="sm"></DateTimePicker>
                </ValidationProvider>
                <span>to</span>
                <ValidationProvider rules="date">
                  <DateTimePicker id="filter-collection-date-to" v-model="queryObject.collectionDateTo" date-only size="sm"></DateTimePicker>
                </ValidationProvider>
              </b-form-group>
              <b-form-group label="Import Date" label-for="filter-import-date-from">
                <ValidationProvider rules="date">
                  <DateTimePicker id="filter-import-date-from" v-model="queryObject.importDateFrom" date-only size="sm"></DateTimePicker>
                </ValidationProvider>
                <span>to</span>
                <ValidationProvider rules="date">
                  <DateTimePicker id="filter-import-date-to" v-model="queryObject.importDateTo" date-only size="sm"></DateTimePicker>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="cocFilter">
              <b-form-group label="COC #" label-for="filter-coc-number">
                <b-button @click="addCOCNumber">
                  <font-awesome-icon icon="fa-solid fa-circle-plus" />
                  <span>Add</span>
                </b-button>
                <span v-for="(cocNumber, index) in queryObject.cocNumbers" :key="index" class="input-with-x">
                  <b-form-input id="filter-coc-number" v-model="queryObject.cocNumbers[index]"></b-form-input>
                  <b-button v-if="queryObject.cocNumbers[index] || index > 0" variant="danger" @click="removeCOCNumber(index)">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                    <font-awesome-icon icon="fa-regular fa-circle-xmark" />
                  </b-button>
                </span>
              </b-form-group>
            </div>
          </b-form>
          <template #modal-footer>
            <div class="w-100">
              <div class="d-modal-buttons">
                <div>
                  <b-link @click="clearFilters()">Clear Filters</b-link>
                  <b-link @click="defaultFilters()">Restore Defaults</b-link>
                </div>
                <b-button variant="primary" @click="getTestResultsNewSearch(invalid)">Search</b-button>
              </div>
            </div>
          </template>
        </DraggableModal>
      </ValidationObserver>
    </div>
    <LabResultModal :test-result-key="selectedTestResultKey" @substancesEdited="refreshLabResults"></LabResultModal>
  </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import LabResultsTable from '@/components/sharedBusiness/LabResultsTable';
import LabResultModal from '@/components/modals/LabResultModal';
import GlobalFunctions from '@/functions/GlobalFunctions';
import MedicallyJustifiableIcon from '@/components/icons/MedicallyJustifiableIcon';
import NotMedicallyJustifiableIcon from '@/components/icons/NotMedicallyJustifiableIcon';

export default {
  components: { LabResultsTable, LabResultModal, MedicallyJustifiableIcon, NotMedicallyJustifiableIcon },
  data() {
    return {
      isBusy: true,
      testResults: [],
      totalTestResultsCount: null,
      triggerHeight: {},
      queryObject: null,
      searchedFilterObject: null,
      defaultQuery: {
        matched: false,
        isAnnual: null,
        isNormal: true,
        scoutingCombine: null,
        preEmployment: null,
        gameOfficial: null,
        invalidResult: null,
        blindTest: false,
        specimenSubstituted: null,
        actedUpon: false,
        isPositive: null,
        medicallyJustifiable: null,
        cocNumbers: [''],
        collectionDateFrom: null,
        collectionDateTo: null,
        importDateFrom: null,
        importDateTo: null,
        currentPage: 1,
        pageSize: 50,
        sortBy: 'collectionDateTime',
        sortDesc: false,
        getAllRows: false
      },
      fieldAnnotations: {
        matched: 'Boolean',
        isAnnual: 'Boolean',
        isNormal: 'Boolean',
        scoutingCombine: 'Boolean',
        preEmployment: 'Boolean',
        gameOfficial: 'Boolean',
        invalidResult: 'Boolean',
        blindTest: 'Boolean',
        specimenSubstituted: 'Boolean',
        actedUpon: 'Boolean',
        isPositive: 'Boolean',
        cocNumber: 'Array',
        sortDesc: 'Boolean',
        currentPage: 'Number'
      },
      warningMessage: null,
      filterTokens: []
    };
  },
  watch: {
    async $route() {
      this.initializeQueryFromURL();
      this.setFilterObject();
    }
  },
  async created() {
    this.initializeQueryFromURL();
    this.setFilterObject();
  },
  methods: {
    initializeQueryFromURL() {
      this.queryObject = GlobalFunctions.convertRouteObjectToQueryObject(this.$route.query, this.defaultQuery, this.fieldAnnotations);
    },
    clearFilters() {
      this.queryObject = _.cloneDeep(this.defaultQuery);
      this.queryObject.actedUpon = null;
      this.queryObject.blindTest = false;
      this.queryObject.isNormal = null;
      this.queryObject.matched = null;
    },
    defaultFilters() {
      this.queryObject = _.cloneDeep(this.defaultQuery);
    },
    resetQueryToLastSearch(args) {
      if (args.params !== 'newSearch') {
        const pageControl = {
          currentPage: this.queryObject.currentPage,
          pageSize: this.queryObject.pageSize,
          sortBy: this.queryObject.sortBy,
          sortDesc: this.queryObject.sortDesc,
          getAllRows: this.queryObject.getAllRows
        };
        this.initializeQueryFromURL();
        this.queryObject.currentPage = pageControl.currentPage;
        this.queryObject.pageSize = pageControl.pageSize;
        this.queryObject.sortBy = pageControl.sortBy;
        this.queryObject.sortDesc = pageControl.sortDesc;
        this.queryObject.getAllRows = pageControl.getAllRows;
      }
    },
    testResultClicked(val) {
      if (val == true) {
        if (this.queryObject.isPositive == true) this.queryObject.isPositive = null;
        else this.queryObject.isPositive = true;
      } else if (val == false) {
        if (this.queryObject.isPositive == false) this.queryObject.isPositive = null;
        else this.queryObject.isPositive = false;
      }
    },
    medicallyJustifiableClicked(val) {
      if (this.queryObject.medicallyJustifiable === val) {
        this.queryObject.medicallyJustifiable = null;
      } else {
        this.queryObject.medicallyJustifiable = val;
      }
    },
    matchedClicked(val) {
      if (val == true) {
        if (this.queryObject.matched == true) this.queryObject.matched = null;
        else this.queryObject.matched = true;
      } else if (val == false) {
        if (this.queryObject.matched == false) this.queryObject.matched = null;
        else this.queryObject.matched = false;
      }
    },
    actedUponClicked(val) {
      if (val == true) {
        if (this.queryObject.actedUpon == true) this.queryObject.actedUpon = null;
        else this.queryObject.actedUpon = true;
      } else if (val == false) {
        if (this.queryObject.actedUpon == false) this.queryObject.actedUpon = null;
        else this.queryObject.actedUpon = false;
      }
    },
    async getTestResultsNewSearch(invalid) {
      console.log('new Search');
      if (invalid) {
        this.$refs.labResultFilterValidationRef.validate();
        return;
      }
      this.$refs.labResultFilterModalRef.hide('newSearch');
      this.totalTestResultsCount = null;
      this.queryObject.currentPage = 1;
      this.$refs.labResultTableRef.$children[2].$el.scrollTop = 0;
      await this.pushNewSearch(false);
    },
    pageChanged(newPage) {
      this.queryObject.currentPage = newPage;
      this.pushNewSearch(true);
    },
    async sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.queryObject.currentPage = 1;
      this.queryObject.sortBy = context.sortBy;
      this.queryObject.sortDesc = context.sortDesc;
      await this.pushNewSearch(true);
    },
    async pushNewSearch(replace) {
      const routeQueryObject = GlobalFunctions.convertQueryObjectToRouteObject(this.queryObject, this.defaultQuery, this.fieldAnnotations);
      if (!_.isEqual(routeQueryObject, this.$route.query)) {
        if (replace) {
          this.$router.replace({ name: 'LabResults', query: routeQueryObject });
        } else {
          this.$router.push({ name: 'LabResults', query: routeQueryObject });
        }
      } else {
        this.setFilterObject();
      }
    },
    setFilterObject() {
      this.searchedFilterObject = _.cloneDeep(this.queryObject);
    },
    refreshLabResults() {
      return this.$refs.labResultTableRef.refreshCurrentTestResults();
    },
    setIsBlind() {
      return this.$refs.labResultTableRef.editRows('SetIsBlind', 'Blind');
    },
    async setActedUpon() {
      return this.$refs.labResultTableRef.editRows('SetActedUpon', 'Acted Upon');
    },
    addCOCNumber() {
      this.queryObject.cocNumbers.push('');
    },
    removeCOCNumber(index) {
      this.queryObject.cocNumbers.splice(index, 1);
      if (this.queryObject.cocNumbers.length === 0) {
        this.queryObject.cocNumbers = [''];
      }
    },
    async exportCSV() {
      this.isBusy = true;
      var exportQueryObject = _.cloneDeep(this.queryObject);
      exportQueryObject.getAllRows = true;
      var response = await axios.get('/api/TestResult/ExportToCsv', {
        params: exportQueryObject,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        }
      });

      var FILE = window.URL.createObjectURL(new Blob([response.data]), { type: 'text/csv' });
      var docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', 'LabResults.csv');
      document.body.appendChild(docUrl);
      docUrl.click();
      this.isBusy = false;
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.header-text {
  width: 200px;
}
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-text {
  width: 200px;
}
</style>
