<template>
  <div>
    <div class="labResultHeader" :class="{ print: isPrintView }">
      <div>
        <div class="key-value">
          <label>COC</label>
          <span>{{ testResult.cocNumber }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'Account ID'">Acct ID</label>
          <span>{{ testResult.patientAccountNumberID }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Reason</label>
          <span>{{ testResult.relevantClinicalInformation }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Accession</label>
          <span>{{ testResult.accessionNumber }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Requisition</label>
          <span>{{ testResult.requisitionNumber }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Collection Date</label>
          <span>{{ testResult.collectionDateTime | dateTime }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Log In Date</label>
          <span>{{ testResult.specimenReceivedDate | dateTime }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Report Date</label>
          <span>{{ testResult.resultsReportedDateTime | dateTime }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'Status'">St</label>
          <span>{{ testResult.resultStatus }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label>Scientist</label>
          <span>{{ testResult.scientist }}</span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'Dilute Specimen'">Dilute</label>
          <span>
            <font-awesome-icon v-if="testResult.diluteSpecimen" icon="circle-check" size="lg" />
          </span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'Positive Result'">Pos</label>
          <span>
            <font-awesome-icon v-if="testResult.isPositive" icon="circle-check" size="lg" />
          </span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'B Bottle Tested'">B Btl</label>
          <span>
            <font-awesome-icon v-if="testResult.bbottleTested" icon="circle-check" size="lg" />
          </span>
        </div>
        <span></span>
        <div class="key-value">
          <label v-b-tooltip.hover :title="'Drug Presence Confirmed'">Drug C</label>
          <span>
            <font-awesome-icon v-if="testResult.drugPresenceConfirmed" icon="circle-check" size="lg" />
          </span>
        </div>
      </div>
    </div>
    <section>
      <div v-show="isBusy" class="spinner-container">
        <font-awesome-icon icon="spinner" spin size="4x" />
      </div>
      <template v-if="!isBusy && isPrintView">
        <div style="width: 100%">
          <LabResultSubstance :substances="substances" is-print-view></LabResultSubstance>
          <div style="page-break-before: always">
            <vue-pdf-embed
              v-if="cocFileData != null"
              :source="cocFileData"
              width="1000"
              :disable-annotation-layer="true"
              :disable-text-layer="true" />
          </div>
        </div>
      </template>
      <template v-if="!isBusy && !isPrintView">
        <div v-if="cocFileData != null" style="width: 100%" class="labResultSubstanceTable">
          <b-tabs v-model="activeTab" nav-wrapper-class="tabList">
            <b-tab title="Substance">
              <LabResultSubstance :substances="substances"></LabResultSubstance>
            </b-tab>
            <b-tab title="COC Form">
              <vue-pdf-embed :source="cocFileData" width="1000" :disable-annotation-layer="true" :disable-text-layer="true" />
            </b-tab>
          </b-tabs>
        </div>
        <div v-else class="labResultSubstanceTable">
          <LabResultSubstance :substances="substances"></LabResultSubstance>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import LabResultSubstance from '@/components/sharedBusiness/LabResultSubstance.vue';

export default {
  components: {
    VuePdfEmbed,
    LabResultSubstance
  },
  props: {
    testResult: { type: Object, required: true },
    isPrintView: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      isBusy: true,
      activeTab: 0,
      cocFileData: null
    };
  },
  computed: {
    substances() {
      return this.$store.getters.substances;
    }
  },
  watch: {
    async testResult(newValue) {
      if (!newValue) {
        return;
      }
      await this.getSubstances();
      await this.getChainOfCustodyDocument();
    }
  },
  async created() {
    await this.getSubstances();
    await this.getChainOfCustodyDocument();
  },
  methods: {
    async getSubstances() {
      this.isBusy = true;
      await this.$store.dispatch('getSubstances', this.testResult.testResultKey);
    },
    async getChainOfCustodyDocument() {
      this.cocFileData = null;
      let queryURL = '/api/Document/GetChainOfCustodyDocument';
      let queryObject = {
        testResultKey: this.testResult.testResultKey
      };

      try {
        const response = await axios.get(queryURL, {
          params: queryObject
        });
        if (response.data && response.data.length > 0) this.cocFileData = 'data:application/pdf;base64,' + response.data;
        else this.cocFileData = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    }
  }
};
</script>
