<template>
  <div id="collectionsPane" class="row" :class="{ collectionDPA: isDPA }">
    <div v-if="isDPA" class="col-2 navDiv no-print" v-click-outside="closeCollapsingSidebar" :class="{ active: showCollapsingSidebar }">
      <div id="navShelf" @click="toggleCollapsingSidebar">
        <div><font-awesome-icon icon="caret-right" size="xl" /></div>
      </div>
      <!-- Program Players -->
      <b-form-select
        v-model="queryObject.collectionType"
        :options="collectionTypes"
        value-field="value"
        text-field="name"
        class="search-select"></b-form-select>
      <b-button-group v-if="queryObject.collectionType == 0" size="sm" class="btnGrp">
        <b-button :class="[showingCurrentProgramPlayers ? ' toggleCurrentActive' : '', 'toggleCurrent']" @click="showCurrentProgramPlayers">
          Current
        </b-button>
        <b-button :class="['toggleCurrent', !showingCurrentProgramPlayers ? 'toggleCurrentActive' : '']" @click="showHistoricalProgramPlayers">
          Historical
        </b-button>
      </b-button-group>
      <b-form-checkbox
        v-if="queryObject.collectionType == 0 && showingCurrentProgramPlayers"
        id="showDischarged"
        v-model="showDischarged"
        v-b-popover.hover.top="'Include subjects discharged within last 30 days'"
        size="sm">
        Show Discharged
      </b-form-checkbox>
      <b-form-select
        v-if="queryObject.collectionType == 0 && !showingCurrentProgramPlayers"
        v-model="queryObject.year"
        :options="years"
        class="search-select small"
        @change="getProgramPlayersForYear"></b-form-select>
      <b-form-select
        v-if="queryObject.collectionType == 0 && !showingCurrentProgramPlayers"
        v-model="queryObject.month"
        :options="monthsForYear"
        value-field="month"
        text-field="monthName"
        class="search-select">
        <template #first>
          <b-form-select-option :value="null" disabled>Select Month</b-form-select-option>
        </template>
      </b-form-select>
      <ul v-if="queryObject.collectionType == 0" class="nav">
        <li
          v-for="option in filteredProgramPlayers"
          :key="option.subjectKey"
          class="nav-item"
          :value="option.subjectKey"
          @click="
            setProgramPlayer(option.subjectKey);
            pushNewSearch();
          ">
          <a :class="{ 'router-link-exact-active': option.subjectKey == queryObject.programPlayer }">
            <template v-if="showingCurrentProgramPlayers && !option.isInProgram">↩️</template>
            {{ option.fullName }}
          </a>
        </li>
      </ul>

      <!-- Annual Test -->
      <b-form-select
        v-if="queryObject.collectionType == 1"
        v-model="queryObject.club"
        :options="clubs"
        value-field="value"
        text-field="name"
        class="search-select"
        @change="getAnnualTestSubjectsForClub">
        <template #first>
          <b-form-select-option :value="null" disabled>Select Club</b-form-select-option>
        </template>
      </b-form-select>
      <ul v-if="queryObject.collectionType == 1" class="nav">
        <li
          v-for="option in annualTestSubjects"
          :key="option.subjectKey"
          class="nav-item"
          :class="{ isInProgram: option.isInProgram }"
          :value="option.subjectKey"
          @click="
            setAnnualTestSubject(option.subjectKey);
            pushNewSearch();
          ">
          <a :class="{ 'router-link-exact-active': option.subjectKey == queryObject.annualTestSubject }">
            <template v-if="option.isTested">✓</template>
            <template v-if="!option.isTested">&nbsp;&nbsp;</template>
            {{ option.subjectDescription }}
          </a>
        </li>
      </ul>

      <template v-if="isDPA && annualTestSubjectsTested.length > 0">
        <div class="testedSubHead">
          <label>Players Tested:</label>
          <span>{{ annualTestSubjectsTested }}</span>
        </div>
      </template>
    </div>
    <div :class="{ 'col-10': isDPA, 'col-12': isSA }">
      <b-card header-tag="header">
        <template #header>
          <div class="search-card-header no-print">
            <h6 class="header-text">Collection Entry</h6>
            <div v-if="isSA" class="search-options">
              <!-- Program Players -->
              <b-form-select
                v-model="queryObject.collectionType"
                :options="collectionTypes"
                value-field="value"
                text-field="name"
                class="search-select"></b-form-select>
              <b-button-group v-if="queryObject.collectionType == 0" size="sm" class="btnGrp">
                <b-button :class="[showingCurrentProgramPlayers ? ' toggleCurrentActive' : '', 'toggleCurrent']" @click="showCurrentProgramPlayers">
                  Current
                </b-button>
                <b-button
                  :class="['toggleCurrent', !showingCurrentProgramPlayers ? 'toggleCurrentActive' : '']"
                  @click="showHistoricalProgramPlayers">
                  Historical
                </b-button>
              </b-button-group>
              <b-form-checkbox
                v-if="queryObject.collectionType == 0 && showingCurrentProgramPlayers"
                id="showDischarged"
                v-model="showDischarged"
                v-b-popover.hover.top="'Include subjects discharged within last 30 days'"
                size="sm">
                Show Discharged
              </b-form-checkbox>
              <b-form-select
                v-if="queryObject.collectionType == 0 && !showingCurrentProgramPlayers"
                v-model="queryObject.year"
                :options="years"
                class="search-select small"
                @change="getProgramPlayersForYear"></b-form-select>
              <b-form-select
                v-if="queryObject.collectionType == 0 && !showingCurrentProgramPlayers"
                v-model="queryObject.month"
                :options="monthsForYear"
                value-field="month"
                text-field="monthName"
                class="search-select">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Month</b-form-select-option>
                </template>
              </b-form-select>

              <b-form-select v-if="queryObject.collectionType == 0" v-model="queryObject.programPlayer" class="search-select">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Subject</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in filteredProgramPlayers" :key="option.subjectKey" :value="option.subjectKey">
                  <template v-if="showingCurrentProgramPlayers && !option.isInProgram">↩️</template>
                  {{ option.fullName }}
                </b-form-select-option>
              </b-form-select>

              <!-- Annual Test -->
              <b-form-select
                v-if="queryObject.collectionType == 1"
                v-model="queryObject.club"
                :options="clubs"
                value-field="value"
                text-field="name"
                class="search-select"
                @change="getAnnualTestSubjectsForClub">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Club</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select v-if="queryObject.collectionType == 1" v-model="queryObject.annualTestSubject" class="search-select">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Player</b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="option in annualTestSubjects"
                  :key="option.subjectKey"
                  :value="option.subjectKey"
                  :class="{ isInProgram: option.isInProgram }">
                  <template v-if="option.isTested">✓</template>
                  <template v-if="!option.isTested">&nbsp;&nbsp;</template>
                  {{ option.subjectDescription }}
                </b-form-select-option>
              </b-form-select>

              <!-- Game Official -->
              <b-form-select
                v-if="queryObject.collectionType == 2"
                v-model="queryObject.gameOfficial"
                :options="gameOfficials"
                value-field="subjectKey"
                text-field="fullName"
                class="search-select">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Subject</b-form-select-option>
                </template>
              </b-form-select>

              <b-button variant="primary" :disabled="searchDisabled" :class="{ 'search-btn-disabled': searchDisabled }" @click="pushNewSearch">
                <font-awesome-icon icon="fa-prescription-bottle" size="lg" class="get-collections-icon" />
                <span>Get Collections</span>
              </b-button>
              <b-button variant="primary" class="print-button" @click="print">
                <font-awesome-icon icon="print" size="lg" />
              </b-button>
            </div>
            <b-button v-if="isDPA" variant="primary" class="print-button" @click="print">
              <font-awesome-icon icon="print" size="lg" />
            </b-button>
          </div>
        </template>
        <template v-if="annualTestSubjectsTested.length > 0">
          <div class="testedSubHead">
            <div>
              <label>Players Tested:</label>
              <span>{{ annualTestSubjectsTested }}</span>
            </div>
            <div class="collection-controls">
              <b-button variant="primary" class="annualTestLog" @click="openAnnualLog()">
                <font-awesome-icon icon="print" size="lg" />
                <span>Print Annual Testing Log</span>
              </b-button>
            </div>
          </div>
        </template>
        <CollectionEntriesTable
          v-if="filterObject"
          ref="collectionEntriesTable"
          :filter-object="filterObject"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @sort-changed="sortChanged"
          @annualTestAddedDeleted="reloadAnnualTestPlayers(queryObject.club)"></CollectionEntriesTable>
      </b-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import { DateTime } from 'luxon';
import CollectionEntriesTable from '@/components/sharedBusiness/CollectionEntriesTable';
import GlobalFunctions from '@/functions/GlobalFunctions';
import { mapState } from 'vuex';

export default {
  components: {
    CollectionEntriesTable
  },
  data() {
    return {
      filterObject: null,
      programPlayers: [],
      annualTestSubjects: [],
      queryObject: null,
      showDischarged: null,
      defaultQuery: {
        collectionType: 0,
        year: DateTime.now().toObject().year,
        month: DateTime.now().toObject().month,
        club: null,
        programPlayer: null,
        annualTestSubject: null,
        gameOfficial: null,
        showDischarged: false
      },
      defaultPageControl: {
        sortBy: 'collection',
        sortDesc: true
      },
      fieldAnnotations: {
        collectionType: 'Number',
        year: 'Number',
        month: 'Number',
        sortDesc: 'Boolean',
        showDischarged: 'Boolean'
      },
      sortBy: null,
      sortDesc: null,
      collectionsEntries: null,
      collectionSubject: null
    };
  },
  computed: {
    ...mapState({
      collectionTypes: (state) => state.collectionEntry.collectionTypes,
      yearMonthOptions: (state) => state.collectionEntry.yearMonthOptions,
      years: (state) => state.collectionEntry.years,
      clubs: (state) => state.collectionEntry.clubs,
      gameOfficials: (state) => state.collectionEntry.gameOfficials,
      storeInitialized: (state) => state.collectionEntry.initialized
    }),
    monthsForYear() {
      const now = DateTime.now().toObject();
      return this.yearMonthOptions.filter((item) => {
        if (!this.showingCurrentProgramPlayers)
          return item.year === this.queryObject.year && (now.year != this.queryObject.year || item.month < now.month);
        else return item.year === this.queryObject.year && item.month === now.month;
      });
    },
    viewingCurrentMonth() {
      const now = DateTime.now().toObject();
      return this.queryObject.year === now.year && this.queryObject.month === now.month;
    },
    showingCurrentProgramPlayers() {
      return this.viewingCurrentMonth && this.queryObject.collectionType === 0;
    },
    filteredProgramPlayers() {
      if (this.programPlayers.length == 0) return [];
      var filteredList = [];
      if (this.queryObject.year == this.programPlayers[0].year) {
        if (!this.viewingCurrentMonth) {
          filteredList = this.programPlayers.filter((item) => {
            return item.month === this.queryObject.month;
          });
        } else {
          filteredList = this.programPlayers.filter((item) => {
            return (
              item.month === this.queryObject.month && (this.showDischarged == true || (this.showDischarged == false && item.isInProgram == true))
            );
          });
        }

        return _.orderBy(filteredList, 'fullName');
      }
      return [];
    },
    selectedMonthName() {
      if (this.queryObject === null) return '';
      var selectedMonth = this.monthsForYear.find((item) => {
        return item.month === this.queryObject.month;
      });
      return selectedMonth ? selectedMonth.monthName : '';
    },
    searchDisabled() {
      if (this.queryObject === null) return true;
      return !(this.queryObject.programPlayer > 0) && !(this.queryObject.annualTestSubject > 0) && !(this.queryObject.gameOfficial > 0);
    },
    annualTestSubjectsTested() {
      if (!this.queryObject || this.queryObject.club == null || this.queryObject.club == '' || this.annualTestSubjects == null) return '';
      let total = this.annualTestSubjects.length;
      let tested = this.annualTestSubjects.filter((item) => {
        return item.isTested;
      });
      return (tested ? tested.length.toString() : '0') + ' / ' + total.toString();
    }
  },
  watch: {
    async 'queryObject.collectionType'(newVal, oldVal) {
      if (isNaN(oldVal)) {
        return;
      }

      this.queryObject = _.cloneDeep(this.defaultQuery);
      this.queryObject.collectionType = newVal;
      this.annualTestSubjects = [];
      this.filterObject = null;
      if (newVal === 0) {
        await this.getProgramPlayersForYear(this.queryObject.year);
      }
    },
    'queryObject.club'(newVal, oldVal) {
      if (!oldVal) {
        return;
      }

      this.resetSelectedSubject();
      this.annualTestSubjects = [];
    },
    'queryObject.year'(newVal, oldVal) {
      if (!oldVal) {
        return;
      }

      this.resetSelectedSubject();
      if (this.queryObject.month && !this.monthsForYear.includes(this.queryObject.month)) {
        this.queryObject.month = null;
      }
    },
    'queryObject.month'(newVal, oldVal) {
      if (!oldVal) {
        return;
      }

      this.resetSelectedSubject();
    },
    async $route(to, from) {
      if (
        to.query.collectionType === from.query.collectionType &&
        to.query.year === from.query.year &&
        to.query.month === from.query.month &&
        to.query.club === from.query.club &&
        to.query.programPlayer === from.query.programPlayer &&
        to.query.annualTestSubject === from.query.annualTestSubject &&
        to.query.gameOfficial === from.query.gameOfficial &&
        to.query.showDischarged === from.query.showDischarged
      ) {
        return;
      }
      this.initializeQueryFromURL();
      await this.initializeData();
      this.setFilterObject();
    }
  },
  async created() {
    this.initializeQueryFromURL();
    if (!this.storeInitialized) {
      await this.$store.dispatch('initializeCollectionTypes');
    }
    await this.initializeData();
    this.setFilterObject();
  },
  methods: {
    initializeQueryFromURL() {
      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = GlobalFunctions.convertRouteObjectToQueryObject(this.$route.query, combinedDefaults, this.fieldAnnotations);
      this.queryObject = {
        collectionType: fullQueryObject.collectionType,
        year: fullQueryObject.year,
        month: fullQueryObject.month,
        club: fullQueryObject.club,
        programPlayer: fullQueryObject.programPlayer,
        annualTestSubject: fullQueryObject.annualTestSubject,
        gameOfficial: fullQueryObject.gameOfficial
      };

      this.sortBy = fullQueryObject.sortBy;
      this.sortDesc = fullQueryObject.sortDesc;
      this.showDischarged = fullQueryObject.showDischarged;
    },
    resetSelectedSubject() {
      this.queryObject.programPlayer = null;
      this.queryObject.annualTestSubject = null;
      this.queryObject.gameOfficial = null;
      this.filterObject = null;
    },
    async initializeData() {
      if (this.queryObject.collectionType === 0) {
        await this.getProgramPlayersForYear(this.queryObject.year);
      } else if (this.queryObject.collectionType === 1) {
        await this.getAnnualTestSubjectsForClub(this.queryObject.club);
      }
    },
    async showCurrentProgramPlayers() {
      const now = DateTime.now().toObject();
      this.queryObject.year = now.year;
      this.queryObject.month = now.month;
      await this.getProgramPlayersForYear(this.queryObject.year);
    },
    async showHistoricalProgramPlayers() {
      this.queryObject.month = null;
      this.queryObject.programPlayer = null;

      const now = DateTime.now().toObject();
      if (now.month == 1) {
        var yearIndex = this.years.indexOf(now.year - 1);
        if (yearIndex > -1) {
          this.queryObject.year = this.years[yearIndex];
          await this.getProgramPlayersForYear(this.queryObject.year);
        }
      }
    },
    async getProgramPlayersForYear(value) {
      let queryURL = '/api/Collections/GetProgramPlayersByYear';
      let qo = { year: value };

      const response = await axios.get(queryURL, { params: qo });
      this.programPlayers = response.data;
    },
    async getAnnualTestSubjectsForClub(value) {
      let queryURL = '/api/Collections/GetAnnualTestSubjectsForClub';
      let qo = { club: value };

      const response = await axios.get(queryURL, { params: qo });
      this.annualTestSubjects = response.data;
    },
    sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.sortBy = context.sortBy;
      this.sortDesc = context.sortDesc;
      this.pushNewSearch(true);
    },
    pushNewSearch(replace) {
      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = { ...this.queryObject, showDischarged: this.showDischarged, sortBy: this.sortBy, sortDesc: this.sortDesc };
      const fullQueryObjectNoEmpties = GlobalFunctions.removeEmpty(fullQueryObject);
      const routeQueryObject = GlobalFunctions.convertQueryObjectToRouteObject(fullQueryObjectNoEmpties, combinedDefaults, this.fieldAnnotations);
      if (!_.isEqual(routeQueryObject, this.$route.query)) {
        if (replace) {
          this.$router.replace({ name: 'CollectionEntry', query: routeQueryObject });
        } else {
          this.$router.push({ name: 'CollectionEntry', query: routeQueryObject });
        }
      } else {
        this.setFilterObject();
      }
    },
    setFilterObject() {
      var qo = {};
      switch (this.queryObject.collectionType) {
        case 0:
          if (!this.queryObject.programPlayer) return;
          qo = {
            collectionType: this.queryObject.collectionType,
            subjectKey: this.queryObject.programPlayer,
            year: this.queryObject.year,
            month: this.queryObject.month,
            monthName: this.selectedMonthName
          };
          break;
        case 1:
          if (!this.queryObject.annualTestSubject) return;
          qo = {
            collectionType: this.queryObject.collectionType,
            subjectKey: this.queryObject.annualTestSubject
          };
          break;
        case 2:
          qo = {
            collectionType: this.queryObject.collectionType,
            subjectKey: this.queryObject.gameOfficial
          };
          break;
        default:
          break;
      }
      this.filterObject = _.cloneDeep(qo);
    },
    setProgramPlayer(opt) {
      if (opt) this.queryObject.programPlayer = opt;
    },
    setAnnualTestSubject(opt) {
      if (opt) this.queryObject.annualTestSubject = opt;
    },
    print() {
      window.print();
    },
    openAnnualLog() {
      const routeData = this.$router.resolve({ name: 'AnnualLog', params: { clubCode: this.queryObject.club } });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
<style scoped>
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-select {
  width: 200px;
}

.search-text {
  width: 200px;
}

.search-btn-disabled {
  background-color: lightgray;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.isInProgram {
  font-weight: bold;
}

.toggleCurrent {
  margin: 0 !important;
  border: solid 1px white;
}
.toggleCurrentActive {
  background-color: green !important;
}
</style>
