<template>
  <div id="subjectsPane">
    <b-alert :show="!!errorInfo" variant="danger">{{ errorInfo }}</b-alert>
    <b-card header-tag="header">
      <template #header>
        <div class="search-card-header">
          <h6>Subjects</h6>
          <div class="search-options no-print">
            <b-button variant="primary" @click="defaultFilters">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" size="lg" />
              <span>Clear Filters</span>
            </b-button>
            <b-form-select v-model="queryObject.isInProgram" :options="isInProgramOptions" class="search-select"></b-form-select>
            <b-form-input v-model="queryObject.subjectKey" placeholder="Subject Key" class="search-text"></b-form-input>
            <b-form-select
              v-model="queryObject.subjectTypeKey"
              :options="subjectTypeOptions"
              value-field="subjectTypeKey"
              text-field="subjectTypeDesc"
              class="search-select"></b-form-select>
            <b-form-input v-model="queryObject.subjectName" placeholder="Subject Name" class="search-text"></b-form-input>
            <b-button variant="primary" @click="pushNewSearch">
              <font-awesome-icon icon="search" size="lg" />
              <span>Search</span>
            </b-button>
            <b-button variant="primary" class="print-button" @click="print">
              <font-awesome-icon icon="print" size="lg" />
            </b-button>
          </div>
        </div>
      </template>

      <div class="pagiParent">
        <span v-if="subjects.length > 0">Displaying {{ subjects.length }} Subjects</span>
      </div>
      <div v-show="isBusy" class="spinner-container">
        <font-awesome-icon icon="spinner" spin size="4x" />
      </div>
      <b-table
        v-if="!isBusy"
        foot-clone
        show-empty
        :emptyText="'No subjects meet these search criteria.'"
        :items="subjects"
        :fields="subjectFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @sort-changed="sortChanged">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(currentClub)="data">
          <div
            class="clubIcon no-print"
            :class="[{ fa: tooltipPosition(data.item.currentClub, data.item.subjectTypeName) }, 'team' + data.item.currentClub]"
            data-balloon-pos="up"
            :aria-label="teamTooltip(data.item.currentClub, data.item.subjectTypeName)"></div>
          <div class="print-only">{{ data.item.currentClub }}</div>
        </template>
        <template #cell(name)="data">
          <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: data.item.subjectKey } }">
            {{ data.item.name }}
          </router-link>
        </template>
      </b-table>
      <!-- <b-alert :show="!isBusy && subjects.length === 0" variant="info">No subjects found for that search.</b-alert> -->
    </b-card>
  </div>
</template>
<script>
import axios from 'axios';
import GlobalFunctions from '@/functions/GlobalFunctions';

export default {
  data() {
    return {
      subjects: [],
      subjectFields: [
        { key: 'currentClub', label: 'Club', sortable: true },
        { key: 'name', label: 'Subject', sortable: true },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'statusInProgramName', label: 'Program Status', sortable: true }
      ],
      queryObject: null,
      defaultQuery: {
        subjectTypeKey: null,
        subjectKey: null,
        subjectName: null,
        isInProgram: true
      },
      defaultPageControl: {
        sortBy: 'name',
        sortDesc: false
      },
      fieldAnnotations: {
        isInProgram: 'Boolean',
        sortDesc: 'Boolean'
      },
      subjectTypes: [],
      totalRows: null,
      isBusy: true,
      isInProgramOptions: [
        { value: null, text: 'Any Program Status' },
        { value: true, text: 'In Program' },
        { value: false, text: 'Not In Program' }
      ],
      sortBy: null,
      sortDesc: null,
      errorInfo: null
    };
  },
  computed: {
    subjectTypeOptions() {
      let tempValue = { subjectTypeKey: null, subjectTypeDesc: 'Select Type' };
      let options = [tempValue];
      return options.concat(this.subjectTypes);
    }
  },
  watch: {
    async $route(to, from) {
      this.initializeQueryFromURL();
      if (
        to.query.subjectTypeKey === from.query.subjectTypeKey &&
        to.query.subjectKey === from.query.subjectKey &&
        to.query.subjectName === from.query.subjectName &&
        to.query.isInProgram === from.query.isInProgram
      ) {
        return;
      }
      await this.getSubjects();
    }
  },
  async created() {
    this.initializeQueryFromURL();
    await this.getSubjectTypes();
    await this.getSubjects();
  },
  methods: {
    initializeQueryFromURL() {
      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = GlobalFunctions.convertRouteObjectToQueryObject(this.$route.query, combinedDefaults, this.fieldAnnotations);
      this.queryObject = {
        subjectTypeKey: fullQueryObject.subjectTypeKey,
        subjectKey: fullQueryObject.subjectKey,
        subjectName: fullQueryObject.subjectName,
        isInProgram: fullQueryObject.isInProgram
      };
      this.sortBy = fullQueryObject.sortBy;
      this.sortDesc = fullQueryObject.sortDesc;
    },
    async defaultFilters() {
      this.queryObject = _.cloneDeep(this.defaultQuery);
      this.sortBy = this.defaultPageControl.sortBy;
      this.sortDesc = this.defaultPageControl.sortDesc;
      await this.pushNewSearch();
    },
    async getSubjectTypes() {
      const response = await axios.get('/api/Subject/GetSubjectTypes');
      this.subjectTypes = response.data;
    },
    async sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.sortBy = context.sortBy;
      this.sortDesc = context.sortDesc;
      await this.pushNewSearch(true);
    },
    async pushNewSearch(replace) {
      this.errorInfo = null;

      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = { ...this.queryObject, sortBy: this.sortBy, sortDesc: this.sortDesc };
      const routeQueryObject = GlobalFunctions.convertQueryObjectToRouteObject(fullQueryObject, combinedDefaults, this.fieldAnnotations);
      if (!_.isEqual(routeQueryObject, this.$route.query)) {
        if (replace) {
          this.$router.replace({ name: 'Subjects', query: routeQueryObject });
        } else {
          this.$router.push({ name: 'Subjects', query: routeQueryObject });
        }
      } else {
        await this.getSubjects();
      }
    },
    async getSubjects() {
      if (this.queryObject.isInProgram !== true && !this.queryObject.subjectKey && !this.queryObject.subjectName) {
        this.errorInfo = 'You must enter a subject key or subject name to search non-program subjects.';
        this.isBusy = false;
        return;
      }

      this.isBusy = true;
      this.errorInfo = null;
      this.subjects = [];
      let queryURL = '/api/Subject/GetSubjects';

      const response = await axios.get(queryURL, {
        params: this.queryObject
      });
      this.subjects = response.data;
      this.totalRows = this.subjects.length;
      this.isBusy = false;
    },
    teamTooltip(clubCode, role) {
      let n = '';
      switch (clubCode) {
        case 'FA':
        case null:
          n = 'Free Agent';
          break;
        case '':
          n = role;
          break;
        default:
          n = clubCode;
          break;
      }
      return n;
    },
    tooltipPosition(club, role) {
      let n = false;
      if (!club || club == null || club == 'FA' || role == 'NFL Official' || role == 'NFL Employee') n = true;
      return n;
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-select {
  width: 230px;
}

.search-text {
  width: 200px;
}
</style>
