<template>
  <ValidationObserver v-slot="{ invalid }" ref="caseEventFormValidationRef">
    <DraggableModal
      id="case-event-modal"
      class="case-event-modal"
      ref="caseEventModalRef"
      size="xl"
      :title="modalTitle"
      confirm-hide
      :has-edits="hasEdits"
      @show="initializeValues">
      <b-alert ref="errorMessageRef" :show="!!errorInfo" variant="danger">{{ errorInfo }}</b-alert>

      <b-form v-if="internalCaseEvent" class="case-event-container">
        <div>
          <b-form-group label="Event Date" label-for="case-event-date">
            <ValidationProvider v-slot="{ errors }" name="Case Event Date" rules="required|date">
              <span class="text-danger">{{ errors[0] }}</span>
              <DateTimePicker
                id="case-event-date"
                v-model="internalCaseEvent.caseEventDate"
                date-only
                :disabled="internalCaseEvent.disableEventEditing"></DateTimePicker>
            </ValidationProvider>
          </b-form-group>

          <b-form-group>
            <ValidationProvider v-slot="{ errors }" name="Event Type" rules="required">
              <span class="text-danger">{{ errors[0] }}</span>
              <b-form-select
                v-model="internalCaseEvent.eventType"
                :options="eventTypeOptions"
                :disabled="editingExistingCaseEvent || internalCaseEvent.disableEventEditing"
                class="search-select"
                @change="eventTypeChanged">
                <template #first>
                  <b-form-select-option :value="null">{{ defaultSelectionLanguage }} Event Type</b-form-select-option>
                </template>
              </b-form-select>
            </ValidationProvider>
          </b-form-group>

          <template v-if="alcoholAbstentionFieldsVisible">
            <b-form-group>
              <b-form-checkbox id="alcohol-abstention" v-model="internalCaseEvent.alcoholAbstention">Alcohol Abstention</b-form-checkbox>
            </b-form-group>
            <b-form-group label="Abstention Begin Date" label-for="abstention-begin-date">
              <DateTimePicker id="case-abstention-begin-date" v-model="internalCaseEvent.abstentionBeginDate" date-only></DateTimePicker>
            </b-form-group>
            <b-form-group label="Abstention End Date" label-for="abstention-end-date">
              <DateTimePicker id="case-abstention-end-date" v-model="internalCaseEvent.abstentionEndDate" date-only></DateTimePicker>
            </b-form-group>
          </template>

          <b-form-group>
            <b-form-select
              v-model="internalCaseEvent.event"
              :options="eventOptions"
              class="search-select"
              :disabled="internalCaseEvent.disableEventEditing"
              @change="eventChanged">
              <template #first>
                <b-form-select-option :value="null">{{ defaultSelectionLanguage }} Event</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group>
            <b-form-select
              v-model="internalCaseEvent.eventReason"
              :options="eventReasonOptions"
              :disabled="internalCaseEvent.disableEventEditing"
              class="search-select"
              @change="eventReasonChanged">
              <template #first>
                <b-form-select-option :value="null">{{ defaultSelectionLanguage }} Event Reason</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group>
            <b-form-select
              v-model="internalCaseEvent.eventSubReason"
              :options="eventSubReasonOptions"
              :disabled="internalCaseEvent.disableEventEditing"
              class="search-select">
              <template #first>
                <b-form-select-option :value="null">{{ defaultSelectionLanguage }} Sub Reason</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group label="COC #:">
            <b-form-input id="cocNumber" v-model="internalCaseEvent.cocNumber" :disabled="internalCaseEvent.disableEventEditing"></b-form-input>
          </b-form-group>
        </div>

        <b-form-group label="Documents">
          <div class="upload-document-section">
            <!-- <input type="button" value="Select File" @click="showFileUpload" /> -->
            <input v-show="false" ref="fileRef" type="file" multiple @change="fileSelected" />
          </div>
          <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <div class="dropzone-table-container">
              <table class="table" v-if="internalCaseEvent.caseEventDocuments.length > 0 || queuedFileUpload.length > 0">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Remove</th>
                  </tr>
                </thead>
              </table>
              <b-table
                v-if="internalCaseEvent.caseEventDocuments.length > 0"
                striped
                hover
                small
                foot-clone
                :items="existingUploadedDocuments"
                :fields="documentFields"
                :sort-desc.sync="sortDesc"
                :sort-by.sync="sortBy"
                thead-class="d-none">
                <template #head()="{ label, field }">
                  <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
                </template>
                <template #cell(documentType)="data">
                  <span v-b-tooltip.hover :title="data.item.documentType">
                    <font-awesome-icon :icon="fileMatch(data.item.documentType)" size="xl" />
                  </span>
                </template>
                <template #cell(documentName)="data">
                  <a :href="`/api/Document/CaseEventDocument/${data.item.caseEventDocumentKey}`" target="_blank">{{ data.item.documentName }}</a>
                </template>
                <template #cell(deleteButton)="data">
                  <span v-if="!(data.item.event && data.item.event.cannotDeleteEvent)" class="table-column-buttons icon actionIcon">
                    <font-awesome-icon icon="trash" size="lg" class="fake-link" @click="deleteCaseEventDocument(data.item.caseEventDocumentKey)" />
                  </span>
                </template>
              </b-table>
              <b-table
                v-if="queuedFileUpload.length > 0"
                :items="queuedFileUpload"
                :fields="fileUploadDocumentFields"
                :sort-desc.sync="sortDesc"
                :sort-by.sync="sortBy"
                thead-class="d-none">
                <template #cell(documentType)="data">
                  <span v-b-tooltip.hover :title="data.item.type">
                    <font-awesome-icon :icon="fileMatch(data.item.type)" size="xl" />
                  </span>
                </template>
                <template #cell(documentName)="data">{{ data.item.name }}</template>
                <template #cell(deleteButton)="data">
                  <span v-if="!(data.item.event && data.item.event.cannotDeleteEvent)" class="table-column-buttons icon actionIcon">
                    <font-awesome-icon icon="trash" size="lg" class="fake-link" @click="removeDocumentToUpload(data.item)" />
                  </span>
                </template>
              </b-table>
            </div>
            <label for="fileInput" class="file-label" @click="showFileUpload">
              <div v-if="isDragging">Release to add file</div>
              <div v-else>
                <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
                Click or Drop files here
              </div>
            </label>
          </div>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <div class="d-modal-buttons">
            <b-button variant="primary" :disabled="saveInProgress" @click="saveCaseEvent(invalid)">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
            <b-button variant="secondary" @click="$modal.hide('case-event-modal')">Cancel</b-button>
          </div>
        </div>
      </template>
    </DraggableModal>
  </ValidationObserver>
</template>
<script>
import { DateTime } from 'luxon';
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    caseKey: { type: Number, required: false, default: null },
    caseEvent: { type: Object, required: false, default: null }
  },
  data() {
    return {
      internalCaseEvent: null,
      documentFields: [
        { key: 'documentType', label: '', class: 'actionIcon' },
        { key: 'documentName', label: 'File Name' },
        { key: 'deleteButton', label: 'Delete', class: 'rightAlign' }
      ],
      fileUploadDocumentFields: [
        { key: 'documentType', label: '', class: 'actionIcon' },
        { key: 'documentName', label: 'File Name' },
        { key: 'deleteButton', label: 'Delete', class: 'rightAlign' }
      ],
      isDragging: false,
      queuedFileUpload: [],
      queuedFileDeletion: [],
      sortBy: null,
      sortDesc: true,
      saveInProgress: false,
      errorInfo: null
    };
  },
  computed: {
    editingExistingCaseEvent() {
      return !!(this.caseEvent && this.caseEvent.caseEventKey);
    },
    modalTitle() {
      if (this.editingExistingCaseEvent) return 'Edit Case Event';
      return 'Add New Case Event';
    },
    eventTypeOptions() {
      return this.getChildLookups(true, 0, this.internalCaseEvent.eventType);
    },
    eventOptions() {
      return this.getChildLookups(!!this.internalCaseEvent.eventType, this.internalCaseEvent.eventType, this.internalCaseEvent.event);
    },
    eventReasonOptions() {
      return this.getChildLookups(!!this.internalCaseEvent.event, this.internalCaseEvent.event, this.internalCaseEvent.eventReason);
    },
    eventSubReasonOptions() {
      return this.getChildLookups(!!this.internalCaseEvent.eventReason, this.internalCaseEvent.eventReason, this.internalCaseEvent.eventSubReason);
    },
    alcoholAbstentionFieldsVisible() {
      return [1, 5].includes(this.internalCaseEvent.eventType);
    },
    defaultSelectionLanguage() {
      return this.editingExistingCaseEvent ? 'No' : 'Select';
    },
    isNewAdmission() {
      if (!this.subject.cases || this.subject.cases.length === 0) {
        return true;
      }

      var currentCase = this.subject.cases[0];

      if (currentCase) {
        return !!currentCase.discharge;
      }

      return true;
    },
    hasEdits() {
      return !_.isEqual(this.caseEvent, this.internalCaseEvent);
    },
    existingUploadedDocuments() {
      return this.internalCaseEvent.caseEventDocuments.filter((doc) => this.queuedFileDeletion.indexOf(doc.caseEventDocumentKey) == -1);
    }
  },
  created() {
    this.initializeValues();
  },
  methods: {
    scrollTop() {
      setTimeout(() => {
        const errorMessage = this.$refs.errorMessageRef;
        if (errorMessage) {
          errorMessage.$el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    },
    initializeValues() {
      this.queuedFileUpload = [];
      this.queuedFileDeletion = [];
      if (this.editingExistingCaseEvent) {
        this.internalCaseEvent = _.cloneDeep(this.caseEvent);
        return;
      }

      let caseKeyToUse = this.caseKey;
      let caseEventDateToUse = null;
      if (this.isNewAdmission) {
        caseKeyToUse = null;
        caseEventDateToUse = DateTime.now().toISO();
      }

      this.internalCaseEvent = {
        caseEventKey: null,
        subjectKey: this.subject.subjectKey,
        caseKey: caseKeyToUse,
        caseEventDate: caseEventDateToUse,
        eventType: null,
        event: null,
        eventReason: null,
        eventSubReason: null,
        cocNumber: null,
        alcoholAbstention: null,
        abstentionBeginDate: null,
        abstentionEndDate: null,
        bbottleTested: null,
        diluteSpecimen: null,
        isPositive: null,
        testResultKey: null,
        caseEventDocuments: []
      };
    },
    eventTypeChanged() {
      this.internalCaseEvent.alcoholAbstention = this.internalCaseEvent.eventType === 5;
      this.internalCaseEvent.event = null;
      this.internalCaseEvent.eventReason = null;
      this.internalCaseEvent.eventSubReason = null;
    },
    eventChanged() {
      this.internalCaseEvent.eventReason = null;
      this.internalCaseEvent.eventSubReason = null;
    },
    eventReasonChanged() {
      this.internalCaseEvent.eventSubReason = null;
    },
    async saveCaseEvent(invalid) {
      if (invalid) {
        this.$refs.caseEventFormValidationRef.validate();
        return;
      }

      this.saveInProgress = true;
      if (this.editingExistingCaseEvent) {
        await this.editCaseEvent();
      } else {
        if (this.isNewAdmission) {
          await this.newAdmission();
        } else {
          await this.addNewCaseEvent();
        }
      }

      this.saveInProgress = false;
    },
    async newAdmission() {
      try {
        var response = await axios.post('/api/Subject/NewAdmission', this.internalCaseEvent);

        if (response.data > 0) {
          this.internalCaseEvent.caseEventKey = response.data;
          await this.UploadDeleteDocuments();

          this.makeToast('Case event has been added.');
          this.$refs.caseEventModalRef.hide('confirmed');
          this.$emit('caseEventSaved');
          this.$emit('newCaseCreated');
        }
      } catch (error) {
        this.errorInfo = error.response.data;
        this.scrollTop();
      }
    },
    async addNewCaseEvent() {
      try {
        var response = await axios.post('/api/Subject/AddCaseEvent', this.internalCaseEvent);

        if (response.data > 0) {
          this.internalCaseEvent.caseEventKey = response.data;
          await this.UploadDeleteDocuments();

          this.makeToast('Case event has been added.');
          this.$refs.caseEventModalRef.hide('confirmed');
          this.$emit('caseEventSaved');
        }
      } catch (error) {
        this.errorInfo = error.response.data;
        this.scrollTop();
      }
    },
    async editCaseEvent() {
      try {
        await axios.post('/api/Subject/EditCaseEvent', this.internalCaseEvent);
        await this.UploadDeleteDocuments();

        this.makeToast('Case event has been edited.');
        this.$refs.caseEventModalRef.hide('confirmed');
        this.$emit('caseEventSaved');
      } catch (error) {
        this.errorInfo = error.response.data;
        this.scrollTop();
      }
    },
    getChildLookups(populateOptions, eventLookupID, alreadySelectedID) {
      if (!populateOptions || !this.eventLookupsReady) {
        return [];
      }

      const filteredLookups = this.eventLookups.filter((item) => {
        return (!item.inActive && item.parentEventLookupID === eventLookupID) || item.eventLookupID === alreadySelectedID;
      });
      const mapped = filteredLookups.map((item) => ({
        value: item.eventLookupID,
        text: item.eventLookupDesc
      }));

      return mapped;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.fileRef.files = e.dataTransfer.files;
      this.fileSelected();
      this.isDragging = false;
    },
    fileSelected() {
      var uploadedFiles = this.$refs.fileRef.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.queuedFileUpload.push(uploadedFiles[i]);
      }
    },
    showFileUpload() {
      this.$refs.fileRef.click();
    },
    async UploadDeleteDocuments() {
      if (this.queuedFileUpload && this.queuedFileUpload.length > 0) await this.uploadFileInternal();
      if (this.queuedFileDeletion && this.queuedFileDeletion.length > 0) await this.deleteCaseEventDocumentInternal();
    },
    async uploadFile() {
      await this.uploadFileInternal();

      this.$emit('caseEventDocumentUploaded');
      this.$refs.caseEventModalRef.hide('confirmed');
      this.makeToast('Document uploaded.');
    },
    async uploadFileInternal() {
      for (var i = 0; i < this.queuedFileUpload.length; i++) {
        const formData = new FormData();
        formData.append('file', this.queuedFileUpload[i]);
        const headers = { 'Content-Type': 'multipart/form-data' };
        const url = `/api/Document/UploadCaseEventDocument/${this.internalCaseEvent.caseEventKey}`;
        await axios.post(url, formData, { headers });
      }
    },
    async deleteCaseEventDocument(caseEventDocumentKey) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this document?', {
        title: 'Delete Document',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        this.queuedFileDeletion.push(caseEventDocumentKey);
      }
    },
    async deleteCaseEventDocumentInternal() {
      for (var i = 0; i < this.queuedFileDeletion.length; i++) {
        await axios.post(`/api/Document/DeleteCaseEventDocument/${this.queuedFileDeletion[i]}`);
      }
    },
    async removeDocumentToUpload(item) {
      const confirmation = await this.$bvModal.msgBoxConfirm('Are you sure that you want to delete this document?', {
        title: 'Delete Document',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });
      if (confirmation) {
        let idx = this.queuedFileUpload.indexOf(item);
        this.queuedFileUpload.splice(idx, 1);
      }
    },
    fileMatch(file) {
      let fileImage = '';
      if (file.indexOf('pdf') > -1) {
        fileImage = '-pdf';
      } else if (file.indexOf('image') > -1) {
        fileImage = '-image';
      } else if (file.indexOf('text') > -1) {
        fileImage = '-lines';
      } else {
        fileImage = '';
      }
      return 'fa-regular fa-file' + fileImage;
    }
  }
};
</script>
<style scoped>
.upload-document-section {
  display: flex;
  justify-content: space-between;
}

.upload-button {
  width: 150px;
}
</style>
