import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import App from './App.vue';
import router from './router/index';
import store from './store';
import { sync } from 'vuex-router-sync';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from './icons';
import VModal from 'vue-js-modal';
import BlockUI from 'vue-blockui';

import ClickOutsideDirective from './directives/clickOutside';
import OpenModalDirective from './directives/openModal';
import BasicMixin from './mixins/basicMixin';

import './filters';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import DateTimePicker from '@/components/utilities/DateTimePicker';
import ServerSidePagination from '@/components/utilities/ServerSidePagination';
import TableSortIcons from '@/components/utilities/TableSortIcons';
import CountBadge from '@/components/utilities/CountBadge';
import DraggableModal from '@/components/utilities/DraggableModal';

import SASubjectDetails from '@/views/SASubjectDetails';
import DPASubjectDetails from '@/views/DPASubjectDetails';

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { DateTime } from 'luxon';

Vue.use(BootstrapVue);
Vue.use(VModal);
Vue.use(BlockUI);

// Registration of global components
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('DateTimePicker', DateTimePicker);
Vue.component('ServerSidePagination', ServerSidePagination);
Vue.component('TableSortIcons', TableSortIcons);
Vue.component('CountBadge', CountBadge);
Vue.component('DraggableModal', DraggableModal);

Vue.component('SASubjectDetails', SASubjectDetails);
Vue.component('DPASubjectDetails', DPASubjectDetails);

extend('required', {
  ...rules.required
});
extend('numeric', {
  ...rules.numeric
});
extend('length', {
  ...rules.length
});
extend('min_value', {
  ...rules.min_value
});
extend('date', (value) => {
  if (!value) {
    return true;
  }

  if (value === 'Invalid Date') {
    return '{_field_} is not a valid date.';
  }

  if (value === 'Invalid Time') {
    return '{_field_} has an invalid time.';
  }

  if (DateTime.fromISO(value).isValid) {
    return true;
  }

  return '{_field_} is invalid.';
});
extend('greater', {
  params: ['target', 'targetName'],
  validate(value, { target, targetName }) {
    const greater = value > target;
    if (greater) {
      return true;
    }
    return `The {_field_} must be greater than the ${targetName}.`;
  }
});
extend('greaterOrEqual', {
  params: ['target', 'targetName'],
  validate(value, { target, targetName }) {
    const greaterOrEqual = value >= target;
    if (greaterOrEqual) {
      return true;
    }
    return `The {_field_} must be greater or equal than the ${targetName}.`;
  }
});
extend('greaterDate', {
  params: ['target', 'targetName'],
  validate(value, { target, targetName }) {
    const dateTimeValue = DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    const dateTimeTarget = DateTime.fromISO(target).toFormat('yyyy-MM-dd');
    const greater = dateTimeValue > dateTimeTarget;
    if (greater) {
      return true;
    }
    return `The {_field_} must not be on the same day as ${targetName}.`;
  }
});

sync(store, router);

Vue.directive('d-modal', OpenModalDirective);
Vue.directive('click-outside', ClickOutsideDirective);
Vue.mixin(BasicMixin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
