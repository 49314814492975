<template>
  <div id="labResultsTable">
    <div class="tableFiltersHead" ref="tableFiltersHead">
      <b-form-tags v-model="filterTokens" v-if="!hideTokens" id="searchTokens" placeholder="No active search filters">
        <template v-slot="{ tags }">
          <b-form-tag v-for="tag in tags" :key="tag" :title="tag" no-remove>{{ tag }}</b-form-tag>
        </template>
      </b-form-tags>
      <ServerSidePagination
        v-model="internalCurrentPage"
        :total-rows="totalTestResultsCount"
        :per-page="pageSize"
        :disabled="isBusy"></ServerSidePagination>
    </div>
    <div class="lazyload-trigger" :style="triggerHeight" :class="{ empty: testResults.length == 0 }">
      <b-table
        ref="testResultsTableRef"
        striped
        hover
        small
        foot-clone
        :emptyText="'No lab results meet these filter criteria.'"
        :items="testResults"
        :fields="testResultFields"
        :sort-by.sync="internalSortBy"
        :sort-desc.sync="internalSortDesc"
        no-local-sorting
        no-sort-reset
        selectable
        no-select-on-click
        responsive
        show-empty
        @sort-changed="sortChanged"
        @scroll.native="onScroll">
        <template #head()="{ label, field, popover }">
          <TableSortIcons :label="label" :field="field" :popover="popover" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <!-- A custom formatted header cell for field 'name' -->
        <template #head(checkbox)>
          <span class="no-print">
            <font-awesome-icon v-if="selectedState === 'all'" icon="square-check" @click="clearSelected()" />
            <font-awesome-icon v-if="selectedState === 'some'" icon="square-minus" @click="selectAllRows()" />
            <font-awesome-icon v-if="selectedState === 'none'" icon="fa-regular fa-square" @click="selectAllRows()" />
          </span>
        </template>
        <template #cell(checkbox)="data">
          <span class="no-print">
            <font-awesome-icon v-if="isRowSelected(data.item.testResultKey)" icon="square-check" @click="unselectRow(data.item.testResultKey)" />
            <font-awesome-icon v-else icon="fa-regular fa-square" @click="selectRow(data.item.testResultKey)" />
          </span>
        </template>
        <template #cell(cocNumber)="data">
          <a v-d-modal.lab-result-modal class="fake-link" @click="selectTestResult(data.item.testResultKey)">{{ data.item.cocNumber }}</a>
        </template>
        <template #cell(collectionDateTime)="data">
          <div v-if="data.item.collectionDateTime">{{ data.item.collectionDateTime | date }},</div>
          <div>{{ data.item.collectionDateTime | time }}</div>
        </template>
        <template #cell(resultsReportedDateTime)="data">
          <div v-if="data.item.resultsReportedDateTime">{{ data.item.resultsReportedDateTime | date }},</div>
          <div>{{ data.item.resultsReportedDateTime | time }}</div>
        </template>
        <template #cell(importDate)="data">
          <div v-if="data.item.importDate">{{ data.item.importDate | date }},</div>
          <div>{{ data.item.importDate | time }}</div>
        </template>
        <template #cell(bbottleTested)="data">
          <font-awesome-icon v-if="data.item.bbottleTested" icon="circle-check" size="lg" />
        </template>
        <template #cell(diluteSpecimen)="data">
          <font-awesome-icon v-if="data.item.diluteSpecimen" icon="circle-check" size="lg" />
        </template>
        <template #cell(isPositive)="data">
          <font-awesome-icon v-if="data.item.isPositive" icon="circle-check" size="lg" />
          <span v-if="data.item.medicallyJustifiable" class="MJ" v-b-tooltip.hover :title="'Medically Justifiable'">
            <MedicallyJustifiableIcon :no-click="true"></MedicallyJustifiableIcon>
          </span>
        </template>
        <template #cell(isAnnual)="data">
          <font-awesome-icon v-if="data.item.isAnnual" icon="circle-check" size="lg" />
        </template>
        <template #cell(isBlind)="data">
          <font-awesome-icon v-if="data.item.isBlind" icon="circle-check" size="lg" />
        </template>
        <template #cell(isCombine)="data">
          <font-awesome-icon v-if="data.item.isCombine" icon="circle-check" size="lg" />
        </template>
        <template #cell(specimenSubstituted)="data">
          <font-awesome-icon v-if="data.item.specimenSubstituted" icon="circle-check" size="lg" />
        </template>
        <template #cell(invalidResult)="data">
          <font-awesome-icon v-if="data.item.invalidResult" icon="circle-check" size="lg" />
        </template>
        <template #cell(actedUpon)="data">
          <font-awesome-icon v-if="data.item.actedUpon" icon="circle-check" size="lg" />
        </template>
        <template #cell(isGameOfficial)="data">
          <font-awesome-icon v-if="data.item.isGameOfficial" icon="circle-check" size="lg" />
        </template>
        <template #cell(isPreEmployment)="data">
          <font-awesome-icon v-if="data.item.isPreEmployment" icon="circle-check" size="lg" />
        </template>
      </b-table>
      <b-overlay :show="isBusy" no-wrap opacity="0.5"></b-overlay>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import axios from 'axios';
import qs from 'qs';
import MedicallyJustifiableIcon from '@/components/icons/MedicallyJustifiableIcon';

export default {
  components: {
    MedicallyJustifiableIcon
  },
  props: {
    filterObject: { type: Object, required: true },
    hideTokens: { type: Boolean, required: false, default: false },
    homeUnmatched: { type: Boolean, required: false, default: false },
    homeActivity: { type: Boolean, required: false, default: false },
    currentPage: { type: Number, required: true },
    sortBy: { type: String, required: true },
    sortDesc: { type: Boolean, required: true },
    useNav: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      pageSize: 50,
      internalCurrentPage: null,
      internalSortBy: null,
      internalSortDesc: null,
      isBusy: true,
      testResults: [],
      totalTestResultsCount: null,
      triggerHeight: {},
      selectedRows: [],
      warningMessage: null,
      filterTokens: [],
      actionFields: [{ key: 'checkbox', label: '', sortable: false }],
      detailPageTestResultFields: [
        { key: 'cocNumber', label: 'COC #', sortable: true },
        { key: 'subjectName', label: 'Subject', sortable: false },
        { key: 'patientAccountNumberID', label: 'Account', sortable: true },
        { key: 'collectionDateTime', label: 'Collection', sortable: true, class: 'dateTime' },
        { key: 'resultsReportedDateTime', label: 'Report Date', sortable: true, class: 'dateTime' },
        { key: 'importDate', label: 'Import Date', class: 'dateTime' },
        { key: 'resultStatus', label: 'Status' },
        // { key: 'scientist', label: 'Scientist' },
        { key: 'bbottleTested', label: 'B Btl', class: 'centerAlign', popover: 'B Bottle Tested' },
        { key: 'diluteSpecimen', label: 'Dil', class: 'centerAlign', popover: 'Dilute Specimen' },
        { key: 'isPositive', label: 'Pos', class: 'centerAlign', popover: 'Positive Test' },
        { key: 'isAnnual', label: 'A', class: 'centerAlign', popover: 'Annual Test' },
        { key: 'isBlind', label: 'B', class: 'centerAlign', popover: 'Blind' },
        { key: 'isCombine', label: 'CB', class: 'centerAlign', popover: 'Combine' },
        { key: 'specimenSubstituted', label: 'SS', class: 'centerAlign', popover: 'Specimen Substituted' },
        { key: 'invalidResult', label: 'IR', class: 'centerAlign', popover: 'Invalid Result' },
        { key: 'actedUpon', label: 'Acted Upon', class: 'centerAlign' },
        { key: 'isGameOfficial', label: 'GO', class: 'centerAlign', popover: 'Game Official' },
        { key: 'isPreEmployment', label: 'PE', class: 'centerAlign', popover: 'Pre Employment' }
      ],
      homePageUnmatchedTestResultFields: [
        { key: 'cocNumber', label: 'COC #', sortable: true },
        { key: 'collectionDateTime', label: 'Collection', sortable: true, class: 'dateTime' },
        { key: 'resultsReportedDateTime', label: 'Report Date', sortable: true, class: 'dateTime' },
        { key: 'importDate', label: 'Import Date', class: 'dateTime' },
        { key: 'bbottleTested', label: 'B Btl', class: 'centerAlign', popover: 'B Bottle Tested' },
        { key: 'isPositive', label: 'Pos', class: 'centerAlign', popover: 'Positive Test' }
      ],
      preventLoadMoreOnScroll: false
    };
  },
  computed: {
    selectedState() {
      if (!this.selectedRows.length) {
        return 'none';
      }

      if (this.selectedRows.length === this.testResults.length) {
        return 'all';
      }

      return 'some';
    },
    testResultFields() {
      if (this.homeUnmatched) return this.homePageUnmatchedTestResultFields;
      else if (this.homeActivity) return this.detailPageTestResultFields;
      else return this.actionFields.concat(this.detailPageTestResultFields);
    }
  },
  watch: {
    currentPage() {
      this.setPageControlValues();
    },
    sortBy() {
      this.setPageControlValues();
    },
    sortDesc() {
      this.setPageControlValues();
    },
    internalCurrentPage(newValue) {
      this.$emit('update:currentPage', newValue);
    },
    internalSortBy(newValue) {
      this.$emit('update:sortBy', newValue);
    },
    internalSortDesc(newValue) {
      this.$emit('update:sortDesc', newValue);
    },
    filterTokens() {
      setTimeout(() => {
        this.matchHeight();
      });
    },
    async filterObject() {
      this.isBusy = true;
      this.totalTestResultsCount = null;
      await this.getTestResults();
    }
  },
  async created() {
    this.setPageControlValues();
    await this.getTestResults();
  },
  mounted() {
    this.matchHeight();
    window.addEventListener('resize', this.matchHeight);
  },
  methods: {
    setPageControlValues() {
      this.internalCurrentPage = this.currentPage;
      this.internalSortBy = this.sortBy;
      this.internalSortDesc = this.sortDesc;
    },
    async getLabResultsNewPage(newPage) {
      this.isBusy = true;
      this.internalCurrentPage = newPage;
      this.$emit('page-changed', newPage);
      if (!this.useNav) {
        await this.getTestResults();
      }
    },
    async onScroll(event) {
      if (this.preventLoadMoreOnScroll) return;
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 5) {
        if (this.isBusy) {
          return;
        }
        if (this.totalTestResultsCount <= this.testResults.length) {
          return;
        }

        this.isBusy = true;
        this.internalCurrentPage++;
        this.getTestResults(true);
      }
    },
    async sortChanged(context) {
      if (!context.sortBy || context.sortBy.length == 0) {
        return;
      }
      this.internalCurrentPage = 1;
      this.internalSortBy = context.sortBy;
      this.internalSortDesc = context.sortDesc;
      this.$emit('sort-changed', context);
      if (!this.useNav) {
        await this.getTestResults();
      }
    },
    async getTestResults(append, pageToGet) {
      if (!pageToGet) pageToGet = this.internalCurrentPage;

      const pageControlObject = {
        pageSize: this.pageSize,
        currentPage: pageToGet,
        sortBy: this.internalSortBy,
        sortDesc: this.internalSortDesc
      };

      const queryObject = { ...this.filterObject, ...pageControlObject };
      const response = await axios.get('/api/TestResult/SearchTestResults', {
        params: queryObject,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        }
      });

      this.totalTestResultsCount = response.data.totalResults;

      if (append) {
        this.testResults = this.testResults.concat(response.data.paginatedResults);
      } else {
        this.testResults = response.data.paginatedResults;
        this.selectedRows = [];
      }

      if (!append) this.getSearchTokens();

      this.isBusy = false;
    },
    async refreshCurrentTestResults() {
      const pageControlObject = {
        pageSize: this.pageSize * this.internalCurrentPage,
        currentPage: 1,
        sortBy: this.internalSortBy,
        sortDesc: this.internalSortDesc
      };

      const queryObject = { ...this.filterObject, ...pageControlObject };
      const response = await axios.get('/api/TestResult/SearchTestResults', {
        params: queryObject,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        }
      });

      this.totalTestResultsCount = response.data.totalResults;

      this.testResults = response.data.paginatedResults;
      this.selectedRows = [];

      this.isBusy = false;
    },
    setIsBlind() {
      return this.editRows('SetIsBlind', 'Blind');
    },
    async setActedUpon() {
      return this.editRows('SetActedUpon', 'Acted Upon');
    },
    async editRows(actionPath, actionDescription) {
      this.warningMessage = null;
      const toBeEditedCount = this.selectedRows.length;
      if (toBeEditedCount === 0) {
        this.warningMessage = 'You must select at least one test result.';
        return;
      }

      const confirmMessage = `Are you sure that you want to set these ${toBeEditedCount} test results as '${actionDescription}'?`;
      const confirmation = await this.$bvModal.msgBoxConfirm(confirmMessage, {
        title: 'Are You Sure?',
        okVariant: 'warning',
        okTitle: 'Yes',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      });

      if (confirmation) {
        const requestObject = { testResultKeys: this.selectedRows.map((x) => x) };
        await axios.post(`/api/TestResult/${actionPath}`, requestObject);
        this.$refs.testResultsTableRef.clearSelected();
        this.makeToast(`${toBeEditedCount} records have been edited.`);

        this.preventLoadMoreOnScroll = true;
        for (var i = 1; i <= this.internalCurrentPage; i++) {
          await this.getTestResults(i != 1, i);
        }
        this.preventLoadMoreOnScroll = false;
      }
    },
    isRowSelected(testResultKey) {
      return this.selectedRows.indexOf(testResultKey) >= 0;
    },
    selectRow(testResultKey) {
      this.selectedRows.push(testResultKey);
    },
    unselectRow(testResultKey) {
      this.selectedRows.splice(this.selectedRows.indexOf(testResultKey), 1);
    },
    selectAllRows() {
      this.selectedRows = [];
      this.testResults.forEach((value) => {
        this.selectedRows.push(value.testResultKey);
      });
    },
    clearSelected() {
      this.selectedRows = [];
    },
    getSearchTokens() {
      this.filterTokens = [];
      const format = 'MM/dd/yyyy';

      if (this.filterObject.isAnnual == true) this.filterTokens.push('Annual');
      if (this.filterObject.isNormal == true) this.filterTokens.push('Normal');
      if (this.filterObject.scoutingCombine == true) this.filterTokens.push('Scouting Combine');
      if (this.filterObject.preEmployment == true) this.filterTokens.push('Pre Employment');
      if (this.filterObject.gameOfficial == true) this.filterTokens.push('Game Official');
      if (this.filterObject.invalidResult == true) this.filterTokens.push('Invalid Result');
      if (this.filterObject.blindTest == true) this.filterTokens.push('Blind Test');
      if (this.filterObject.specimenSubstituted == true) this.filterTokens.push('Specimen Substituted');
      if (this.filterObject.actedUpon == true) this.filterTokens.push('Acted Upon');
      if (this.filterObject.actedUpon == false) this.filterTokens.push('Not Acted Upon');
      if (this.filterObject.isPositive == true) this.filterTokens.push('Positive');
      if (this.filterObject.isPositive == false) this.filterTokens.push('Negative');
      if (this.filterObject.matched == true) this.filterTokens.push('Matched');
      if (this.filterObject.matched == false) this.filterTokens.push('Unmatched');
      if (this.filterObject.cocNumbers != null && this.filterObject.cocNumbers.length > 0) {
        var cNums = '';
        this.filterObject.cocNumbers.forEach((o) => {
          cNums += (cNums.length > 0 ? ', ' : '') + o;
        });
        if (cNums.length > 0) this.filterTokens.push(cNums);
      }
      //Collection Date
      if (this.filterObject.collectionDateFrom != null && this.filterObject.collectionDateTo != null)
        this.filterTokens.push(
          'Collection: ' +
            DateTime.fromISO(this.filterObject.collectionDateFrom).toFormat(format) +
            ' - ' +
            DateTime.fromISO(this.filterObject.collectionDateTo).toFormat(format)
        );
      else if (this.filterObject.collectionDateFrom != null && this.filterObject.collectionDateTo == null)
        this.filterTokens.push('Collection From: ' + DateTime.fromISO(this.filterObject.collectionDateFrom).toFormat(format));
      else if (this.filterObject.collectionDateFrom == null && this.filterObject.collectionDateTo != null)
        this.filterTokens.push('Collection To: ' + DateTime.fromISO(this.filterObject.collectionDateTo).toFormat(format));
      //Import Date
      if (this.filterObject.importDateFrom != null && this.filterObject.importDateTo != null)
        this.filterTokens.push(
          'Import: ' +
            DateTime.fromISO(this.filterObject.importDateFrom).toFormat(format) +
            ' - ' +
            DateTime.fromISO(this.filterObject.importDateTo).toFormat(format)
        );
      else if (this.filterObject.importDateFrom != null && this.filterObject.importDateTo == null)
        this.filterTokens.push('Import From: ' + DateTime.fromISO(this.filterObject.importDateFrom).toFormat(format));
      else if (this.filterObject.importDateFrom == null && this.filterObject.importDateTo != null)
        this.filterTokens.push('Import To: ' + DateTime.fromISO(this.filterObject.importDateTo).toFormat(format));
    },
    matchHeight() {
      if (this.$refs.tableFiltersHead) {
        var heightString = `calc(100% - ${this.$refs.tableFiltersHead.clientHeight + 2}px)`;
        this.triggerHeight = { height: heightString };
      } else
        setTimeout(() => {
          this.matchHeight();
        }, 1000);
    }
  }
};
</script>
