<template>
  <div id="homePane">
    <div>
      <HomeNotTested></HomeNotTested>
      <HomeUnmatchedLabResults ref="homeUnmatchedRef"></HomeUnmatchedLabResults>
    </div>
    <div>
      <HomeEvents v-if="eventLookupsReady" ref="homeEventsRef"></HomeEvents>
    </div>
    <LabResultModal :test-result-key="selectedTestResultKey" @substancesEdited="refetchLabResults"></LabResultModal>
  </div>
</template>
<script>
import HomeNotTested from '../components/home/HomeNotTested';
import HomeUnmatchedLabResults from '../components/home/HomeUnmatchedLabResults';
import HomeEvents from '../components/home/HomeEvents';
import LabResultModal from '@/components/modals/LabResultModal';

export default {
  components: {
    HomeNotTested,
    HomeUnmatchedLabResults,
    HomeEvents,
    LabResultModal
  },
  methods: {
    refetchLabResults() {
      this.$refs.homeUnmatchedRef.refreshLabResults();
      this.$refs.homeEventsRef.refreshLabResults();
    }
  }
};
</script>
