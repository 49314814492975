<template>
  <DraggableModal
    id="lab-result-modal"
    title="Lab Result"
    size="xl"
    confirm-hide
    :has-edits="showSave"
    :respond-to-escape="!askingForSaveConfirmationBeforePrint"
    @hidden="clearSaveStatus">
    <b-alert ref="errorMessageRef" :show="!!errorInfo" variant="danger">{{ errorInfo }}</b-alert>
    <LabResultDetails v-if="testResult" :test-result="testResult"></LabResultDetails>
    <template #modal-footer>
      <div class="w-100">
        <div class="d-modal-buttons">
          <template v-if="showSave">
            <b-button v-b-modal.confirm-before-print-modal variant="info">Printable Version</b-button>
            <b-button variant="secondary" @click="closeModal">Cancel</b-button>
            <b-button variant="primary" :disabled="saveInProgress" @click="saveSubstanceChanges">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
          </template>
          <template v-else>
            <b-button
              variant="info"
              :to="{ name: 'LabResultPrintable', params: { testResultKey: testResultKey } }"
              target="_blank"
              rel="noopener noreferrer">
              Printable Version
            </b-button>
            <b-button variant="primary" @click="closeModal">Close</b-button>
          </template>
        </div>
      </div>
    </template>
    <b-modal
      id="confirm-before-print-modal"
      title="Save Changes?"
      no-close-on-backdrop
      @show="askingForSaveConfirmationBeforePrint = true"
      @hide="onConfirmPrintHide">
      Changes have been made and will not be reflected on the Printable View. Do you wish to save prior to viewing?
      <template #modal-footer="{ hide }">
        <b-button variant="secondary" @click="hide()">Cancel</b-button>
        <b-button variant="danger" @click="showPrintableView(false)">No</b-button>
        <b-button variant="primary" @click="showPrintableView(true)">Yes</b-button>
      </template>
    </b-modal>
    <BlockUI v-if="saveInProgress" message="Saving in progress...">
      <font-awesome-icon icon="spinner" size="4x" spin />
    </BlockUI>
  </DraggableModal>
</template>

<script>
import axios from 'axios';
import LabResultDetails from '@/components/sharedBusiness/LabResultDetails';

export default {
  components: {
    LabResultDetails
  },
  props: {
    testResultKey: { type: Number, required: false, default: null }
  },
  data() {
    return {
      isBusy: true,
      testResult: null,
      saveInProgress: false,
      errorInfo: null,
      askingForSaveConfirmationBeforePrint: false
    };
  },
  computed: {
    showSave() {
      return this.$store.getters.medicallyJustifiableChanges.length > 0;
    }
  },
  watch: {
    async testResultKey(newValue) {
      if (!newValue) {
        return;
      }

      await this.getLabResults();
    }
  },
  methods: {
    async getLabResults() {
      this.testResult = null;
      let queryURL = '/api/TestResult/GetLabResult';
      let queryObject = { testResultKey: this.testResultKey };

      const response = await axios.get(queryURL, { params: queryObject });

      this.testResult = response.data;
    },
    async saveSubstanceChanges() {
      this.saveInProgress = true;
      try {
        await this.$store.dispatch('saveSubstanceChanges', this.testResultKey);
        this.makeToast('Substances have been edited.');
        this.$emit('substancesEdited');
      } catch (error) {
        this.errorInfo = 'There was an error saving substances.';
      } finally {
        this.saveInProgress = false;
      }
    },
    async showPrintableView(saveFirst) {
      if (saveFirst) {
        await this.saveSubstanceChanges();
      }

      const routeData = this.$router.resolve({ name: 'LabResultPrintable', params: { testResultKey: this.testResultKey } });
      window.open(routeData.href, '_blank');
      this.$bvModal.hide('confirm-before-print-modal');
    },
    closeModal() {
      this.$modal.hide('lab-result-modal');
    },
    clearSaveStatus() {
      this.$store.commit('setMedicallyJustifiableChanges', []);
    },
    onConfirmPrintHide() {
      setTimeout(() => {
        this.askingForSaveConfirmationBeforePrint = false;
      }, 100);
    }
  }
};
</script>
<style scoped>
.result-summary-section {
  display: flex;
  flex-direction: column;
  height: 100px;
  flex-wrap: wrap;
}
</style>
