<template>
  <DraggableModal id="case-event-email-modal" title="Email Case Event" size="lg" @show="initializeValues">
    <div v-if="caseEvent">
      <h3>Case Event</h3>
      <section>
        <div>
          <div class="key-value">
            <label>Case Event Date:</label>
            {{ caseEvent.caseEventDate | dateTime }}
          </div>
          <div class="key-value">
            <label>Event Type:</label>
            {{ caseEvent.eventType }}
          </div>
          <div class="key-value">
            <label>Event:</label>
            {{ caseEvent.event }}
          </div>
          <div class="key-value">
            <label>Event Reason:</label>
            {{ caseEvent.eventReason }}
          </div>
          <div class="key-value">
            <label>Event Sub Reason:</label>
            {{ caseEvent.eventSubReason }}
          </div>
        </div>

        <div>
          <div class="key-value">
            <label>COC Number:</label>
            {{ caseEvent.cocNumber }}
          </div>
          <div class="key-value">
            <label>Alcohol Abstention:</label>
            {{ caseEvent.alcoholAbstention | yesNo }}
          </div>
          <div class="key-value">
            <label>B Bottle Tested:</label>
            {{ caseEvent.bbottleTested | yesNo }}
          </div>
          <div class="key-value">
            <label>Dilute Specimen:</label>
            {{ caseEvent.diluteSpecimen | yesNo }}
          </div>
          <div class="key-value">
            <label>Positive Test:</label>
            {{ caseEvent.isPositive | yesNo }}
          </div>
        </div>
      </section>

      <section v-if="hasEmailAddress || caseEvent.comments" class="vert">
        <hr />
        <div v-if="hasEmailAddress" class="key-value">
          <label>Subject Email Address:</label>
          {{ subject.subjectContactInfo.email }}
        </div>
        <div v-if="caseEvent.comments" class="key-value">
          <label>Comments:</label>
          {{ caseEvent.comments }}
        </div>
      </section>

      <div v-if="documentsInternal && documentsInternal.length > 0">
        <h3>Select Documents to Include</h3>
        <b-table striped hover small :items="documentsInternal" :fields="documentFields" :sort-desc.sync="sortDesc" :sort-by.sync="sortBy" foot-clone>
          <template #head()="{ label, field }">
            <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
          </template>
          <template #cell(documentName)="data">
            <a :href="`/api/Document/CaseEventDocument/${data.item.caseEventDocumentKey}`" target="_blank">{{ data.item.documentName }}</a>
          </template>
          <template #cell(includeInEmail)="data">
            <b-form-checkbox v-model="data.item.includeInEmail"></b-form-checkbox>
          </template>
        </b-table>
      </div>

      <div v-if="caseEvent.emailsSent.length > 0">
        <hr />
        <h3 v-b-toggle.previous-emails-collapse>
          Previous Emails
          <span class="collapse-opened"><font-awesome-icon icon="caret-down" /></span>
          <span class="collapse-closed"><font-awesome-icon icon="caret-left" /></span>
        </h3>
        <b-collapse id="previous-emails-collapse" visible>
          <ul>
            <li v-for="email in caseEvent.emailsSent" :key="email.caseEventEmailKey">
              Email sent at: {{ email.emailDate | dateTime }} to {{ email.toEmailAddress }}
              <ul v-if="email.documentsSent.length > 0">
                <li v-for="document in email.documentsSent" :key="document.caseEventDocumentKey">{{ document.documentName }}</li>
              </ul>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>

    <div v-if="!hasEmailAddress">
      <hr />
      <b-alert show variant="danger">There is no email address on file for this subject.</b-alert>
    </div>

    <b-alert :show="!!emailError" variant="danger">{{ emailError }}</b-alert>

    <template #modal-footer>
      <div class="w-100">
        <div class="d-modal-buttons">
          <b-button variant="primary" :disabled="!readyToSend" @click="sendEmail">
            Send Email
            <font-awesome-icon v-show="emailSendInProgress" icon="spinner" spin />
          </b-button>
          <b-button variant="secondary" @click="$modal.hide('case-event-email-modal')">Close</b-button>
        </div>
      </div>
    </template>
  </DraggableModal>
</template>
<script>
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  props: {
    caseEvent: { type: Object, required: false, default: null }
  },
  data() {
    return {
      documentsInternal: null,
      documentFields: [
        { key: 'documentName', label: 'File Name', sortable: true },
        { key: 'includeInEmail', label: 'Include?' }
      ],
      emailSendInProgress: false,
      emailError: null,
      sortBy: null,
      sortDesc: true
    };
  },
  computed: {
    hasEmailAddress() {
      return !!this.subject.subjectContactInfo?.email;
    },
    readyToSend() {
      if (this.emailSendInProgress) {
        return false;
      }

      if (!this.hasEmailAddress) {
        return false;
      }

      if (!this.documentsInternal) {
        return false;
      }

      const documentsToInclude = this.documentsInternal.filter((x) => x.includeInEmail);
      if (documentsToInclude.length === 0) {
        return false;
      }

      return true;
    }
  },
  watch: {
    caseEvent() {
      this.initializeValues();
    }
  },
  created() {
    this.initializeValues();
  },
  methods: {
    initializeValues() {
      this.emailError = false;
      if (!this.caseEvent) {
        return;
      }

      this.documentsInternal = _.cloneDeep(this.caseEvent.caseEventDocuments).map((x) => ({ ...x, includeInEmail: false }));
    },
    async sendEmail() {
      const documentsToInclude = this.documentsInternal.filter((x) => x.includeInEmail);
      const documentKeys = documentsToInclude.map((x) => x.caseEventDocumentKey);
      const params = {
        subjectKey: this.subject.subjectKey,
        caseEventKey: this.caseEvent.caseEventKey,
        caseEventDocumentKeys: documentKeys
      };
      this.emailSendInProgress = true;
      try {
        await axios.post('/api/Email/SendCaseEventEmail', params);
        this.$modal.hide('case-event-email-modal');
        this.makeToast('Email Sent.');
        this.$emit('caseEventEmailSent');
      } catch (error) {
        this.emailError = error.response.data;
      } finally {
        this.emailSendInProgress = false;
      }
    }
  }
};
</script>
