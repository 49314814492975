import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import { routes } from './routes';

Vue.use(VueRouter);

let router = new VueRouter({
  scrollBehavior(to, from) {
    if (to.hash) {
      const smoothParams = {
        selector: to.hash,
        behavior: 'smooth'
      };

      if (!to.meta.delayScroll || to.path === from.path) {
        return smoothParams;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(smoothParams);
        }, 500);
      });
    }
    return { x: 0, y: 0 }; // Go to the top of the page if no hash
  },
  mode: 'history',
  routes
});

router.beforeEach(async function (toRoute, fromRoute, next) {
  if (fromRoute.name !== 'SubjectDetails' && toRoute.name === 'SubjectDetails' && !toRoute.params.generalLink) {
    store.commit('setArrivedAtSubjectDetailsFromRoute', fromRoute);
  }

  await store.dispatch('getUserInfo');
  if (!store.getters.isAuth && toRoute.name === 'Login') {
    return next();
  }

  if (store.getters.authFailed) {
    if (toRoute.name === 'AccessDenied') {
      return next();
    } else {
      return next({ name: 'AccessDenied' });
    }
  }

  if (!store.getters.isAuth) {
    const allowFakeLogin = process.env.VUE_APP_FAKE_LOGIN_ENABLE === 'true';

    if (toRoute.path === '/') {
      if (!allowFakeLogin) {
        window.location.href = '/Login/Login';
        return;
      }

      return next({ name: 'Login' });
    }

    if (toRoute.meta.isPublic) {
      return next();
    }

    if (!allowFakeLogin) {
      window.location.href = '/Login/Login';
      return;
    }

    return next({ name: 'Login' });
  }

  if (toRoute.meta.publicOnly) {
    if (store.getters.isSA) {
      return next({ name: 'Home' });
    }
    if (store.getters.isDPA) {
      return next({ name: 'CList' });
    }

    return next({ name: 'AccessDenied' });
  }

  if (store.getters.isSA) {
    if (!toRoute.meta.allowedRoles || toRoute.meta.allowedRoles.includes('SA')) {
      return next();
    } else {
      return next({ name: 'Home' });
    }
  }

  if (store.getters.isDPA) {
    if (!toRoute.meta.allowedRoles || toRoute.meta.allowedRoles.includes('DPA')) {
      return next();
    } else {
      return next({ name: 'CList' });
    }
  }
});

router.afterEach((toRoute) => {
  if (toRoute.meta.title) {
    document.title = toRoute.meta.title + ' - NFL SoA';
  } else {
    document.title = 'NFL SoA';
  }
  store.commit('setCurrentRoute', toRoute);
  store.dispatch('storeRouteState');
});

export default router;
