<template>
  <div class="input-group">
    <b-form-input
      id="top-nav-search-input"
      v-model="query"
      size="sm"
      class="search-input"
      placeholder="Search Subject Name/Key or COC #"
      @input="fetchResults"
      @click="onClick"></b-form-input>
    <b-button size="sm" class="my-2 my-sm-0 inputButton" type="submit">
      <font-awesome-icon icon="search" />
    </b-button>
    <b-popover :show.sync="showPopover" custom-class="top-nav-search-popover" target="top-nav-search-input" placement="bottom" triggers="manual blur">
      <div>
        <template v-if="matchingSubjectsToShow.length > 0">
          <h6>Matching Subjects In Program ({{ matchingSubjects.length }})</h6>
          <ul class="search-results-list">
            <li v-for="subject in matchingSubjectsToShow" :key="subject.subjectKey">
              <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: subject.subjectKey } }" @click.native="closePopover">
                {{ subject.name }}
              </router-link>
            </li>
          </ul>
        </template>
        <h6 v-if="matchingSubjectsToShow.length === 0">No subjects in program matching '{{ query }}'</h6>

        <hr />
        <ul class="search-results-list">
          <li v-if="isSA" v-show="matchingSubjects.length > 0">
            <router-link :to="{ name: 'Subjects', query: getSubjectQueryObject('yes') }" @click.native="closePopover">
              See all {{ matchingSubjects.length }} program subjects matching '{{ query }}'
            </router-link>
          </li>
          <li v-if="isSA">
            <router-link :to="{ name: 'Subjects', query: getSubjectQueryObject('any') }" @click.native="closePopover">
              See any subject matching '{{ query }}'
            </router-link>
          </li>
          <li v-if="isSA">
            <router-link
              :to="{ name: 'LabResults', query: { cocNumber: cocQuery, actedUpon: 'any', blindTest: 'any', isNormal: 'any', matched: 'any' } }"
              @click.native="closePopover">
              <template v-if="cocQuery.length === 1">Search for COC # '{{ query }}' (Lab Result)</template>
              <template v-if="cocQuery.length > 1">Search for multiple COC #s (Lab Result)</template>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CollectionSearch', query: { cocNumber: cocQuery, matched: 'any' } }" @click.native="closePopover">
              <template v-if="cocQuery.length === 1">Search for COC # '{{ query }}' (Collection)</template>
              <template v-if="cocQuery.length > 1">Search for multiple COC #s (Collection)</template>
            </router-link>
          </li>
        </ul>
      </div>
    </b-popover>
  </div>
</template>
<script>
export default {
  data() {
    return {
      query: '',
      matchingSubjects: [],
      showPopover: false
    };
  },
  computed: {
    matchingSubjectsToShow() {
      return this.matchingSubjects.slice(0, 5);
    },
    cocQuery() {
      return this.splitCOCNumberString(this.query);
    }
  },
  watch: {
    $route() {
      this.showPopover = false;
      this.query = '';
    }
  },
  methods: {
    onClick() {
      if (this.query) {
        this.showPopover = true;
      }
    },
    closePopover() {
      this.showPopover = false;
    },
    fetchResults() {
      if (!this.query) {
        this.showPopover = false;
        return;
      }

      this.matchingSubjects = this.searchSubjectsInProgram(this.query);
      this.showPopover = true;
    },
    getSubjectQueryObject(isInProgram) {
      const queryIsNumber = !isNaN(this.query);
      if (queryIsNumber) {
        return { subjectKey: this.query, isInProgram: isInProgram };
      }
      return { subjectName: this.query, isInProgram: isInProgram };
    },
    splitCOCNumberString(cocNumber) {
      return cocNumber.split(/[\s,]+/);
    }
  }
};
</script>
<style scoped>
.search-results-list {
  list-style: none;
  padding-left: 0;
}

.search-results-list li {
  margin-bottom: 5px;
  padding: 10px;
}

.search-results-list li:hover {
  background-color: #e2e5e9;
}
</style>
<style>
.top-nav-search-popover.bs-popover-bottom {
  margin-top: 0px;
}
.top-nav-search-popover .arrow {
  visibility: hidden;
}
</style>
