<template>
  <span v-b-popover.hover.focus.top="field.popover">
    {{ label }}
    <template v-if="sortable">
      <font-awesome-icon v-if="sortBy !== key" icon="sort" class="no-print" />
      <font-awesome-icon v-else-if="sortDesc" icon="sort-down" />
      <font-awesome-icon v-else icon="sort-up" />
    </template>
  </span>
</template>
<script>
export default {
  props: {
    label: { type: String, required: true },
    field: { type: Object, required: true },
    sortBy: { type: String, required: false, default: null },
    sortDesc: { type: Boolean, required: true }
  },
  computed: {
    sortable() {
      return this.field.sortable;
    },
    key() {
      return this.field.key;
    }
  }
};
</script>
