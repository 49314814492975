<template>
  <b-card id="notTestedTable" header-tag="header">
    <template #header>
      <div class="search-card-header">
        <span>Players not tested (Days)</span>
        <b-button variant="primary" class="print-button" @click="print('playersNotTested', printHeaderText)">
          <font-awesome-icon icon="print" size="lg" />
        </b-button>
        <div class="tableHeaderSlider">
          <b-form-radio-group class="radioGroup">
            <b-form-radio
              v-for="range in dateRanges"
              :key="range"
              v-model="playersNotTestedDays"
              :value="range"
              :class="{ active: playersNotTestedDays == range }">
              {{ range }}
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
    </template>
    <div id="playersNotTested">
      <b-table
        v-if="!isBusy"
        borderless
        hover
        foot-clone
        :items="playersNotTested"
        :fields="playersNotTestFields"
        :sort-desc.sync="sortDesc"
        :sort-by.sync="sortBy">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(name)="data">
          <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: data.item.subjectKey } }">
            {{ data.item.name }}
          </router-link>
        </template>
        <template #cell(lastCollection)="data">
          {{ data.item.lastCollection | date }}
        </template>
        <template #cell(phone)="data">
          <span v-if="data.item.phone" :aria-label="data.item.phone" data-balloon-pos="left" class="icon actionIcon">
            <font-awesome-icon icon="square-phone-flip" size="lg" />
          </span>
        </template>
        <template #cell(email)="data">
          <span v-if="data.item.email" :aria-label="data.item.email" data-balloon-pos="left" class="icon actionIcon" @click="mailTo(data.value)">
            <font-awesome-icon icon="square-envelope" size="lg" />
          </span>
        </template>
      </b-table>
      <div v-if="isBusy" class="spinner-container">
        <font-awesome-icon icon="spinner" spin size="4x" />
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from 'axios';
import VuexFunctions from '@/functions/VuexFunctions';
import GlobalFunctions from '@/functions/GlobalFunctions';

export default {
  data() {
    return {
      dateRanges: ['7', '15', '30', '45', '90', '180'],
      isBusy: true,
      playersNotTested: [],
      totalRows: 0,
      playersNotTestFields: [
        { key: 'name', label: 'Subject', sortable: true },
        { key: 'testFrequency', label: 'Freq', sortable: true, class: 'centerAlign' },
        { key: 'lastCollection', label: 'Last Collection', sortable: true },
        { key: 'phone', label: '', sortable: false, class: 'centerAlign' },
        { key: 'email', label: '', sortable: false, class: 'centerAlign' }
      ]
    };
  },
  computed: {
    playersNotTestedDays: VuexFunctions.mapComputed('home', 'playersNotTestedDays'),
    sortBy: VuexFunctions.mapComputed('home', 'playersNotTestedSortBy'),
    sortDesc: VuexFunctions.mapComputed('home', 'playersNotTestedSortDesc'),
    printHeaderText() {
      return 'Players Not Tested in ' + this.playersNotTestedDays + ' days';
    }
  },
  watch: {
    async playersNotTestedDays() {
      await this.getPlayersNotTested();
    }
  },
  async created() {
    await this.getPlayersNotTested();
  },
  methods: {
    async getPlayersNotTested() {
      this.isBusy = true;
      this.playersNotTested = [];
      let queryURL = '/api/Subject/GetPlayersNotTested';

      const queryObject = {
        notTestedInDays: this.playersNotTestedDays
      };
      const response = await axios.get(queryURL, {
        params: queryObject
      });
      this.playersNotTested = response.data;
      this.totalRows = this.playersNotTested.length;
      this.isBusy = false;
    },
    mailTo(v) {
      window.open(`mailto:${v}`, '_blank');
    },
    print(element, headerText) {
      GlobalFunctions.printSection(element, headerText);
    }
  }
};
</script>
