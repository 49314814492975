import { mapState } from 'vuex';

let subjectDetailsMixin = {
  computed: {
    ...mapState({
      subject: (state) => state.subjectDetails.subject,
      selectedSeason: (state) => state.subjectDetails.selectedSeason,
      assignments: (state) => state.subjectDetails.assignments,
      usStates: (state) => state.subjectDetails.usStates
    })
    // ,
    // previousUntilFurtherNoticeLocationExists() {
    //   if (!this.subject) {
    //     return false;
    //   }
    //   const untilFurtherNoticeLocations = this.subject.locations.filter((x) => !x.endDate);
    //   return untilFurtherNoticeLocations.length > 0;
    // }
  },
  methods: {
    clearSubjectDetails() {
      this.$store.dispatch('clearSubjectDetails');
    },
    async initializeSubjectDetails(subjectKey) {
      await this.$store.dispatch('initializeSubjectDetails', subjectKey);
    },
    async getSubject() {
      await this.$store.dispatch('getSubject');
    },
    async getAssignments() {
      await this.$store.dispatch('getAssignments');
    }
  }
};

export default subjectDetailsMixin;
