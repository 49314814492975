<template>
  <div id="cListPane">
    <b-card header-tag="header">
      <template #header>
        <div class="search-card-header">
          <h6 class="header-text">Assigned Collections for {{ getReadableYearMonth(headerYearMonth) }}</h6>

          <b-button
            v-if="viewingCurrentMonth && searchResponse.newAdmissionCount"
            variant="warning"
            :class="{ active: highlightingNewAdmissions }"
            @click="highlightingNewAdmissions = !highlightingNewAdmissions">
            <font-awesome-icon icon="circle-exclamation" size="lg" />
            <span v-if="searchResponse.newAdmissionCount === 1">{{ searchResponse.newAdmissionCount }} New Admission</span>
            <span v-if="searchResponse.newAdmissionCount > 1">{{ searchResponse.newAdmissionCount }} New Admissions</span>
          </b-button>

          <div class="search-options no-print">
            <b-form-input v-model.number="queryObject.year" type="number" class="search-text small"></b-form-input>
            <b-form-select v-model="queryObject.month" :options="monthOptions" class="search-select"></b-form-select>

            <b-button variant="primary" @click="pushNewSearch">
              <font-awesome-icon icon="search" size="lg" />
              <span>Search</span>
            </b-button>
            <b-button variant="primary" class="print-button" @click="print">
              <font-awesome-icon icon="print" size="lg" />
            </b-button>
          </div>
        </div>
      </template>
      <div v-show="isBusy" class="spinner-container">
        <font-awesome-icon icon="spinner" spin size="4x" />
      </div>
      <b-table
        v-if="!isBusy"
        striped
        hover
        small
        foot-clone
        show-empty
        :emptyText="`No collections found for ${getReadableYearMonth(currentSearchYearMonth)}.`"
        :items="cListEntries"
        :fields="cListFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @sort-changed="sortChanged">
        <template #head()="{ label, field }">
          <TableSortIcons :label="label" :field="field" :sort-by="sortBy" :sort-desc="sortDesc"></TableSortIcons>
        </template>
        <template #cell(subjectName)="data">
          <router-link :to="{ name: 'SubjectDetails', params: { subjectKey: data.item.subjectKey } }">
            {{ data.item.subjectName }}
          </router-link>
        </template>
        <template #cell(admissionDate)="data">
          {{ data.item.admissionDate | date }}
        </template>
        <template #cell(currentAddress)="data">
          <div>
            {{ joinValidStrings(', ', data.item.currentAddressOne, data.item.currentAddressTwo) }}
          </div>
          <div>
            {{ joinValidStrings(', ', data.item.currentCity, data.item.currentState, data.item.currentZip) }}
          </div>
          <div v-if="data.item.startDate != null && data.item.startDate.length > 0" class="key-value">
            <label>From:&nbsp;</label>
            <template v-if="data.item.endDate != null">{{ data.item.startDate | dateTime }} to {{ data.item.endDate | dateTime }}</template>
            <template v-if="data.item.endDate == null">{{ data.item.startDate | dateTime }} {{ 'to  Until further notice' }}</template>
          </div>
          <span class="key-value primary" aria-label="Primary Phone Number" data-balloon-pos="right">
            {{ data.item.currentPrimaryNumber }}
            <font-awesome-icon v-if="data.item.currentPrimaryNumber" icon="fa-sharp fa-solid fa-star" size="xs" />
          </span>
          <div v-if="data.item.currentAlternateNumber != null && data.item.currentAlternateNumber.length > 0" class="key-value">
            {{ data.item.currentAlternateNumber }}
          </div>
        </template>
        <template #cell(clubInfo)="data">
          <div
            class="clubIcon no-print"
            :class="[{ fa: tooltipPosition(data.item.currentClub, data.item.subjectTypeName) }, 'team' + data.item.currentClub]"
            data-balloon-pos="up"
            :aria-label="teamTooltip(data.item.currentClub, data.item.subjectTypeName)"></div>
          <div class="print-only">{{ data.item.currentClub == null ? 'FA' : data.item.currentClub }}</div>
        </template>
        <template #cell(positionInfo)="data">
          {{ data.item.position }}
        </template>
        <template #cell(protocols)="data">
          <font-awesome-icon v-if="data.item.teamFacilityProtocol" v-b-popover.hover.focus="'Team Facility Protocol'" icon="building" />
          <font-awesome-icon v-if="data.item.homeResidenceProtocol" v-b-popover.hover.focus="'Home Residence Protocol'" icon="house" />
          <font-awesome-icon v-if="data.item.hotelProtocol" v-b-popover.hover.focus="'Hotel Protocol'" icon="hotel" />
          <font-awesome-icon v-if="data.item.otherProtocol" v-b-popover.hover.focus="'Other Protocol'" icon="question-circle" />
        </template>
      </b-table>
      <!-- <b-alert v-if="currentSearchYearMonth && !isBusy && cListEntries.length === 0" variant="info" show>
        No entries found for {{ getReadableYearMonth(currentSearchYearMonth) }}.
      </b-alert> -->
      <template v-if="!isBusy && searchResponse" #footer>
        <div class="footer">
          <div class="footer-buttons">
            <CountBadge variant="success" text="Collections" :count="searchResponse.collectionCount"></CountBadge>
            <CountBadge variant="warning" text="Frequency" :count="searchResponse.testFrequencyCount"></CountBadge>
            <CountBadge variant="primary" text="Assignments" :count="searchResponse.assignmentCount"></CountBadge>
          </div>
          <div v-if="viewingCurrentMonth">As of {{ searchResponse.dataDateTime | dateTime }}</div>
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import GlobalFunctions from '@/functions/GlobalFunctions';

export default {
  data() {
    return {
      queryObject: null,
      defaultQuery: {
        year: DateTime.now().toObject().year,
        month: DateTime.now().toObject().month
      },
      defaultPageControl: {
        sortBy: 'subjectName',
        sortDesc: false
      },
      fieldAnnotations: {
        year: 'Number',
        month: 'Number',
        sortDesc: 'Boolean'
      },
      currentSearchYearMonth: null,
      isBusy: true,
      searchResponse: null,
      cListFields: [
        { key: 'clubInfo', label: 'Club', class: 'centerAlign' },
        { key: 'positionInfo', label: 'Pos', class: 'centerAlign' },
        { key: 'subjectName', label: 'Subject Name', sortable: true },
        { key: 'subjectKey', label: 'Subj Key', sortable: true },
        { key: 'playerCurrentStatus', label: 'Current Status', sortable: true },
        { key: 'admissionDate', label: 'Admission Date', sortable: true },
        { key: 'currentAddress', label: 'Current Address' },
        { key: 'testFrequency', label: 'Test Freq', sortable: true, class: 'centerAlign' },
        { key: 'numberOfCollections', label: 'Collections', sortable: true, class: 'centerAlign' },
        { key: 'numberOfDilutes', label: 'Dilutes', sortable: true, class: 'centerAlign' },
        // { key: 'missedCollections', label: '# Missed', sortable: true, class: 'centerAlign' },
        { key: 'protocols', label: 'Test Location' },
        { key: 'dataSource', label: 'Data Source', sortable: true }
      ],
      highlightingNewAdmissions: false,
      sortBy: 'subjectName',
      sortDesc: false
    };
  },
  computed: {
    cListEntries() {
      if (!this.searchResponse || !this.searchResponse.entries) {
        return [];
      }
      if (!this.highlightingNewAdmissions) {
        return this.searchResponse.entries;
      }

      return this.searchResponse.entries.map((x) => ({ ...x, _rowVariant: x.newAdmission ? 'info' : null }));
    },
    viewingCurrentMonth() {
      if (!this.currentSearchYearMonth) {
        return false;
      }
      const now = DateTime.now().toObject();
      return this.currentSearchYearMonth.year === now.year && this.currentSearchYearMonth.month === now.month;
    },
    headerYearMonth() {
      if (this.currentSearchYearMonth) {
        return this.currentSearchYearMonth;
      }
      return this.queryObject;
    }
  },
  watch: {
    async $route(to, from) {
      this.initializeQueryFromURL();
      if (to.query.year === from.query.year && to.query.month === from.query.month) {
        return;
      }
      await this.getCList();
    }
  },
  async created() {
    this.initializeQueryFromURL();
    await this.getCList();
  },
  methods: {
    initializeQueryFromURL() {
      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = GlobalFunctions.convertRouteObjectToQueryObject(this.$route.query, combinedDefaults, this.fieldAnnotations);
      this.queryObject = {
        year: fullQueryObject.year,
        month: fullQueryObject.month
      };

      this.sortBy = fullQueryObject.sortBy;
      this.sortDesc = fullQueryObject.sortDesc;
    },
    async sortChanged(context) {
      if (!context.sortBy) {
        return;
      }
      this.sortBy = context.sortBy;
      this.sortDesc = context.sortDesc;
      await this.pushNewSearch(true);
    },
    async pushNewSearch(replace) {
      const combinedDefaults = { ...this.defaultQuery, ...this.defaultPageControl };
      const fullQueryObject = { ...this.queryObject, sortBy: this.sortBy, sortDesc: this.sortDesc };
      const routeQueryObject = GlobalFunctions.convertQueryObjectToRouteObject(fullQueryObject, combinedDefaults, this.fieldAnnotations);

      if (!_.isEqual(routeQueryObject, this.$route.query)) {
        if (replace) {
          this.$router.replace({ name: 'CList', query: routeQueryObject });
        } else {
          this.$router.push({ name: 'CList', query: routeQueryObject });
        }
      } else {
        await this.getCList();
      }
    },
    async getCList() {
      this.isBusy = true;
      const response = await axios.get('/api/Specimen/GetCList', { params: this.queryObject });
      this.currentSearchYearMonth = _.cloneDeep(this.queryObject);
      this.searchResponse = response.data;
      this.isBusy = false;
    },
    teamTooltip(clubCode, role) {
      let n = '';
      switch (clubCode) {
        case 'FA':
        case null:
          n = 'Free Agent';
          break;
        case '':
          n = role;
          break;
        default:
          n = clubCode;
          break;
      }
      return n;
    },
    tooltipPosition(club, role) {
      let n = false;
      if (!club || club == null || club == 'FA' || role == 'NFL Official' || role == 'NFL Employee') n = true;
      return n;
    },
    print() {
      window.print();
    }
  }
};
</script>
<style scoped>
.header-text {
  width: 200px;
}
.search-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-text {
  width: 200px;
}

.search-select {
  width: 200px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer-buttons {
  display: flex;
  gap: 5px;
}
</style>
