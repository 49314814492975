<template>
  <ValidationObserver v-slot="{ invalid }" ref="newAssignmentValidationRef">
    <DraggableModal
      id="new-assignment-modal"
      ref="newAssignmentModalRef"
      size="md"
      title="New Assignment"
      confirm-hide
      :has-edits="hasEdits"
      @hidden="initializeValues">
      <b-alert :show="!!errorInfo" variant="danger" dismissible>{{ errorInfo }}</b-alert>
      <b-form v-if="newAssignment" @submit.prevent>
        <div class="flex-form two-col">
          <b-form-group label-for="year">
            <template #label>
              <span class="text-danger">*</span>
              Year
            </template>
            <ValidationProvider v-slot="{ errors }" mode="lazy" name="Year" rules="required|numeric|min_value:2015|length:4">
              <span class="text-danger">{{ errors[0] }}</span>
              <b-form-input id="year" v-model="newAssignment.season"></b-form-input>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label-for="month">
            <template #label>
              <span class="text-danger">*</span>
              Month
            </template>
            <ValidationProvider v-slot="{ errors }" name="Month" rules="required">
              <span class="text-danger">{{ errors[0] }}</span>
              <b-form-select id="month" v-model="newAssignment.monthNumber" :options="monthOptions"></b-form-select>
            </ValidationProvider>
          </b-form-group>
        </div>
        <b-form-group v-show="false" label="DPA" label-for="dpa">
          <b-form-select id="dpa" v-model="presetDPA" :options="[presetDPA]" disabled></b-form-select>
        </b-form-group>
        <div class="flex-form two-col">
          <b-form-group label-for="test-frequency">
            <template #label>
              <span class="text-danger">*</span>
              Test Frequency
            </template>
            <ValidationProvider v-slot="{ errors }" name="Test Frequency" rules="required|numeric|min_value:1">
              <span class="text-danger">{{ errors[0] }}</span>
              <b-form-input id="test-frequency" v-model="newAssignment.testFrequency"></b-form-input>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Protocol">
            <div class="horizontal-form-group protocol-selector">
              <b-form-checkbox v-model="newAssignment.teamFacilityProtocol" @change="clearOtherSelections('TeamFacilityProtocol')">
                <font-awesome-icon v-b-popover.hover.focus.top="'Team Facility Protocol'" icon="building" size="2x" />
              </b-form-checkbox>
              <b-form-checkbox v-model="newAssignment.homeResidenceProtocol" @change="clearOtherSelections('HomeResidenceProtocol')">
                <font-awesome-icon v-b-popover.hover.focus.top="'Home Residence Protocol'" icon="house" size="2x" />
              </b-form-checkbox>
              <b-form-checkbox v-model="newAssignment.hotelProtocol" @change="clearOtherSelections('HotelProtocol')">
                <font-awesome-icon v-b-popover.hover.focus.top="'Hotel Protocol'" icon="hotel" size="2x" />
              </b-form-checkbox>
              <b-form-checkbox v-model="newAssignment.otherProtocol" @change="clearOtherSelections('OtherProtocol')">
                <font-awesome-icon v-b-popover.hover.focus.top="'Other Protocol'" icon="question-circle" size="2x" />
              </b-form-checkbox>
            </div>
          </b-form-group>
        </div>

        <b-form-group label="Comments" label-for="comments">
          <b-form-textarea id="comments" v-model="newAssignment.comments"></b-form-textarea>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <div class="d-modal-buttons">
            <b-button variant="primary" :disabled="saveInProgress" @click="saveAssignment(invalid)">
              Save
              <font-awesome-icon v-show="saveInProgress" icon="spinner" spin />
            </b-button>
            <b-button variant="secondary" @click="$modal.hide('new-assignment-modal')">Close</b-button>
          </div>
        </div>
      </template>
    </DraggableModal>
  </ValidationObserver>
</template>
<script>
import axios from 'axios';
import SubjectDetailsMixin from '@/mixins/subjectDetailsMixin';

export default {
  mixins: [SubjectDetailsMixin],
  data() {
    return {
      newAssignment: null,
      initialAssignment: null,
      presetDPA: 'ALL DFS',
      errorInfo: null,
      saveInProgress: false
    };
  },
  computed: {
    hasEdits() {
      return !_.isEqual(this.newAssignment, this.initialAssignment);
    }
  },
  watch: {
    selectedSeasons() {
      this.initializeValues();
    }
  },
  created() {
    this.initializeValues();
  },
  methods: {
    initializeValues() {
      this.initialAssignment = {
        subjectKey: this.subject.subjectKey,
        season: this.season,
        monthNumber: null,
        testFrequency: 5,
        teamFacilityProtocol: false,
        homeResidenceProtocol: false,
        hotelProtocol: false,
        otherProtocol: false,
        comments: null
      };

      this.newAssignment = _.cloneDeep(this.initialAssignment);
      this.errorInfo = null;
    },
    clearOtherSelections(protocolSelected) {
      switch (protocolSelected) {
        case 'TeamFacilityProtocol':
          this.newAssignment.homeResidenceProtocol = false;
          this.newAssignment.hotelProtocol = false;
          this.newAssignment.otherProtocol = false;
          break;
        case 'HomeResidenceProtocol':
          this.newAssignment.teamFacilityProtocol = false;
          this.newAssignment.hotelProtocol = false;
          this.newAssignment.otherProtocol = false;
          break;
        case 'HotelProtocol':
          this.newAssignment.teamFacilityProtocol = false;
          this.newAssignment.homeResidenceProtocol = false;
          this.newAssignment.otherProtocol = false;
          break;
        case 'OtherProtocol':
          this.newAssignment.teamFacilityProtocol = false;
          this.newAssignment.homeResidenceProtocol = false;
          this.newAssignment.hotelProtocol = false;
          break;
      }
    },
    async saveAssignment(invalid) {
      if (invalid) {
        this.$refs.newAssignmentValidationRef.validate();
        return;
      }

      try {
        this.saveInProgress = true;
        await axios.post('/api/Subject/AddDFSAssignment', this.newAssignment);
        this.makeToast('Assignment has been Added.');
        await this.getSubject();
        this.$refs.newAssignmentModalRef.hide('confirmed');
      } catch (error) {
        this.errorInfo = error.response.data;
      } finally {
        this.saveInProgress = false;
      }
    }
  }
};
</script>
