import store from '../store';

export default {
  mapComputed(namespace, property) {
    return {
      get() {
        return store.getters[`${namespace}/${property}`];
      },
      set(value) {
        //automatically creates mutation name (starting with "set") based on property name.
        store.commit(`${namespace}/set${property.charAt(0).toUpperCase() + property.slice(1)}`, value);
      }
    };
  }
};
