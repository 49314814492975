import Vue from 'vue';
import Vuex from 'vuex';
import general from './general';
import home from './home';
import auth from './auth';
import subjectDetails from './subjectDetails';
import collectionEntry from './collectionEntry';
import substances from './substances';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general,
    home,
    auth,
    subjectDetails,
    collectionEntry,
    substances
  }
});
